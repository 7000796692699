import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router';
import { journeyTimeLineRoute } from './routes.const';
import { useSelector } from 'react-redux';
import { isWebRenewalEnv } from '../config';

const KycGuardRoute = props => {
  const isKycSubmitted = useSelector(state => state.general.kycSubmitted);
  if (isKycSubmitted && !isWebRenewalEnv) {
    return <Navigate to={journeyTimeLineRoute} />;
  }

  return <Outlet {...props} />;
};

export default KycGuardRoute;
