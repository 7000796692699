import Logo from "./logo.svg";
import LogoWhite from "./logo-white.svg";
import ArrowWhite from "./arrow-white.svg";
import Tick from "./tick.svg";
import Error from "./error.svg";

const Icons = {
  Logo,
  LogoWhite,
  ArrowWhite,
  Tick,
  Error,
};

export default Icons;
