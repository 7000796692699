import React, { useEffect, useState } from "react";
import Icons from "../../assets/icons";

const Section = (props) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const checkWindowSize = () => {
    setIsMobile(window.innerWidth < 992);
  };

  useEffect(() => {
    checkWindowSize();
    window.addEventListener("resize", checkWindowSize);
    return () => {
      window.removeEventListener("resize", checkWindowSize);
    };
  }, []);

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  return (
    <>
      <div className="section">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: `${
              isMobile && !isActive ? "1px solid #2c2c2c" : "0"
            }`,
          }}
          className="section-header"
          onClick={handleToggle}
        >
          <p className="section-title">{props.title}</p>
          <div
            style={{
              width: "16px",
              height: "14px",
              display: `${isMobile ? "inline-block" : "none"}`,
              transform: isActive ? "rotate(180deg)" : "rotate(0)",
              transition: "all 0.2s ease-in-out",
            }}
          >
            <img src={Icons.ArrowWhite} alt="arrow" width="100%" />
          </div>
        </div>
        <div>
          <div
            style={{
              display: `${isMobile && !isActive ? "none" : "block"}`,
            }}
          >
            {props.children}
          </div>
        </div>
      </div>
    </>
  );
};

export default Section;
