import React from "react";
import Icons from "../assets/icons";

import "./style.scss";

const Header = ({ isArabic }) => {
  return (
    <div id="header" className={`${isArabic ? "rtl" : "ltr"}`}>
      <div className="container">
        <div className="flex">
          <div className="logo">
            <img src={Icons.Logo} alt="logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
