import React, { useEffect } from "react";
import PropTypes from "prop-types";
import KycKeys from "../../../enums/kyc-enum";
import PoliticalPosition from "../components/political-position";
import PoliticalPositionStatus from "../components/political-position-status";

const ClientPoliticalPositions = ({
  data,
  setKycData,
  kycFields,
  translator,
  isRenewal = false,
  counterValue,
}) => {
  const fieldKey = KycKeys.CLIENT_POLITICAL_POSITIONS;

  const {
    [fieldKey]: { value, isVisible },
  } = data;

  const renderDynamicFields = () =>
    value?.map((option, index) => {
      return (
        <React.Fragment key={index}>
          <PoliticalPosition
            data={{
              [fieldKey]: { value, isVisible },
              index,
            }}
            setKycData={setKycData}
            kycFields={kycFields}
            translator={translator}
            isRenewal={isRenewal}
            counterValue={counterValue}
          />
          <PoliticalPositionStatus
            data={{
              [fieldKey]: { value, isVisible },
              index,
            }}
            setKycData={setKycData}
            kycFields={kycFields}
            translator={translator}
            isRenewal={isRenewal}
            counterValue={counterValue}
          />
        </React.Fragment>
      );
    });

  useEffect(() => {
    if (value.length && !isVisible) {
      setKycData((prev) => ({
        ...prev,
        [fieldKey]: {
          ...prev[fieldKey],
          isVisible: true,
        },
      }));
    }
  }, [value]);

  if (!isVisible) {
    return null;
  }

  return renderDynamicFields();
};

ClientPoliticalPositions.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  validationCycle: PropTypes.number.isRequired,
  isSaveForLater: PropTypes.bool.isRequired,
  kycFields: PropTypes.object.isRequired,
};

export default ClientPoliticalPositions;
