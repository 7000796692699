import React, { useState, useEffect } from 'react';
import MainLayout from '../../layouts/main-layout';
import Contract from '../../widgets/contract';
import {
  generateClientContract,
  createContractSession,
  getContractSession,
  submitToAdmin,
  getContractGenerationStatus
} from './contract-services.proxy';
import { useLocation, useNavigate } from 'react-router';
import LoadingContract from '../loading-contract';
import { applicationSubmittedRoute } from '../../routes/routes.const';
import { getCustomer } from '../../general-services.proxy';
import { customerStatusEnums, activeCustomerStatusEnums } from '../../enums/general';
import contractEnums from '../../enums/contract';

const ReviewAndSign = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [contractUrl, setContractUrl] = useState('');
  const orderId = location?.state ? location?.state?.orderId : '';
  const [contractSessionId, setContractSessionId] = useState('');
  const [contractId, setContractId] = useState('');
  const [contractStatus, setContractStatus] = useState('');

  const [isSigned, setIsSigned] = useState(false);
  const [customer, setCustomer] = useState('');

  const generateContract = async () => {
    try {
      const { contract_id } = await generateClientContract(orderId);
      setContractId(contract_id);
    } catch (exception) {
      console.error(exception);
    }
  };

  const fetchContractGenerationStatus = async () => {
    if (!contractId || contractStatus === contractEnums.ACTIVE_CONTRACT_GENERATION) {
      return;
    }

    try {
      const { status } = await getContractGenerationStatus(orderId, contractId);
      setContractStatus(status);
    } catch (exception) {
      console.error(exception);
    }
  };

  const generateContractSession = async () => {
    try {
      const { order_contract_session_id } = await createContractSession(orderId);
      setContractSessionId(order_contract_session_id);
    } catch (exception) {
      throw new Error(exception);
    }
  };

  const orderSubmission = async () => {
    try {
      await submitToAdmin(orderId);
    } catch (exception) {
      throw new Error(exception);
    }
  };

  const fetchCustomerData = async () => {
    try {
      const availableCustomer = await getCustomer();
      setCustomer(availableCustomer);
    } catch (exception) {
      console.error(exception);
    }
  };

  const checkContractStatus = async () => {
    if (contractSessionId && !isSigned) {
      try {
        const { status, docusign_url } = await getContractSession(contractSessionId);
        setContractUrl(docusign_url);
        setIsLoading(false);
        if (status === contractEnums.SIGNED_CONTRACT) {
          setIsSigned(true);
          if (!activeCustomerStatusEnums.includes(customer.status)) {
            await orderSubmission();
          }
          navigate(applicationSubmittedRoute, {
            replace: true
          });
        }
      } catch (exception) {
        console.error(exception);
      }
    }
  };

  useEffect(() => {
    generateContract();
    fetchCustomerData();
  }, []);

  useEffect(() => {
    const timer = setInterval(checkContractStatus, 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [contractSessionId, isSigned]);

  useEffect(() => {
    const timer = setInterval(fetchContractGenerationStatus, 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [contractId, contractStatus]);

  useEffect(() => {
    if (contractStatus === contractEnums.ACTIVE_CONTRACT_GENERATION) {
      generateContractSession();
    }
  }, [contractStatus]);

  if (isLoading) {
    return <LoadingContract />;
  }

  return (
    <MainLayout isContract>
      <Contract srcURL={contractUrl} />
    </MainLayout>
  );
};

export default ReviewAndSign;
