import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MainLayout from '../../../layouts/main-layout';
import {
  personalRenewalInfoStepRoute,
  contactRenewalInfoStepRoute,
  employmentRenewalInfoStepRoute,
  incomeAndWealthRenewalInfoStepRoute,
  additionalRenewalInfoStepRoute,
  journeyTimeLineRenewalRoute,
  uploadAdditionalDocumentsRoute,
  identityVerificationRenewalRoute
} from '../../../routes/routes.const';
import {
  getPersonalInfoStepDetails,
  getContactInfoStepDetails,
  getEmploymentStepDetails,
  getAdditionalInfoStepDetails,
  getIncomeAndWealthTabDetails,
  getAccordionDefaultOpen,
  getAdditionalInfoStepDetailsV2
} from '../../../helpers/summary-step-helper';
import PrimaryButton from '../../../widgets/buttons/primary-button';
import SkeletonLoader from '../../../widgets/fields-skeleton';
import {
  getKycDataV2,
  getCustomer,
  getCustomerOrder,
  putCustomerOrder,
  getCustomerOrderValidation,
  getAllCustomerDocuments,
  getCustomerRisk,
  getKycDataNewVersion2,
  getCustomerOrderValidationV3
} from '../../../general-services.proxy';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import styles from '../kyc-summary-step.module.scss';
import Accordion from '../../../widgets/accordion/renewal';
import InfoRenewalBox from '../../../components/info-box/renewal';
import moment from 'moment';
import { BACK_CIVIL_ID, FRONT_CIVIL_ID, SUPPORTING_DOCUMENTS } from './config';
import {
  CLIENT_ADDITIONAL_FILE,
  documentTypesToBeFiltered
} from '../../upload-additional-documents/config';
import { getPresignedUrl } from '../../identity-verification/identity-verification-services.proxy';
import KycKeys from '../../../enums/kyc-enum';
import { updateChildrensNamesInfo, validateExpiryDates } from '../../../helpers/general-helpers';
import LoadingComponent from '../../../components/loading-component';
import { getLanguage } from '../../../utils';
import { getDefaultIncomeSourceValues } from '../../income-and-wealth/helper';
import { newPepFieldsFeatureFlag } from '../../../config/feature-flags';
import businessSectorEnums from '../../../enums/business-sector';

const KycRenewalSummaryStep = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const [isLoading, setLoading] = useState(true);
  const [orderId, setOrderId] = useState('');
  const [order, setOrder] = useState('');

  const [missedFieldsArray, setMissedFieldsArray] = useState({
    validations: {
      summaryTab: [],
      productInfo: [],
      personalInfo: [],
      contactInfo: [],
      employment: [],
      incomeAndWealth: [],
      additionalInfo: []
    }
  });
  const [isMissed, setIsMissed] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isCivilIdExpired, setIsCivilIdExpired] = useState(false);
  const [civilIdFrontUrl, setCivilIdFrontUrl] = useState('');
  const [civilIdBackUrl, setCivilIdBackUrl] = useState('');
  const [defaultAccordionOpen, setDefaultAccordionOpen] = useState('');

  const [isUpdateKycLoading, setIsUpdateKycLoading] = useState(false);
  const [isFinalLoading, setIsFinalLoading] = useState(false);

  const isSupportingDocuments =
    order.isReturnedRequest && order.returnReason === SUPPORTING_DOCUMENTS;
  const [riskLevel, setRiskLevel] = useState('');

  const [stepData, setStepData] = useState({
    civilIdInfo: {
      data: [
        {
          url: civilIdFrontUrl,
          title: t('frontLabel')
        },
        { url: civilIdBackUrl, title: t('backLabel') }
      ],
      routeName: identityVerificationRenewalRoute,
      title: 'civilIdInfoStepTitle',
      missingFields: 0
    },
    personalInfo: {
      data: [],
      routeName: personalRenewalInfoStepRoute,
      title: 'personalInfoStepTitle',
      missingFields: 0
    },
    contactInfo: {
      data: [],
      routeName: contactRenewalInfoStepRoute,
      title: 'contactInformation',
      missingFields: 0
    },
    employmentInfo: {
      data: [],
      routeName: employmentRenewalInfoStepRoute,
      title: 'EmploymentInfoStepTitle',
      missingFields: 0
    },
    incomeAndWealthInfo: {
      data: [],
      routeName: incomeAndWealthRenewalInfoStepRoute,
      title: 'incomeAndWealthStepTitle',
      missingFields: 0
    },
    additionalInfo: {
      data: [],
      routeName: additionalRenewalInfoStepRoute,
      title: 'additionalInfo',
      missingFields: 0
    },
    supportingDocumentsInfo: {
      data: [
        {
          supporting_documents: []
        },
        {
          additional_documents: []
        }
      ],
      routeName: uploadAdditionalDocumentsRoute,
      title: 'supportingDocumentsInfoStepTitle',
      missingFields: 0
    }
  });

  const { countries, kycFields: kycFieldsOptions, cities } = useSelector(state => state.general);

  const {
    yes_no: yesOrNo = {},
    employment_status: employmentStatuses = {},
    employer_type: employerTypes = {},
    private_sector_industries_v2: privateSectorIndustry = {},
    government_entity_type: governmentEntityTypes = {},
    employment_sector: employmentSectors = {},
    last_employment_status: lastEmploymentStatuses = {},
    assets_value: assetsValues = {},
    transactions_value_past_two_years: transactionsValuesPastTwoYears = {},
    financial_sector_years_experience: financialSectorYearsExperiences = {},
    account_purpose: accountPurpose = {},
    income_annual_v2: incomeAnual = {},
    investment_reason: investmentReasons = {},
    investment_level: investmentLevels = {},
    risk_tolerance_level: riskToleranceLevels = {},
    bank_names: bankNames = {},
    private_business_sector: privateBusinessSectors = {},
    political_position_role: positions = {},
    investment_years_experience: investmentYearsExperience = {},
    employment_entity_name: employmentEntityNames = {},
    relationship = {},
    gender: genderOptions = {},
    income_source_v2: annualIncomeSource = {},
    wealth_source: wealthSource = {},
    relationship_status: relationshipStatus = {},
    political_position_status: politicalPositionStatuses = {},
    retail_trade: retailTradeSectors = {},
    energy_utilities_and_natural_resources: energyUtilitySectors = {},
    banking_and_finance: bankingAndFinanceSectors = {},
    health_and_social_services: healthServicesSectors = {},
    manufacturing_industries: manufacturingIndustriesSectors = {},
    professional_and_specialized_services: professionalAndSpecializedSectors = {},
    real_estate_hospitality_and_rental_services: realEstateSectors = {},
    information_and_technology_services: informationAndTechnologySectors = {},
    logistics_and_communications: logisticsSectors = {},
    public_services: publicSectors = {},
    sports_culture_and_entertainment: sportsCultureSectors = {},
    wholesale_trade: wholeSaleTradeSectors = {},
    off_shore_organizations_and_bodies: offShoreOrganizationSectors = {},
    other_assoc_pers_cult_and_tour: otherAssocSectors = {}
  } = kycFieldsOptions;

  const businessTypeMapping = {
    [businessSectorEnums.RETAIL_TRADE]: retailTradeSectors,
    [businessSectorEnums.ENERGY_UTILITIES_AND_RESOURCES]: energyUtilitySectors,
    [businessSectorEnums.BANKING_AND_FINANCE]: bankingAndFinanceSectors,
    [businessSectorEnums.HEALTH_AND_SOCIAL_SERVICES]: healthServicesSectors,
    [businessSectorEnums.MANUFACTURING_INDUSTRIES]: manufacturingIndustriesSectors,
    [businessSectorEnums.PROFESSIONAL_AND_SPECIALIZED_SERVICES]: professionalAndSpecializedSectors,
    [businessSectorEnums.REAL_ESTATE_HOSPITALITY_AND_RENTAL_SERVICCES]: realEstateSectors,
    [businessSectorEnums.INFORMATION_AND_TECHNOLOGY_SERVICES]: informationAndTechnologySectors,
    [businessSectorEnums.LOGISTICS_AND_COMMUNICATIONS]: logisticsSectors,
    [businessSectorEnums.PUBLIC_SERVICES]: publicSectors,
    [businessSectorEnums.SPORTS_CULTURE_AND_ENTERTAINMENT]: sportsCultureSectors,
    [businessSectorEnums.WHOLESALE_TRADE]: wholeSaleTradeSectors,
    [businessSectorEnums.OFF_SHORE_ORGANIZATIONS_AND_BODIES]: offShoreOrganizationSectors,
    [businessSectorEnums.OTHER_ASOC_PERS_CULT_AND_TOUR]: otherAssocSectors
  };

  const updateKycToDone = async () => {
    try {
      setIsFinalLoading(true);
      await putCustomerOrder(orderId);
      navigate(journeyTimeLineRenewalRoute, {
        replace: true
      });
    } catch (exception) {
      console.error(exception);
    } finally {
      setIsFinalLoading(false);
    }
  };

  const renderFooter = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '12px'
      }}
    >
      {isUpdateKycLoading && (
        <div className={styles.loaderContainer}>
          <div className={styles.loader} />
        </div>
      )}

      <PrimaryButton
        disabled={
          !order.isKycDone || isLoading || isUpdateKycLoading || isMissed || order?.isAumError
        }
        onClick={() => {
          updateKycToDone();
        }}
        data-testid={'saveAndContinueButton'}
      >
        {t('saveAndContinueButton')}
      </PrimaryButton>
    </div>
  );
  const getStepsDetails = kycData => {
    const personalInfoDetails = getPersonalInfoStepDetails(
      kycData,
      countries,
      genderOptions,
      relationshipStatus
    );
    const contactInfoDetails = getContactInfoStepDetails(kycData, countries, cities, getLanguage());

    const employmentInfoDetails = getEmploymentStepDetails(
      kycData,
      employmentStatuses,
      employerTypes,
      privateSectorIndustry,
      governmentEntityTypes,
      businessTypeMapping,
      lastEmploymentStatuses,
      employmentEntityNames,
      yesOrNo
    );

    const additionalInfoDetails = newPepFieldsFeatureFlag
      ? getAdditionalInfoStepDetailsV2(
          kycData,
          positions,
          politicalPositionStatuses,
          relationship,
          countries,
          yesOrNo
        )
      : getAdditionalInfoStepDetails(kycData, positions, relationship, countries, yesOrNo);

    const defaultIncomeandSourceValue = getDefaultIncomeSourceValues(
      annualIncomeSource,
      kycData[KycKeys.EMPLOYMENT_STATUS].value
    );

    const incomeAndWealthInfoDetails = getIncomeAndWealthTabDetails(
      kycData,
      accountPurpose,
      incomeAnual,
      assetsValues,
      transactionsValuesPastTwoYears,
      financialSectorYearsExperiences,
      investmentReasons,
      investmentLevels,
      riskToleranceLevels,
      bankNames,
      privateSectorIndustry,
      businessTypeMapping,
      investmentYearsExperience,
      annualIncomeSource,
      wealthSource,
      yesOrNo,
      defaultIncomeandSourceValue
    );

    setStepData(prevState => ({
      ...prevState,
      personalInfo: {
        ...prevState.personalInfo,
        data: personalInfoDetails
      },
      contactInfo: {
        ...prevState.contactInfo,
        data: contactInfoDetails
      },
      employmentInfo: {
        ...prevState.employmentInfo,
        data: employmentInfoDetails
      },
      additionalInfo: {
        ...prevState.additionalInfo,
        data: additionalInfoDetails
      },
      incomeAndWealthInfo: {
        ...prevState.incomeAndWealthInfo,
        data: incomeAndWealthInfoDetails
      }
    }));
  };

  const setMissingFields = missedFields => {
    setStepData(prevState => ({
      ...prevState,

      personalInfo: {
        ...prevState.personalInfo,

        missingFields: missedFields.validations.personalInfo.length
      },
      contactInfo: {
        ...prevState.contactInfo,

        missingFields: missedFields.validations.contactInfo.length
      },
      employmentInfo: {
        ...prevState.employmentInfo,

        missingFields: missedFields.validations.employment.length
      },
      additionalInfo: {
        ...prevState.additionalInfo,

        missingFields: missedFields.validations.additionalInfo.length
      },
      incomeAndWealthInfo: {
        ...prevState.incomeAndWealthInfo,

        missingFields: missedFields.validations.incomeAndWealth.length
      }
    }));
  };

  const fetchDocumentsAndSetData = async orderId => {
    try {
      if (civilIdFrontUrl && civilIdBackUrl) {
        return;
      }

      const response = await getAllCustomerDocuments(orderId);
      const documentsNeededFiltered = response.filter(
        doc => documentTypesToBeFiltered.includes(doc.documentType) && doc.id
      );
      const documentsAdditionalFiltered = response.filter(
        doc => doc.documentType === CLIENT_ADDITIONAL_FILE
      );

      const civilIdCopyFront = response.find(doc => doc.documentType === FRONT_CIVIL_ID);
      const civilIdCopyBack = response.find(doc => doc.documentType === BACK_CIVIL_ID);
      let frontUrl = null;
      let backUrl = null;
      if (civilIdCopyFront && civilIdCopyBack) {
        frontUrl = civilIdCopyFront.id ? await getPresignedUrl(civilIdCopyFront.id) : null;
        backUrl = civilIdCopyBack.id ? await getPresignedUrl(civilIdCopyBack.id) : null;
      }

      if (frontUrl) {
        setCivilIdFrontUrl(frontUrl.url);
      }

      if (backUrl) {
        setCivilIdBackUrl(backUrl.url);
      }

      setStepData(prevState => ({
        ...prevState,
        civilIdInfo: {
          ...prevState.civilIdInfo,
          data: [
            frontUrl
              ? { url: frontUrl.url, title: t('frontLabel') }
              : prevState.civilIdInfo.data[0],
            backUrl ? { url: backUrl.url, title: t('backLabel') } : prevState.civilIdInfo.data[1]
          ]
        },
        supportingDocumentsInfo: {
          ...prevState.supportingDocumentsInfo,
          data: [
            { supporting_documents: documentsNeededFiltered },
            { additional_documents: documentsAdditionalFiltered }
          ]
        }
      }));
    } catch (exception) {
      console.error(exception);
    }
  };

  const validateChildrenNames = (kycData, missedFields) => {
    updateChildrensNamesInfo(
      kycData,
      kycData[KycKeys.CHILDREN_DETAILS].value,
      kycData[KycKeys.CHILDREN_NUMBER].value
    );
    const requiredKeys = ['name', 'first_name', 'middle_name', 'family_name'];
    kycData[KycKeys.CHILDREN_DETAILS].value.map(child => {
      if (
        requiredKeys.some(
          key => child[key] === null || child[key] === undefined || child[key] === ''
        )
      ) {
        missedFields.validations.personalInfo.push(child.name);
      }
    });
    if (
      kycData[KycKeys.CHILDREN_NUMBER].value > 0 &&
      kycData[KycKeys.CHILDREN_DETAILS].value.length < kycData[KycKeys.CHILDREN_NUMBER].value
    ) {
      missedFields.validations.personalInfo.push(KycKeys.CHILDREN_NUMBER);
      missedFields.validations.personalInfo = missedFields.validations.personalInfo.filter(
        key => key !== KycKeys.CHILDREN_DETAILS
      );
    }
  };

  const validateNewPepFields = (kycData, missedFields) => {
    if (
      kycData[KycKeys.IS_CLIENT_POLITICAL_POSITION].value &&
      !kycData[KycKeys.CLIENT_POLITICAL_POSITIONS].value.length
    ) {
      missedFields.validations.additionalInfo.push(KycKeys.CLIENT_POLITIAL_POSITION_COUNT);
    }

    if (
      kycData[KycKeys.IS_RELATIVE_POLITICAL_POSITION].value &&
      !kycData[KycKeys.RELATIVE_POLITICAL_POSITIONS].value.length
    ) {
      missedFields.validations.additionalInfo.push(KycKeys.RELATIVE_POLITICAL_POSITION_COUNT);
    }

    if (
      kycData[KycKeys.CLIENT_POLITICAL_POSITIONS].value &&
      Array.isArray(kycData[KycKeys.CLIENT_POLITICAL_POSITIONS].value) &&
      kycData[KycKeys.CLIENT_POLITICAL_POSITIONS].value.length > 0
    ) {
      kycData[KycKeys.CLIENT_POLITICAL_POSITIONS].value.forEach((item, index) => {
        if (!item.position) {
          missedFields.validations.additionalInfo.push(
            `${KycKeys.CLIENT_POLITICAL_POSITIONS}${index}position`
          );
        }
        if (!item.status) {
          missedFields.validations.additionalInfo.push(
            `${KycKeys.CLIENT_POLITICAL_POSITIONS}${index}status`
          );
        }
      });
    }

    if (
      kycData[KycKeys.RELATIVE_POLITICAL_POSITIONS].value &&
      Array.isArray(kycData[KycKeys.RELATIVE_POLITICAL_POSITIONS].value) &&
      kycData[KycKeys.RELATIVE_POLITICAL_POSITIONS].value.length > 0
    ) {
      kycData[KycKeys.RELATIVE_POLITICAL_POSITIONS].value.forEach((item, index) => {
        if (!item.first_name) {
          missedFields.validations.additionalInfo.push(
            `${KycKeys.RELATIVE_POLITICAL_POSITIONS}${index}firstName`
          );
        }
        if (!item.family_name) {
          missedFields.validations.additionalInfo.push(
            `${KycKeys.RELATIVE_POLITICAL_POSITIONS}${index}familyName`
          );
        }
        if (!item.relationship) {
          missedFields.validations.additionalInfo.push(
            `${KycKeys.RELATIVE_POLITICAL_POSITIONS}${index}relationship`
          );
        }
        if (!item.position) {
          missedFields.validations.additionalInfo.push(
            `${KycKeys.RELATIVE_POLITICAL_POSITIONS}${index}position`
          );
        }
        if (!item.status) {
          missedFields.validations.additionalInfo.push(
            `${KycKeys.RELATIVE_POLITICAL_POSITIONS}${index}status`
          );
        }
      });
    }
  };

  const fetchKycData = async () => {
    try {
      setLoading(true);
      const { orderId, orderKycId, civilIdExpiryDate } = await getCustomer();
      const currentDate = new Date();
      setIsCivilIdExpired(new Date(civilIdExpiryDate) < currentDate ? true : false);

      setOrderId(orderId);
      const availableOrder = await getCustomerOrder(orderId);

      setOrder(availableOrder);
      const missedFields = await (newPepFieldsFeatureFlag
        ? getCustomerOrderValidationV3(orderId, orderKycId)
        : getCustomerOrderValidation(orderId, orderKycId));
      const { riskLevel } = await getCustomerRisk(orderKycId);

      const {
        serializedData: kycData,
        createdAt,
        updatedAt
      } = await (newPepFieldsFeatureFlag
        ? getKycDataNewVersion2(orderId, orderKycId)
        : getKycDataV2(orderId, orderKycId));

      setRiskLevel(riskLevel);
      validateChildrenNames(kycData, missedFields);
      if (newPepFieldsFeatureFlag) {
        validateNewPepFields(kycData, missedFields);
      }

      validateExpiryDates(kycData, missedFields);
      setMissedFieldsArray(missedFields);
      setMissingFields(missedFields);
      setDefaultAccordionOpen(getAccordionDefaultOpen(missedFields));
      const isAnyFieldMissed = Object.values(missedFields.validations).some(
        fieldArray => fieldArray.length > 0
      );

      setIsMissed(isAnyFieldMissed);

      const createdAtMoment = moment(createdAt);
      const updatedAtMoment = moment(updatedAt);
      setIsUpdated(updatedAtMoment.isAfter(createdAtMoment));
      await fetchDocumentsAndSetData(orderId);
      getStepsDetails(kycData);
    } catch (exception) {
      console.error(exception);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (civilIdFrontUrl && civilIdBackUrl) {
      setStepData(prevState => ({
        ...prevState,
        civilIdInfo: {
          ...prevState.civilIdInfo,
          data: [
            { url: civilIdFrontUrl, title: t('frontLabel') },
            { url: civilIdBackUrl, title: t('backLabel') }
          ]
        }
      }));
    }
  }, [civilIdFrontUrl, civilIdBackUrl]);

  const renderInfoRenewalBox = () => {
    if (!isMissed && !isUpdated && !order.isReturnedRequest) {
      return (
        <InfoRenewalBox
          title={t('informationAdded')}
          subTitle={t('filledInfoMessage')}
          color='#7595f5'
          borderColor='#7595f5'
        />
      );
    }

    if (order.isReturnedRequest) {
      return (
        <InfoRenewalBox
          title={t('attentionRequired')}
          beforeDescription={t('beforedescription')}
          description={order.returnDescription}
        />
      );
    }
    if (isMissed) {
      return <InfoRenewalBox title={t('missingInfo')} subTitle={t('missedData')} />;
    }

    return null;
  };

  const renderAumFailureBox = () => {
    if (order?.isAumError) {
      return <InfoRenewalBox title={t('aumFailureTitle')} subTitle={t('aumFailure')} />;
    }
  };

  useEffect(() => {
    if (Object.entries(kycFieldsOptions).length && countries) {
      fetchKycData();
    }
  }, [kycFieldsOptions, countries]);

  if (isFinalLoading) {
    return <LoadingComponent />;
  }

  return (
    <MainLayout title={t('reviewAndUpdate')} footer={renderFooter}>
      {isLoading ? (
        <SkeletonLoader numberOfFields={6} />
      ) : (
        <div className={styles.mainContainer}>
          {renderInfoRenewalBox()}
          {renderAumFailureBox()}
          {Object.entries(stepData).map(([key, step]) => (
            <Accordion
              key={key}
              accordionData={step.data}
              accordionTitle={t(`${step.title}`)}
              routeName={step.routeName}
              testId={step.title}
              additionalInfo={step.missingFields}
              missingFieldsArray={missedFieldsArray}
              orderId={orderId}
              isEditDisabled={
                isSupportingDocuments && step.title !== 'supportingDocumentsInfoStepTitle'
              }
              order={order}
              riskLevel={riskLevel}
              defaultAccordionOpen={defaultAccordionOpen}
              isCivilIdExpired={isCivilIdExpired}
            />
          ))}
        </div>
      )}
    </MainLayout>
  );
};

export default KycRenewalSummaryStep;
