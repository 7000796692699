import StepEnums from '../enums/step-enum';
import KycKeys from '../enums/kyc-enum';
import { convertBooleanToYesNo } from '.';
import DateUtils from '../utils/date';
import { countriesEnums, generalEnums } from '../enums/general';
import { getCountryName, formatNumberWithComma, capitalizeFirstLetter } from '../utils';
import employmentStatusEnums from '../enums/employment-status-enum';
import { getAreaByCityName } from './general-helpers';
import {
  incomeSourceEnums,
  wealthSourceEnums,
  accountPurposeEnums
} from '../enums/income-and-wealth';
import employerTypeEnums from '../enums/employer-type';
import { languageEnum } from '../enums/language-enum';

const getFieldsByStep = (kycData, stepName) =>
  Object.entries(kycData)
    .filter(([key, value]) => value.step === stepName)
    .reduce((tabData, [key, value]) => {
      tabData[key] = value.value;
      return tabData;
    }, {});

const getArrayLabels = (values, labels) => values.map(value => labels[value]);

const getPersonalInfoStepDetails = (kycData, countries, genderOptions, relationshipStatuses) => {
  let tabDetails = [];
  const tabData = getFieldsByStep(kycData, StepEnums.PERSONAL_INFO_STEP);
  const {
    [KycKeys.GENDER]: gender,
    [KycKeys.FIRST_NAME]: firstName,
    [KycKeys.MIDDLE_NAME]: middleName,
    [KycKeys.FAMILY_NAME]: familyName,
    [KycKeys.BIRTH_DATE]: dob,
    [KycKeys.NATIONALITY]: nationality,
    [KycKeys.OTHER_NATIONALITIES]: otherNationalities,
    [KycKeys.BIRTH_COUNTRY]: countryOfBirth,
    [KycKeys.CITY_TOWN_BIRTH]: cityTownBirth,
    [KycKeys.CIVIL_ID_NUMBER]: civilIdNumber,
    [KycKeys.CIVIL_ID_SERIAL]: civilIdSerial,
    [KycKeys.CIVIL_ID_EXPIRY_DATE]: civilIdExpiryDate,
    [KycKeys.PASSPORT_NUMBER]: passportNumber,
    [KycKeys.PASSPORT_COUNTRY]: passportCountry,
    [KycKeys.PASSPORT_EXPIRY_DATE]: passportExpiryDate,
    [KycKeys.MOTHER_FIRST_NAME]: motherFirstName,
    [KycKeys.MOTHER_MIDDLE_NAME]: motherMiddleName,
    [KycKeys.MOTHER_LAST_NAME]: motherLastName,
    [KycKeys.FATHER_FIRST_NAME]: fatherFirstName,
    [KycKeys.FATHER_MIDDLE_NAME]: fatherMiddleName,
    [KycKeys.FATHER_LAST_NAME]: fatherLastName,
    [KycKeys.MARRIAGE_STATUS]: marriageStatus,
    [KycKeys.PARTNER_NAME]: partnerName,
    [KycKeys.CHILDREN_DETAILS]: childrenDetails,
    [KycKeys.CHILDREN_NUMBER]: numberOfChildren,
    [KycKeys.RELATIONSHIP_STATUS]: relationshipStatus
  } = tabData;
  tabDetails = [
    {
      id: KycKeys.GENDER,
      label: 'gender',
      value: genderOptions[gender] || 'NALabel'
    },
    {
      id: KycKeys.FIRST_NAME,
      label: 'firstName',
      value: firstName || 'NALabel'
    },
    {
      id: KycKeys.MIDDLE_NAME,
      label: 'middleName',
      value: middleName || 'NALabel'
    },
    {
      id: KycKeys.FAMILY_NAME,
      label: 'familyName',
      value: familyName || 'NALabel'
    },
    {
      id: KycKeys.BIRTH_DATE,
      label: 'dateOfBirth',
      value: DateUtils.formatDate(dob) || 'NALabel'
    },
    {
      id: KycKeys.NATIONALITY,
      label: 'nationality',
      value: getCountryName(nationality, countries) || 'NALabel'
    }
  ];
  if (nationality && nationality != countriesEnums.KUWAIT) {
    tabDetails.push({
      id: KycKeys.PASSPORT_NUMBER,
      label: 'passportNumber',
      value: passportNumber || 'NALabel'
    });
    tabDetails.push({
      id: KycKeys.PASSPORT_COUNTRY,
      label: 'passportCountry',
      value:
        getCountryName(passportCountry, countries) ||
        getCountryName(nationality, countries) ||
        'NALabel'
    });
    tabDetails.push({
      id: KycKeys.PASSPORT_EXPIRY_DATE,
      label: 'passportExpiryDate',
      value: DateUtils.formatDate(passportExpiryDate) || 'NALabel'
    });
  }
  let extraDetails = [
    {
      id: KycKeys.OTHER_NATIONALITIES,
      label: 'otherNationalitiesLabel',
      value:
        otherNationalities.length > 0
          ? otherNationalities.map(nationality => getCountryName(nationality, countries))
          : 'NALabel'
    },
    {
      id: KycKeys.BIRTH_COUNTRY,
      label: 'birthCountry',
      value: getCountryName(countryOfBirth, countries) || 'NALabel'
    },
    {
      id: KycKeys.CITY_TOWN_BIRTH,
      label: 'cityTownBirth',
      value: cityTownBirth || 'NALabel'
    },
    {
      id: KycKeys.CIVIL_ID_NUMBER,
      label: 'civilIdNumber',
      value: civilIdNumber || 'NALabel'
    },
    {
      id: KycKeys.CIVIL_ID_SERIAL,
      label: 'civilIdSerialNumber',
      value: civilIdSerial || 'NALabel'
    },
    {
      id: KycKeys.CIVIL_ID_EXPIRY_DATE,
      label: 'civilIdExpiryDate',
      value: DateUtils.formatDate(civilIdExpiryDate) || 'NALabel'
    },
    {
      id: KycKeys.FATHER_FIRST_NAME,
      label: 'fatherFirstName',
      value: fatherFirstName || 'NALabel'
    },
    {
      id: KycKeys.FATHER_MIDDLE_NAME,
      label: 'fatherMiddleName',
      value: fatherMiddleName || 'NALabel'
    },
    {
      id: KycKeys.FATHER_LAST_NAME,
      label: 'fatherLastName',
      value: fatherLastName || 'NALabel'
    },
    {
      id: KycKeys.MOTHER_FIRST_NAME,
      label: 'motherFirstName',
      value: motherFirstName || 'NALabel'
    },
    {
      id: KycKeys.MOTHER_MIDDLE_NAME,
      label: 'motherMiddleName',
      value: motherMiddleName || 'NALabel'
    },
    {
      id: KycKeys.MOTHER_LAST_NAME,
      label: 'motherLastName',
      value: motherLastName || 'NALabel'
    },

    {
      id: KycKeys.MARRIAGE_STATUS,
      label: 'maritalStatus',
      value: relationshipStatuses[relationshipStatus] || 'NALabel'
    }
  ];
  tabDetails.push(...extraDetails);
  if (marriageStatus) {
    tabDetails.push({
      id: KycKeys.PARTNER_NAME,
      label: 'partnerName',
      value: partnerName || 'NALabel'
    });
  }
  tabDetails.push({
    id: KycKeys.CHILDREN_NUMBER,
    label: 'childrenNumber',
    value: numberOfChildren || numberOfChildren === 0 ? numberOfChildren : 'NALabel'
  });

  if (numberOfChildren > 0) {
    childrenDetails.map(
      (
        {
          name,
          [KycKeys.FIRST_NAME]: firstName,
          [KycKeys.MIDDLE_NAME]: middleName,
          [KycKeys.FAMILY_NAME]: familyName,
          value
        },
        index
      ) => {
        const isOldChildrens = !firstName && !middleName && !familyName;
        const childrenDetailsValue = isOldChildrens
          ? value
          : `${firstName} ${middleName} ${familyName}`;
        tabDetails.push({
          id: name,
          label: 'childFullName',
          value: childrenDetailsValue?.trim() ? childrenDetailsValue : 'NALabel',
          childNumber: index + 1
        });
      }
    );
  }

  return tabDetails;
};

const getContactInfoStepDetails = (kycData, countries, cities, language) => {
  let tabDetails = [];
  const tabData = getFieldsByStep(kycData, StepEnums.CONTACT_INFO_STEP);

  const {
    [KycKeys.ADDRESS_AREA]: area,
    [KycKeys.ADDRESS_BLOCK]: block,
    [KycKeys.ADDRESS_CITY]: city,
    [KycKeys.ADDRESS_HOUSE]: house,
    [KycKeys.ADDRESS_STREET]: street,
    [KycKeys.COUNTRY]: country
  } = tabData;

  // if (!cities || !city) {
  //   return [];
  // }
  const cityObj = cities.find(c => c.name.en === city);
  const cityDisplayValue =
    language === languageEnum.english && cityObj ? cityObj?.name.en : cityObj?.name.ar;
  const areas = getAreaByCityName(cities, city);
  const areaObj = areas?.find(c => c.en === area);
  const areaDispalyValue = language === languageEnum.english && areaObj ? areaObj?.en : areaObj?.ar;

  tabDetails = [
    {
      id: KycKeys.COUNTRY,
      label: 'country',
      value: getCountryName(country, countries) || 'NALabel'
    },
    {
      id: KycKeys.ADDRESS_CITY,
      label: 'city',
      value: cityDisplayValue || 'NALabel'
    },
    {
      id: KycKeys.ADDRESS_AREA,
      label: 'area',
      value: areaDispalyValue || 'NALabel'
    },
    {
      id: KycKeys.ADDRESS_BLOCK,
      label: 'block',
      value: block || 'NALabel'
    },
    {
      id: KycKeys.ADDRESS_HOUSE,
      label: 'house',
      value: house || 'NALabel'
    },
    {
      id: KycKeys.ADDRESS_STREET,
      label: 'street',
      value: street || 'NALabel'
    }
  ];

  return tabDetails;
};

const getEmploymentStepDetails = (
  kycData,
  employmentStatuses,
  employerTypes,
  privateSectorIndustries,
  governmentEntityTypes,
  businessTypeMapping,
  lastEmploymentStatuses,
  employmentEntityNames,
  yesOrNo
) => {
  let tabDetails = [];
  const tabData = getFieldsByStep(kycData, StepEnums.EMPLOYMENT_INFO_STEP);
  const {
    [KycKeys.AUTH_SIGN_PROCESS_FINANCIAL_TRANSACTIONS]: authSignProcessFinancialTransactions,
    [KycKeys.BOARD_MEMBERSHIP_COMPANY_NAME_1]: boardMembershipCompanyName1,
    [KycKeys.BOARD_MEMBERSHIP_COMPANY_NAME_2]: boardMembershipCompanyName2,
    [KycKeys.BOARD_MEMBERSHIP_COMPANY_NAME_3]: boardMembershipCompanyName3,
    [KycKeys.BOARD_MEMBERSHIP_EXISTING]: boardMembershipExisting,
    [KycKeys.EMPLOYER_TYPE]: employerType,
    [KycKeys.EMPLOYMENT_COMPANY]: employmentCompany,
    [KycKeys.EMPLOYMENT_DEPARTMENT]: employmentDepartment,
    [KycKeys.EMPLOYMENT_ENTITY_NAME]: employmentEntityName,
    [KycKeys.EMPLOYMENT_ORGANIZATION_NAME]: employmentOrganizationName,
    [KycKeys.EMPLOYMENT_SECTOR]: employmentSector,
    [KycKeys.EMPLOYMENT_STATUS]: employmentStatus,
    [KycKeys.GOVERNMENT_ENTITY_TYPE]: governmentEntityType,
    [KycKeys.JOB_TITLE]: employmentPlace,
    [KycKeys.LAST_EMPLOYMENT_STATUS]: lastEmploymentStatus,
    [KycKeys.PRIVATE_SECTOR_INDUSTRY]: privateSectorIndustry
  } = tabData;

  const businessTypes = kycData[KycKeys.PRIVATE_SECTOR_INDUSTRY].value
    ? businessTypeMapping[kycData[KycKeys.PRIVATE_SECTOR_INDUSTRY].value] || {}
    : {};

  const entityName = employmentEntityNames[employmentEntityName] || employmentEntityName;
  const isEmployerTypeCharity = employerType === employerTypeEnums.CHARITY;
  const isEmployerTypeGovernment = employerType === employerTypeEnums.GOVERNMENT;
  const isEmployerTypePrivateSector = employerType === employerTypeEnums.PRIVATE_SECTOR;
  const employmentStatusValues = [
    employmentStatusEnums.EMPLOYED,
    employmentStatusEnums.SELF_EMPLOYED
  ];
  const isEmoployedOrSelfEmployed = [employmentStatus, lastEmploymentStatus].some(value =>
    employmentStatusValues.includes(value)
  );
  const boardMemberOrInsiderValue = convertBooleanToYesNo(boardMembershipExisting);
  const authSignProcessFinancialTransactionsValue = convertBooleanToYesNo(
    authSignProcessFinancialTransactions
  );

  tabDetails = [
    {
      id: KycKeys.EMPLOYMENT_STATUS,
      label: 'employmentStatusLabel',
      value: employmentStatuses[employmentStatus] || 'NALabel'
    }
  ];
  if (employmentStatus && employmentStatus === employmentStatusEnums.RETIRED) {
    tabDetails.push({
      id: KycKeys.LAST_EMPLOYMENT_STATUS,
      label: 'lastEmploymentStatusLabel',
      value: lastEmploymentStatuses[lastEmploymentStatus] || 'NALabel'
    });
  }

  if ((employmentStatus || lastEmploymentStatus) && isEmoployedOrSelfEmployed) {
    tabDetails.push({
      id: KycKeys.EMPLOYER_TYPE,
      label: 'employerTypeLabel',
      value: employerTypes[employerType] || 'NALabel'
    });
  }

  if (isEmployerTypeGovernment || isEmployerTypePrivateSector || isEmployerTypeCharity) {
    tabDetails.push({
      id: KycKeys.JOB_TITLE,
      label: 'jobTitleLabel',
      value: employmentPlace || 'NALabel'
    });
  }

  if (employerType && isEmployerTypeCharity) {
    tabDetails.push(
      {
        id: KycKeys.EMPLOYMENT_ORGANIZATION_NAME,
        label: 'organizationNameLabel',
        value: employmentOrganizationName || 'NALabel'
      },
      {
        id: KycKeys.AUTH_SIGN_PROCESS_FINANCIAL_TRANSACTIONS,
        label: 'seniorExecutiveOrOwnerLabel',
        value: yesOrNo[authSignProcessFinancialTransactionsValue] || 'NALabel'
      }
    );
  }

  if (employerType && isEmployerTypeGovernment) {
    tabDetails.push(
      {
        id: KycKeys.GOVERNMENT_ENTITY_TYPE,
        label: 'entityTypeLabel',
        value: governmentEntityTypes[governmentEntityType] || 'NALabel'
      },
      {
        id: KycKeys.EMPLOYMENT_ENTITY_NAME,
        label: 'entityNameLabel',
        value: entityName || 'NALabel'
      },
      {
        id: KycKeys.AUTH_SIGN_PROCESS_FINANCIAL_TRANSACTIONS,
        label: 'seniorLeadershipPositionLabel',
        value: yesOrNo[authSignProcessFinancialTransactionsValue] || 'NALabel'
      }
    );
  }

  if (employerType && isEmployerTypePrivateSector) {
    const isSelfEmployed = [employmentStatus, lastEmploymentStatus].some(
      value => value === employmentStatusEnums.SELF_EMPLOYED
    );
    tabDetails.push(
      {
        id: KycKeys.PRIVATE_SECTOR_INDUSTRY,
        label: 'businessSectorLabel',
        value: privateSectorIndustries[privateSectorIndustry] || 'NALabel'
      },
      {
        id: KycKeys.EMPLOYMENT_DEPARTMENT,
        label: 'departmentLabel',
        value: employmentDepartment || 'NALabel'
      },
      {
        id: KycKeys.EMPLOYMENT_SECTOR,
        label: 'typeOfBusinessLabel',
        value: businessTypes[employmentSector] || 'NALabel'
      },
      {
        id: KycKeys.EMPLOYMENT_COMPANY,
        label: isSelfEmployed ? 'privateBusinessNameLabel' : 'employerNameLabel',
        value: employmentCompany || 'NALabel'
      },
      {
        id: KycKeys.AUTH_SIGN_PROCESS_FINANCIAL_TRANSACTIONS,
        label: 'seniorExecutiveOrOwnerLabel',
        value: yesOrNo[authSignProcessFinancialTransactionsValue] || 'NALabel'
      }
    );
  }
  tabDetails.push({
    id: KycKeys.BOARD_MEMBERSHIP_EXISTING,
    label: 'boardMemberOrInsiderLabel',
    value: yesOrNo[boardMemberOrInsiderValue] || 'NALabel'
  });
  if (boardMembershipExisting) {
    tabDetails.push(
      {
        id: KycKeys.BOARD_MEMBERSHIP_COMPANY_NAME_1,
        label: 'companyName1Label',
        value: boardMembershipCompanyName1 || 'NALabel'
      },
      {
        id: KycKeys.BOARD_MEMBERSHIP_COMPANY_NAME_2,
        label: 'companyName2Label',
        value: boardMembershipCompanyName2 || 'NALabel'
      },
      {
        id: KycKeys.BOARD_MEMBERSHIP_COMPANY_NAME_3,
        label: 'companyName3Label',
        value: boardMembershipCompanyName3 || 'NALabel'
      }
    );
  }
  return tabDetails;
};

const getIncomeAndWealthTabDetails = (
  kycData,
  accountPurposes,
  incomeAnual,
  assetsValues,
  transactionsValuesPastTwoYears,
  financialSectorYearsExperiences,
  investmentReasons,
  investmentLevels,
  riskToleranceLevels,
  bankNames,
  privateBusinessIndustries,
  businessTypeMapping,
  investmentYearsOfExperience,
  annualIncomeSource,
  wealthSources,
  yesOrNo,
  defaultIncomeandSourceValue = []
) => {
  let tabDetails = [];
  const tabData = getFieldsByStep(kycData, StepEnums.INCOME_AND_WEALTH_STEP);

  const {
    [KycKeys.ACCOUNT_PURPOSE]: accountPurpose,
    [KycKeys.ASSETS_VALUE]: assetsValue,
    [KycKeys.TRANSACTIONS_VALUE_PAST_TWO_YEARS]: transactionsValuePastTwoYears,
    [KycKeys.FINANCIAL_SECTOR_YEARS_EXPERIENCE]: financialSectorYearsExperience,
    [KycKeys.ACCOUNT_PURPOSE_OTHER]: accountPurposeOther,
    [KycKeys.INCOME_TYPE]: incomeType,
    [KycKeys.INCOME_ANNUAL_V2]: incomeAnnual,
    [KycKeys.INCOME_DETAILS]: incomeDetails,
    [KycKeys.INCOME_SOURCE_V2]: incomeSource,
    [KycKeys.INVESTMENT_ADVISOR]: investmentAdvisor,
    [KycKeys.INVESTMENT_LEVEL]: investmentLevel,
    [KycKeys.INVESTMENT_REASON]: investmentReason,
    [KycKeys.INVESTMENT_YEARS_EXPERIENCE]: investmentYearsExperience,
    [KycKeys.WEALTH_AMOUNT]: wealthAmount,
    [KycKeys.PRIVATE_BUSINESS_COMPANY]: privateBusinessCompany,
    [KycKeys.PRIVATE_BUSINESS_INDUSTRY]: privateBusinessIndustry,
    [KycKeys.PRIVATE_BUSINESS_JOB]: privateBusinessJob,
    [KycKeys.PRIVATE_BUSINESS_SAME_EMPLOYMENT_DETAILS]: privateBusinessSameEmploymentDetails,
    [KycKeys.PRIVATE_BUSINESS_SECTOR]: privateBusinessSector,
    [KycKeys.PRIVATE_BUSINESS_SENIOR_EXECUTIVE]: privateBusinessSeniorExecutive,
    [KycKeys.RISK_TOLERANCE_LEVEL]: riskToleranceLevel,
    [KycKeys.WEALTH_SOURCE]: wealthSource,
    [KycKeys.WEALTH_AMOUNT_INHERITANCE]: wealthAmountInheritance,
    [KycKeys.WEALTH_AMOUNT_INVESTMENTS]: wealthAmountInvestments,
    [KycKeys.WEALTH_AMOUNT_JOB]: wealthAmountJob,
    [KycKeys.WEALTH_AMOUNT_OTHER]: wealthAmountOther,
    [KycKeys.WEALTH_AMOUNT_SALE]: wealthAmountSale,
    [KycKeys.WEALTH_AMOUNT_SAVING]: wealthAmountSaving,
    [KycKeys.WEALTH_DETAILS_INHERITANCE]: wealthDetailsInheritance,
    [KycKeys.WEALTH_DETAILS_INVESTMENTS]: wealthDetailsInvestments,
    [KycKeys.WEALTH_DETAILS_OTHER]: wealthDetailsOther,
    [KycKeys.WEALTH_DETAILS_SALE]: wealthDetailsSale,
    [KycKeys.WEALTH_DETAILS_SAVING]: wealthDetailsSaving,
    [KycKeys.BANK_NAME]: bankName,
    [KycKeys.IBAN_NUMBER]: iban
  } = tabData;

  const businessTypes = kycData[KycKeys.PRIVATE_BUSINESS_INDUSTRY].value
    ? businessTypeMapping[kycData[KycKeys.PRIVATE_BUSINESS_INDUSTRY].value] || {}
    : {};

  defaultIncomeandSourceValue.forEach(item => {
    if (!incomeSource.includes(item.value)) {
      incomeSource.push(item.value);
    }
  });

  const privateBusinessSameEmploymentDetailsValue = convertBooleanToYesNo(
    privateBusinessSameEmploymentDetails
  );
  const seniorExecutiveOrOwnerValue = convertBooleanToYesNo(privateBusinessSeniorExecutive);
  const investmentAdvisorValue = convertBooleanToYesNo(investmentAdvisor);
  const isOtherInIncomeSource = incomeSource.some(obj => obj === incomeSourceEnums.OTHER);
  const isBusinessDetailsSectionVisible = incomeSource.some(
    obj => obj === incomeSourceEnums.PRIVATE_BUSINESS
  );
  const isJobInWealthSource = wealthSource.some(item => item === wealthSourceEnums.JOB_INDEMNITY);
  const isInheritanceInWealthSource = wealthSource.some(
    item => item === wealthSourceEnums.INHERITANCE
  );
  const isInvestmentInWealthSource = wealthSource.some(
    item => item === wealthSourceEnums.INVESTMENT
  );
  const isSavingInWealthSource = wealthSource.some(item => item === wealthSourceEnums.SAVING);
  const isSaleInWealthSource = wealthSource.some(
    item => item === wealthSourceEnums.SALE_OF_PROPERTY
  );
  const isOtherInWealthSource = wealthSource.some(item => item === wealthSourceEnums.OTHER);
  const bankLabel = bankNames[bankName];
  tabDetails = [
    {
      id: KycKeys.ACCOUNT_PURPOSE,
      label: 'purposeOfOpeningTheAccountLabel',
      value: accountPurposes[accountPurpose] || 'NALabel'
    }
  ];
  if (accountPurpose === accountPurposeEnums.OTHER) {
    tabDetails.push({
      id: KycKeys.ACCOUNT_PURPOSE_OTHER,
      label: 'purposeOfOpeningTheAccountForOtherLabel',
      value: accountPurposeOther || 'NALabel'
    });
  }
  tabDetails.push(
    {
      id: KycKeys.INCOME_ANNUAL_V2,
      label: 'annualIncomeLabel',
      value: incomeAnual[incomeAnnual] || 'NALabel'
    },
    {
      id: KycKeys.INCOME_SOURCE_V2,
      label: 'sourceAnnualIncomeLabel',
      value: incomeSource.length > 0 ? getArrayLabels(incomeSource, annualIncomeSource) : 'NALabel'
    },
    {
      id: KycKeys.INCOME_DETAILS,
      label: 'incomeDetailsLabel',
      value: incomeDetails || 'NALabel'
    }
  );
  if (incomeSource && isOtherInIncomeSource) {
    tabDetails.push({
      id: KycKeys.INCOME_TYPE,
      label: 'incomeTypeLabel',
      value: incomeType || 'NALabel'
    });
  }

  if (isBusinessDetailsSectionVisible) {
    tabDetails.push({
      id: KycKeys.PRIVATE_BUSINESS_SAME_EMPLOYMENT_DETAILS,
      label: 'sameBusinessDetailsLabel',
      value: yesOrNo[privateBusinessSameEmploymentDetailsValue] || 'NALabel'
    });

    if (
      privateBusinessSameEmploymentDetailsValue &&
      privateBusinessSameEmploymentDetailsValue === generalEnums.NO
    ) {
      tabDetails.push(
        {
          id: KycKeys.PRIVATE_BUSINESS_INDUSTRY,
          label: 'businessSectorLabel',
          value: privateBusinessIndustries[privateBusinessIndustry] || 'NALabel'
        },
        {
          id: KycKeys.PRIVATE_BUSINESS_SECTOR,
          label: 'typeOfBusinessLabel',
          value: businessTypes[privateBusinessSector] || 'NALabel'
        },
        {
          id: KycKeys.PRIVATE_BUSINESS_COMPANY,
          label: 'privateBusinessNameLabel',
          value: privateBusinessCompany || 'NALabel'
        },
        {
          id: KycKeys.PRIVATE_BUSINESS_JOB,
          label: 'jobTitleLabel',
          value: privateBusinessJob || 'NALabel'
        },
        {
          id: KycKeys.PRIVATE_BUSINESS_SENIOR_EXECUTIVE,
          label: 'seniorExecutiveOrOwnerLabel',
          value: yesOrNo[seniorExecutiveOrOwnerValue] || 'NALabel'
        }
      );
    }
  }
  tabDetails.push(
    {
      id: KycKeys.INVESTMENT_REASON,
      label: 'investingReasonLabel',
      value: investmentReasons[investmentReason] || 'NALabel'
    },
    {
      id: KycKeys.INVESTMENT_LEVEL,
      label: 'investmentKnowledgeLevelLabel',
      value: investmentLevels[investmentLevel] || 'NALabel'
    },
    {
      id: KycKeys.INVESTMENT_YEARS_EXPERIENCE,
      label: 'yearsOfInvestmentExperienceLabel',
      value: investmentYearsOfExperience[investmentYearsExperience] || 'NALabel'
    },
    {
      id: KycKeys.RISK_TOLERANCE_LEVEL,
      label: 'levelOfRiskToleranceLabel',
      value: riskToleranceLevels[riskToleranceLevel] || 'NALabel'
    },
    {
      id: KycKeys.INVESTMENT_ADVISOR,
      label: 'clientHaveInvestmentAdvisorLabel',
      value: yesOrNo[investmentAdvisorValue] || 'NALabel'
    },
    {
      id: KycKeys.ASSETS_VALUE,
      label: 'assetsValueLabel',
      value: assetsValues[assetsValue] || 'NALabel'
    },
    {
      id: KycKeys.TRANSACTIONS_VALUE_PAST_TWO_YEARS,
      label: 'transactionsValuePastTwoYearsLabel',
      value: transactionsValuesPastTwoYears[transactionsValuePastTwoYears] || 'NALabel'
    },
    {
      id: KycKeys.FINANCIAL_SECTOR_YEARS_EXPERIENCE,
      label: 'financialSectorYearsExperienceLabel',
      value: financialSectorYearsExperiences[financialSectorYearsExperience] || 'NALabel'
    },
    {
      id: KycKeys.WEALTH_SOURCE,
      label: 'sourceOfWealthLabel',
      value: wealthSource.length > 0 ? getArrayLabels(wealthSource, wealthSources) : 'NALabel'
    }
  );
  if (isJobInWealthSource) {
    tabDetails.push({
      id: KycKeys.WEALTH_AMOUNT_JOB,
      label: 'wealthAmountJobLabel',
      value: wealthAmountJob || 'NALabel'
    });
  }

  if (isInheritanceInWealthSource) {
    tabDetails.push(
      {
        id: KycKeys.WEALTH_AMOUNT_INHERITANCE,
        label: 'wealthAmountInheritanceLabel',
        value: wealthAmountInheritance || 'NALabel'
      },
      {
        id: KycKeys.WEALTH_DETAILS_INHERITANCE,
        label: 'wealthDetailsInheritanceLabel',
        value: wealthDetailsInheritance || 'NALabel'
      }
    );
  }

  if (isInvestmentInWealthSource) {
    tabDetails.push(
      {
        id: KycKeys.WEALTH_AMOUNT_INVESTMENTS,
        label: 'wealthAmountInvestmentsLabel',
        value: wealthAmountInvestments || 'NALabel'
      },
      {
        id: KycKeys.WEALTH_DETAILS_INVESTMENTS,
        label: 'wealthDetailsInvestmentsLabel',
        value: wealthDetailsInvestments || 'NALabel'
      }
    );
  }

  if (isSavingInWealthSource) {
    tabDetails.push(
      {
        id: KycKeys.WEALTH_AMOUNT_SAVING,
        label: 'wealthAmountSavingLabel',
        value: wealthAmountSaving || 'NALabel'
      },
      {
        id: KycKeys.WEALTH_DETAILS_SAVING,
        label: 'wealthDetailsSavingLabel',
        value: wealthDetailsSaving || 'NALabel'
      }
    );
  }

  if (isSaleInWealthSource) {
    tabDetails.push(
      {
        id: KycKeys.WEALTH_AMOUNT_SALE,
        label: 'wealthAmountSaleLabel',
        value: wealthAmountSale || 'NALabel'
      },
      {
        id: KycKeys.WEALTH_DETAILS_SALE,
        label: 'wealthDetailsSaleLabel',
        value: wealthDetailsSale || 'NALabel'
      }
    );
  }

  if (isOtherInWealthSource) {
    tabDetails.push(
      {
        id: KycKeys.WEALTH_AMOUNT_OTHER,
        label: 'wealthAmountOtherLabel',
        value: wealthAmountOther || 'NALabel'
      },
      {
        id: KycKeys.WEALTH_DETAILS_OTHER,
        label: 'wealthDetailsOtherLabel',
        value: wealthDetailsOther || 'NALabel'
      }
    );
  }
  tabDetails.push(
    {
      id: KycKeys.WEALTH_AMOUNT,
      label: 'wealthAmountLabel',
      value: formatNumberWithComma(wealthAmount) || 'NALabel'
    },
    {
      id: KycKeys.BANK_NAME,
      label: 'bankName',
      value: bankLabel || 'NALabel'
    },
    {
      id: KycKeys.IBAN_NUMBER,
      label: 'iban',
      value: iban || 'NALabel'
    }
  );
  return tabDetails;
};

const getAdditionalInfoStepDetails = (kycData, positions, relationship, countries, yesOrNo) => {
  let tabDetails = [];
  const tabData = getFieldsByStep(kycData, StepEnums.ADDITIONAL_INFO_STEP);
  const {
    [KycKeys.PAYS_TAXES_IN_OTHER_COUNTRY]: paysTaxesInOtherCountry,
    [KycKeys.POLITICAL_POSITION_EXISTING]: politicalPositionExisting,
    [KycKeys.POLITICAL_POSITION_IS_ME]: politicalPositionIsMe,
    [KycKeys.POLITICAL_POSITION_NAME]: politicalPositionName,
    [KycKeys.POLITICAL_POSITION_RELATIONSHIP]: politicalPositionRelationship,
    [KycKeys.POLITICAL_POSITION_ROLE]: politicalPositionRole,
    [KycKeys.TAX_COUNTRY_1]: taxCountry1,
    [KycKeys.TAX_COUNTRY_2]: taxCountry2,
    [KycKeys.TAX_COUNTRY_3]: taxCountry3,
    [KycKeys.TAX_PAYER_ID_1]: taxPayerId1,
    [KycKeys.TAX_PAYER_ID_2]: taxPayerId2,
    [KycKeys.TAX_PAYER_ID_3]: taxPayerId3,
    [KycKeys.TAX_PAYER_IDENTIFICATION]: taxPayerIdentification,
    [KycKeys.US_ADDRESS_1]: usAddress1,
    [KycKeys.US_ADDRESS_2]: usAddress2,
    [KycKeys.US_ADDRESS_3]: usAddress3,
    [KycKeys.US_PASSPORT_NUMBER]: usPassportNumber,
    [KycKeys.US_PASSPORT_EXPIRY_DATE]: usPassportExpiryDate,
    [KycKeys.FULL_NAME_AS_IN_US_PASSPORT]: usPassportFullName,
    us_citizen: usCitizen
  } = tabData;
  const politicalPositionExistingValue = convertBooleanToYesNo(politicalPositionExisting);
  const usCitizenValue = convertBooleanToYesNo(usCitizen);
  const payTaxesInAnotherCountryValue = convertBooleanToYesNo(paysTaxesInOtherCountry);
  const politicalPositionIsMeValue = convertBooleanToYesNo(politicalPositionIsMe);

  tabDetails = [
    {
      id: KycKeys.POLITICAL_POSITION_EXISTING,
      label: 'areYouPep',
      value: yesOrNo[politicalPositionExistingValue] || 'NALabel'
    }
  ];
  if (politicalPositionExisting) {
    tabDetails.push(
      {
        id: KycKeys.POLITICAL_POSITION_IS_ME,
        label: 'isMePep',
        value: yesOrNo[politicalPositionIsMeValue] || 'NALabel'
      },
      {
        id: KycKeys.POLITICAL_POSITION_ROLE,
        label: 'politicalPosition',
        value: positions[politicalPositionRole] || 'NALabel'
      }
    );
    if (politicalPositionIsMeValue === generalEnums.NO) {
      tabDetails.push(
        {
          id: KycKeys.POLITICAL_POSITION_NAME,
          label: 'nameOfFamilyMember',
          value: politicalPositionName || 'NALabel'
        },
        {
          id: KycKeys.POLITICAL_POSITION_RELATIONSHIP,
          label: 'relationToFamilyMember',
          value: relationship[politicalPositionRelationship] || 'NALabel'
        }
      );
    }
  }
  tabDetails.push({
    id: KycKeys.US_CITIZEN,
    label: 'isUsCitizen',
    value: yesOrNo[usCitizenValue] || 'NALabel'
  });
  if (usCitizen) {
    tabDetails.push(
      {
        id: KycKeys.FULL_NAME_AS_IN_US_PASSPORT,
        label: 'USPassportFullName',
        value: usPassportFullName || 'NALabel'
      },
      {
        id: KycKeys.US_PASSPORT_NUMBER,
        label: 'USPassportNumber',
        value: usPassportNumber || 'NALabel'
      },
      {
        id: KycKeys.US_PASSPORT_EXPIRY_DATE,
        label: 'USPassportExpiryDate',
        value: DateUtils.formatDate(usPassportExpiryDate) || 'NALabel'
      },
      {
        id: KycKeys.TAX_PAYER_IDENTIFICATION,
        label: 'taxPayerId',
        value: taxPayerIdentification || 'NALabel'
      },
      {
        id: KycKeys.US_ADDRESS_1,
        label: 'usAddress1',
        value: usAddress1 || 'NALabel'
      },
      {
        id: KycKeys.US_ADDRESS_2,
        label: 'usAddress2',
        value: usAddress2 || 'NALabel'
      },
      {
        id: KycKeys.US_ADDRESS_3,
        label: 'usAddress3',
        value: usAddress3 || 'NALabel'
      }
    );
  }
  tabDetails.push({
    id: KycKeys.PAYS_TAXES_IN_OTHER_COUNTRY,
    label: 'payTaxInAnotherCountry',
    value: yesOrNo[payTaxesInAnotherCountryValue] || 'NALabel'
  });
  if (paysTaxesInOtherCountry) {
    tabDetails.push({
      id: KycKeys.TAX_COUNTRY_1,
      label: 'taxCountryOne',
      value: getCountryName(taxCountry1, countries) || 'NALabel'
    });

    if (taxCountry1) {
      tabDetails.push({
        id: KycKeys.TAX_PAYER_ID_1,
        label: 'taxIdOne',
        value: taxPayerId1 || 'NALabel'
      });
    }
    tabDetails.push({
      id: KycKeys.TAX_COUNTRY_2,
      label: 'taxCountryTwo',
      value: getCountryName(taxCountry2, countries) || 'NALabel'
    });
    if (taxCountry2) {
      tabDetails.push({
        id: KycKeys.TAX_PAYER_ID_2,
        label: 'taxIdTwo',
        value: taxPayerId2 || 'NALabel'
      });
    }
    tabDetails.push({
      id: KycKeys.TAX_COUNTRY_3,
      label: 'taxCountryThree',
      value: getCountryName(taxCountry3, countries) || 'NALabel'
    });
    if (taxCountry3) {
      tabDetails.push({
        id: KycKeys.TAX_PAYER_ID_3,
        label: 'taxIdThree',
        value: taxPayerId3 || 'NALabel'
      });
    }
  }

  return tabDetails;
};

const getAdditionalInfoStepDetailsV2 = (
  kycData,
  positions,
  politicalPositionStatuses,
  relationships,
  countries,
  yesOrNo
) => {
  let tabDetails = [];
  const tabData = getFieldsByStep(kycData, StepEnums.ADDITIONAL_INFO_STEP);
  const {
    [KycKeys.PAYS_TAXES_IN_OTHER_COUNTRY]: paysTaxesInOtherCountry,
    [KycKeys.TAX_COUNTRY_1]: taxCountry1,
    [KycKeys.TAX_COUNTRY_2]: taxCountry2,
    [KycKeys.TAX_COUNTRY_3]: taxCountry3,
    [KycKeys.TAX_PAYER_ID_1]: taxPayerId1,
    [KycKeys.TAX_PAYER_ID_2]: taxPayerId2,
    [KycKeys.TAX_PAYER_ID_3]: taxPayerId3,
    [KycKeys.TAX_PAYER_IDENTIFICATION]: taxPayerIdentification,
    [KycKeys.US_ADDRESS_1]: usAddress1,
    [KycKeys.US_ADDRESS_2]: usAddress2,
    [KycKeys.US_ADDRESS_3]: usAddress3,
    [KycKeys.US_PASSPORT_NUMBER]: usPassportNumber,
    [KycKeys.US_PASSPORT_EXPIRY_DATE]: usPassportExpiryDate,
    [KycKeys.FULL_NAME_AS_IN_US_PASSPORT]: usPassportFullName,
    [KycKeys.IS_CLIENT_POLITICAL_POSITION]: isClientPoliticalPosition,
    [KycKeys.CLIENT_POLITICAL_POSITIONS]: clientPoliticalPositions,
    [KycKeys.IS_RELATIVE_POLITICAL_POSITION]: isRelativePoliticalPosition,
    [KycKeys.RELATIVE_POLITICAL_POSITIONS]: relativePoliticalPositions,
    us_citizen: usCitizen
  } = tabData;

  const usCitizenValue = convertBooleanToYesNo(usCitizen);
  const payTaxesInAnotherCountryValue = convertBooleanToYesNo(paysTaxesInOtherCountry);

  const isClientPoliticalPositionValue = convertBooleanToYesNo(isClientPoliticalPosition);
  const isRelativePoliticalPositionValue = convertBooleanToYesNo(isRelativePoliticalPosition);

  tabDetails = [
    {
      id: KycKeys.IS_CLIENT_POLITICAL_POSITION,
      label: 'isClientPep',
      value: yesOrNo[isClientPoliticalPositionValue] || 'NALabel'
    }
  ];

  if (isClientPoliticalPosition) {
    tabDetails.push({
      id: KycKeys.CLIENT_POLITIAL_POSITION_COUNT,
      label: 'politicalPositionsNumberLabel',
      value: clientPoliticalPositions.length || 'NALabel'
    });
  }

  if (isClientPoliticalPosition && clientPoliticalPositions.length) {
    clientPoliticalPositions.map(({ position, status }, index) => {
      tabDetails.push(
        {
          id: KycKeys.CLIENT_POLITICAL_POSITIONS + index + 'position',
          label: 'clientPosition',
          value: positions[position] || 'NALabel',
          counter: index + 1
        },
        {
          id: KycKeys.CLIENT_POLITICAL_POSITIONS + index + 'status',
          label: 'clientStatus',
          value: politicalPositionStatuses[status] || 'NALabel',
          counter: index + 1
        }
      );
    });
  }

  tabDetails.push({
    id: KycKeys.IS_RELATIVE_POLITICAL_POSITION,
    label: 'relativePoliticalPositionQuestionLabel',
    value: capitalizeFirstLetter(isRelativePoliticalPositionValue) || 'NALabel'
  });

  if (isRelativePoliticalPosition) {
    tabDetails.push({
      id: KycKeys.RELATIVE_POLITICAL_POSITION_COUNT,
      label: 'relativePoliticalPositionsNumberLabel',
      value: relativePoliticalPositions.length || 'NALabel'
    });
  }

  if (isRelativePoliticalPosition && relativePoliticalPositions.length) {
    relativePoliticalPositions.map(
      (
        { first_name: firstName, family_name: familyName, relationship, position, status },
        index
      ) => {
        tabDetails.push(
          {
            id: KycKeys.RELATIVE_POLITICAL_POSITIONS + index + 'firstName',
            label: 'relativeFirstName',
            value: capitalizeFirstLetter(firstName) || 'NALabel',
            counter: index + 1
          },
          {
            id: KycKeys.RELATIVE_POLITICAL_POSITIONS + index + 'familyName',
            label: 'relativeFamilyName',
            value: capitalizeFirstLetter(familyName) || 'NALabel',
            counter: index + 1
          },
          {
            id: KycKeys.RELATIVE_POLITICAL_POSITIONS + index + 'relationship',
            label: 'relativeRelationShip',
            value: relationships[relationship] || 'NALabel',
            counter: index + 1
          },
          {
            id: KycKeys.RELATIVE_POLITICAL_POSITIONS + index + 'position',
            label: 'relativePosition',
            value: positions[position] || 'NALabel',
            counter: index + 1
          },
          {
            id: KycKeys.RELATIVE_POLITICAL_POSITIONS + index + 'status',
            label: 'relativePositionStatus',
            value: politicalPositionStatuses[status] || 'NALabel',
            counter: index + 1
          }
        );
      }
    );
  }

  tabDetails.push({
    id: KycKeys.US_CITIZEN,
    label: 'isUsCitizen',
    value: yesOrNo[usCitizenValue] || 'NALabel'
  });
  if (usCitizen) {
    tabDetails.push(
      {
        id: KycKeys.FULL_NAME_AS_IN_US_PASSPORT,
        label: 'USPassportFullName',
        value: usPassportFullName || 'NALabel'
      },
      {
        id: KycKeys.US_PASSPORT_NUMBER,
        label: 'USPassportNumber',
        value: usPassportNumber || 'NALabel'
      },
      {
        id: KycKeys.US_PASSPORT_EXPIRY_DATE,
        label: 'USPassportExpiryDate',
        value: DateUtils.formatDate(usPassportExpiryDate) || 'NALabel'
      },
      {
        id: KycKeys.TAX_PAYER_IDENTIFICATION,
        label: 'taxPayerId',
        value: taxPayerIdentification || 'NALabel'
      },
      {
        id: KycKeys.US_ADDRESS_1,
        label: 'usAddress1',
        value: usAddress1 || 'NALabel'
      },
      {
        id: KycKeys.US_ADDRESS_2,
        label: 'usAddress2',
        value: usAddress2 || 'NALabel'
      },
      {
        id: KycKeys.US_ADDRESS_3,
        label: 'usAddress3',
        value: usAddress3 || 'NALabel'
      }
    );
  }
  tabDetails.push({
    id: KycKeys.PAYS_TAXES_IN_OTHER_COUNTRY,
    label: 'payTaxInAnotherCountry',
    value: yesOrNo[payTaxesInAnotherCountryValue] || 'NALabel'
  });
  if (paysTaxesInOtherCountry) {
    tabDetails.push({
      id: KycKeys.TAX_COUNTRY_1,
      label: 'taxCountryOne',
      value: getCountryName(taxCountry1, countries) || 'NALabel'
    });

    if (taxCountry1) {
      tabDetails.push({
        id: KycKeys.TAX_PAYER_ID_1,
        label: 'taxIdOne',
        value: taxPayerId1 || 'NALabel'
      });
    }
    tabDetails.push({
      id: KycKeys.TAX_COUNTRY_2,
      label: 'taxCountryTwo',
      value: getCountryName(taxCountry2, countries) || 'NALabel'
    });
    if (taxCountry2) {
      tabDetails.push({
        id: KycKeys.TAX_PAYER_ID_2,
        label: 'taxIdTwo',
        value: taxPayerId2 || 'NALabel'
      });
    }
    tabDetails.push({
      id: KycKeys.TAX_COUNTRY_3,
      label: 'taxCountryThree',
      value: getCountryName(taxCountry3, countries) || 'NALabel'
    });
    if (taxCountry3) {
      tabDetails.push({
        id: KycKeys.TAX_PAYER_ID_3,
        label: 'taxIdThree',
        value: taxPayerId3 || 'NALabel'
      });
    }
  }

  return tabDetails;
};

export const getAccordionDefaultOpen = missedFields => {
  const steps = [
    {
      key: 'personalInfoStepTitle',
      field: missedFields.validations.personalInfo
    },
    { key: 'contactInformation', field: missedFields.validations.contactInfo },
    {
      key: 'EmploymentInfoStepTitle',
      field: missedFields.validations.employment
    },
    {
      key: 'incomeAndWealthStepTitle',
      field: missedFields.validations.incomeAndWealth
    },
    { key: 'additionalInfo', field: missedFields.validations.additionalInfo }
  ];

  const step = steps.find(({ field }) => field.length > 0);
  return step ? step.key : null;
};

export {
  getContactInfoStepDetails,
  getPersonalInfoStepDetails,
  getEmploymentStepDetails,
  getAdditionalInfoStepDetails,
  getAdditionalInfoStepDetailsV2,
  getIncomeAndWealthTabDetails
};
