import React from 'react';
import KYCValidator from '../../../helpers/validation';
import CustomSelect from '../../../widgets/inputs/custom-select';
import KycKeys from '../../../enums/kyc-enum';
import { createChildrenDetailsList } from '../../../helpers/general-helpers';
import PropTypes from 'prop-types';

const ChildrenNumber = ({
  data,
  setKycData,
  translator,
  counterValue = 1,
  setCounter = () => {}
}) => {
  const fieldKey = KycKeys.CHILDREN_NUMBER;
  const childrenDetailsKey = KycKeys.CHILDREN_DETAILS;

  const {
    [fieldKey]: { value, error, isDisabled },
    [childrenDetailsKey]: { value: childrentList }
  } = data;

  const validateField = value => {
    const childrenDetailsList = createChildrenDetailsList(value, childrentList);
    const fieldErrorObject = KYCValidator.validateField(value);

    setKycData(prev => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isValidated: true,
        error: fieldErrorObject.errorMessage,
        value
      },
      [childrenDetailsKey]: {
        ...prev[childrenDetailsKey],
        value: childrenDetailsList || [],
        isVisible: childrenDetailsList.length > 0 ? true : false
      }
    }));
  };

  const handleChange = value => {
    setCounter(counterValue + 1);
    validateField(value);
  };

  const genderListOptions = Array.from({ length: 21 }, (_, index) => ({
    label: index,
    value: index
  }));

  return (
    <CustomSelect
      name={'childrenNumber'}
      label={translator('childrenNumber')}
      onChange={handleChange}
      value={value}
      errorMessage={error}
      inputOptions={genderListOptions}
      isDisabled={isDisabled}
    />
  );
};

ChildrenNumber.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  translator: PropTypes.func.isRequired,
  setCounter: PropTypes.func
};

export default ChildrenNumber;
