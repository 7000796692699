import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import KycKeys from "../../../enums/kyc-enum";
import { politicalPositionEnums } from "../../../enums/additional-info-enums";
import KYCValidator from "../../../helpers/validation";
import CustomSelect from "../../../widgets/inputs/custom-select";

const RelativeRelationship = ({
  data,
  translator,
  setKycData,
  kycFields,
  isRenewal,
  counterValue,
}) => {
  const relativePoliticalPositionsKey = KycKeys.RELATIVE_POLITICAL_POSITIONS;
  const relativeRelationshipKey = KycKeys.RELATIVE_RELATIONSHIP;
  const {
    index: relationshipIndex,
    [relativePoliticalPositionsKey]: { value: relativePoliticalPositionsList },
  } = data;

  const { [politicalPositionEnums.RELATIONSHIP]: value } =
    relativePoliticalPositionsList[relationshipIndex];

  const { relationship } = kycFields;

  const [errorMessage, setErrorMessage] = useState("");

  const items = Object.entries(relationship)?.map(([key, value]) => ({
    label: value,
    value: key,
  }));

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);
    if (fieldErrorObject.errorMessage) {
      setErrorMessage(fieldErrorObject.errorMessage);
    } else {
      setErrorMessage("");
    }

    const updatedList = [...relativePoliticalPositionsList];

    updatedList[relationshipIndex] = {
      ...updatedList[relationshipIndex],
      [politicalPositionEnums.RELATIONSHIP]: value,
    };

    setKycData((prev) => ({
      ...prev,
      [relativePoliticalPositionsKey]: {
        ...prev[relativePoliticalPositionsKey],
        error: "",
        value: updatedList,
      },
    }));
  };

  const handleChange = (value) => {
    validateField(value);
  };

  useEffect(() => {
    if (isRenewal && counterValue == 1) {
      validateField(value);
    } else if (isRenewal && counterValue !== 1) {
      setErrorMessage("");
    }
  }, [counterValue]);

  return (
    <CustomSelect
      name={`${relativeRelationshipKey}_${relationshipIndex}`}
      inputOptions={items}
      label={translator("relativeRelationShip", {
        counter: relationshipIndex + 1,
      })}
      onChange={handleChange}
      value={value}
      errorMessage={errorMessage}
    />
  );
};

RelativeRelationship.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  validationCycle: PropTypes.number.isRequired,
  isSaveForLater: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  kycFields: PropTypes.object.isRequired,
  optionalHandleChange: PropTypes.func,
  isSectionDisabled: PropTypes.bool,
};

export default RelativeRelationship;
