import React from 'react';
import { Navigate, Outlet } from 'react-router';
import { landingRoute } from './routes.const';
import { getToken, setToken } from '../utils/auth';

const ProtectedRoute = props => {
  let token = getToken();
  if (!token) {
    const queryParams = new URLSearchParams(window.location.search);
    token = queryParams.get('token');
    setToken(token);
  }
  if (!token) {
    return <Navigate to={landingRoute} />;
  }

  return <Outlet {...props} />;
};

export default ProtectedRoute;
