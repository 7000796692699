import React, { useEffect, useState } from "react";
import KycKeys from "../../../enums/kyc-enum";
import KYCValidator from "../../../helpers/validation";
import { politicalPositionEnums } from "../../../enums/additional-info-enums";
import PropTypes from "prop-types";
import CustomSelect from "../../../widgets/inputs/custom-select";

const PoliticalPosition = ({
  data,
  setKycData,
  kycFields,
  translator,
  isRenewal,
  counterValue,
}) => {
  const clientPoliticalPositionsKey = KycKeys.CLIENT_POLITICAL_POSITIONS;
  const clientPoliticalPositionRoleKey = KycKeys.CLIENT_POLITICAL_POSITION_ROLE;
  const {
    index: roleIndex,
    [clientPoliticalPositionsKey]: { value: clientPoliticalPositionsList },
  } = data;

  const { [politicalPositionEnums.POSITION]: value } =
    clientPoliticalPositionsList[roleIndex];

  const { political_position_role: positions } = kycFields;

  const [errorMessage, setErrorMessage] = useState("");

  const options = Object.keys(positions)?.map((key) => ({
    label: positions[key],
    value: key,
  }));

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);

    if (fieldErrorObject.errorMessage) {
      setErrorMessage(fieldErrorObject.errorMessage);
    } else {
      setErrorMessage("");
    }

    const updatedList = [...clientPoliticalPositionsList];

    updatedList[roleIndex] = {
      ...updatedList[roleIndex],
      [politicalPositionEnums.POSITION]: value,
    };

    setKycData((prev) => ({
      ...prev,
      [clientPoliticalPositionsKey]: {
        ...prev[clientPoliticalPositionsKey],
        error: "",
        value: updatedList,
      },
    }));
  };

  const handleChange = (value) => {
    validateField(value);
  };

  useEffect(() => {
    if (isRenewal && counterValue == 1) {
      validateField(value);
    } else if (isRenewal && counterValue !== 1) {
      setErrorMessage("");
    }
  }, [counterValue]);

  return (
    <CustomSelect
      name={`${clientPoliticalPositionRoleKey}_${roleIndex}`}
      inputOptions={options}
      label={translator("clientPosition", { counter: roleIndex + 1 })}
      onChange={handleChange}
      value={value}
      errorMessage={errorMessage}
    />
  );
};

PoliticalPosition.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  kycFields: PropTypes.object.isRequired,
};

export default PoliticalPosition;
