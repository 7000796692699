import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './accordion.module.scss';
import { useNavigate } from 'react-router';
import { kycSummaryStepRoute } from '../../routes/routes.const';
import arrowDown from '../../assets/icons/arrow-down.svg';
import editIcon from '../../assets/icons/edit-icon.svg';
import { useTranslation } from 'react-i18next';

const Accordion = ({ accordionTitle, accordionData, routeName, testId }) => {
  const contentHeight = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  const [isOpen, setIsOpen] = useState(false);

  const handleEditClick = event => {
    event.stopPropagation();
    navigate({
      pathname: routeName
    });
  };

  const handleItemClick = () => {
    setIsOpen(prevOpen => !prevOpen);
  };
  const displayAccordionDetails = () =>
    accordionData?.map(item => (
      <div key={item.id}>
        <span className={styles.fieldLabel}>
          {item.label === 'childFullName'
            ? `${t(item.label)} (${item.childNumber})`
            : item.counter
              ? t(item.label, { counter: item.counter })
              : t(`${item.label}`)}
        </span>
        <span className={styles.fieldValue}>
          {Array.isArray(item.value)
            ? item.value.join(', ')
            : item.value === 'NALabel'
              ? t(`${item.value}`)
              : item.value}
        </span>
      </div>
    ));

  return (
    <div className={styles.wrapper} onClick={handleItemClick} data-testid='accordion-wrapper'>
      <div className={`${styles.accordionTitleContainer} ${isOpen ? styles.active : ''}`}>
        <p className={styles.accordionTitleContent}>{accordionTitle}</p>
        <div className={styles.headerActions}>
          <div className={styles.editAction} onClick={handleEditClick}>
            <img src={editIcon} alt='Edit Icon' />
            <div className={styles.editButton}>{t('editButtonLabel')}</div>
          </div>
          <img
            data-testid={`arrow${testId}`}
            src={arrowDown}
            alt='Arrow'
            className={`${styles.arrow} ${isOpen ? styles.active : ''}`}
          />
        </div>
      </div>
      {isOpen && <hr className={styles.hr} />}
      <div
        ref={contentHeight}
        className={styles.answerContainer}
        style={
          isOpen
            ? { height: contentHeight.current.scrollHeight }
            : { height: '0px', overflow: 'hidden' }
        }
        data-testid='content-container'
      >
        {displayAccordionDetails()}
      </div>
    </div>
  );
};

Accordion.propTypes = {
  accordionTitle: PropTypes.string.isRequired,
  accordionData: PropTypes.array.isRequired,
  routeName: PropTypes.string.isRequired,
  testId: PropTypes.string.isRequired
};

export default Accordion;
