import React, { useState, useEffect } from 'react';
import PrimaryButton from '../../widgets/buttons/primary-button';
import kycFields from '../../config/kyc';
import KycKeys from '../../enums/kyc-enum';
import { useSelector } from 'react-redux';
import PoliticalPositionIsMe from './components/political-position-is-me';
import PoliticalPositionRole from './components/political-position-role';
import StepEnums from '../../enums/step-enum';
import {
  getKycData,
  putKycData,
  putKycDataV3,
  getCustomer,
  getKycDataV3
} from '../../general-services.proxy';
import { useNavigate } from 'react-router';
import SkeletonLoader from '../../widgets/fields-skeleton';
import { kycSummaryStepRoute } from '../../routes/routes.const';
import PoliticalPositionExisting from './components/political-position-existing';
import PoliticalPositionName from './components/political-position-name';
import PoliticalPositionRelationship from './components/political-position-relationship';
import UsCitizen from './components/us-citizen';
import USPassportNumber from './components/us-passport-number';
import USPassportFullName from './components/full-name-as-in-us-passport';
import USPassportExpiryDate from './components/us-passport-expiry-date';
import TaxPayerIdentification from './components/tax-payer-id';
import UsAddressOne from './components/us-address-1';
import UsAddressTwo from './components/us-address-2';
import UsAddressThree from './components/us-address-3';
import PayTaxesInAnotherCountry from './components/pay-tax-another-country';
import TaxCountryOne from './components/tax-country-1';
import { countriesEnums } from '../../enums/general';
import TaxPayerIdOne from './components/tax-payer-id-1';
import TaxCountryTwo from './components/tax-country-2';
import TaxPayerIdTwo from './components/tax-payer-id-2';
import TaxCountryThree from './components/tax-country-3';
import TaxPayerIdThree from './components/tax-payer-id-3';
import MainLayout from '../../layouts/main-layout';
import { scrollToTop } from '../../utils';
import { useTranslation } from 'react-i18next';
import { newPepFieldsFeatureFlag } from '../../config/feature-flags';
import ClientPoliticalPosition from './components/client-political-position';
import ClientPoliticalPositionCount from './components/client-political-position-count';
import ClientPoliticalPositions from './political-client-details/client-political-positions';
import RelativePoliticalPosition from './components/relative-political-position';
import RelativePoliticalPositionCount from './components/relative-political-position-count';
import RelativePoliticalPositions from './relative-political-details/relative-political-positions';

const AdditionalInfoStep = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const [kycData, setKycData] = useState(kycFields);
  const { kycFields: kycFieldsOptions, countries } = useSelector(state => state.general);

  const [isLoading, setLoading] = useState(true);
  const [orderId, setOrderId] = useState('');
  const isKuwaityCitizen =
    kycData[KycKeys.NATIONALITY].value === countriesEnums.KUWAIT ||
    kycData[KycKeys.COUNTRY].value === countriesEnums.KUWAIT;

  const isUSCitizenByAnyNationality =
    kycData[KycKeys.NATIONALITY].value === 'US' ||
    kycData[KycKeys.OTHER_NATIONALITIES].value.includes('US');

  const customerCivilIdNumber = kycData[KycKeys.CIVIL_ID_NUMBER].value;
  const handleNavigation = () => {
    navigate({
      pathname: kycSummaryStepRoute
    });
  };

  const saveKycData = async () => {
    try {
      setLoading(true);
      await (newPepFieldsFeatureFlag
        ? putKycDataV3(orderId, kycData, StepEnums.ADDITIONAL_INFO_STEP)
        : putKycData(orderId, kycData, StepEnums.ADDITIONAL_INFO_STEP));

      handleNavigation();
    } catch (exception) {
      console.error(exception);
    } finally {
      setLoading(false);
    }
  };

  const handleNextActionClick = () => {
    saveKycData();
  };

  const fetchKycData = async () => {
    try {
      setLoading(true);
      const { orderId, orderKycId } = await getCustomer();
      setOrderId(orderId);
      const data = await (newPepFieldsFeatureFlag
        ? getKycDataV3(orderId, orderKycId)
        : getKycData(orderId, orderKycId));

      setKycData(data);
    } catch (exception) {
      console.error(exception);
    } finally {
      setLoading(false);
      scrollToTop();
    }
  };

  let isContinueDisabled;
  const stepName = StepEnums.ADDITIONAL_INFO_STEP;

  if (newPepFieldsFeatureFlag && !isLoading) {
    const clientPoliticalPositionsData = kycData[KycKeys.CLIENT_POLITICAL_POSITIONS].value;

    const politicalPositionsNumber = kycData[KycKeys.CLIENT_POLITICAL_POSITIONS].value.length;

    const politicalPositionsDataFilled = clientPoliticalPositionsData.every(
      item => Object.values(item).every(value => value !== '') && !item.error
    );

    const relativePoliticalPositionsData = kycData[KycKeys.RELATIVE_POLITICAL_POSITIONS].value;

    const relativePoliticalPositionsNumber =
      kycData[KycKeys.RELATIVE_POLITICAL_POSITIONS].value.length;

    const relativePoliticalPositionsDataFilled = relativePoliticalPositionsData.every(
      item => Object.values(item).every(value => value !== '') && !item.error
    );

    isContinueDisabled = Object.keys(kycData)
      .filter(key => kycData[key].step === stepName && kycData[key].isVisible)
      .some(
        key =>
          (!kycData[key].isOptional && !kycData[key].value && kycData[key].value !== false) ||
          (politicalPositionsNumber > 0 && !politicalPositionsDataFilled) ||
          (relativePoliticalPositionsNumber > 0 && !relativePoliticalPositionsDataFilled) ||
          kycData[key].error
      );
  } else {
    isContinueDisabled = Object.keys(kycData)
      .filter(key => kycData[key].step === stepName && kycData[key].isVisible)
      .some(
        key =>
          (!kycData[key].isOptional && !kycData[key].value && kycData[key].value !== false) ||
          kycData[key].error
      );
  }

  useEffect(() => {
    fetchKycData();
    return () => {
      setKycData(kycFields);
    };
  }, []);

  const renderFooter = () => (
    <PrimaryButton
      disabled={isContinueDisabled || isLoading}
      onClick={() => {
        handleNextActionClick();
      }}
      data-testid={'saveandcontinue'}
    >
      {t('saveAndContinueButton')}
    </PrimaryButton>
  );
  return (
    <MainLayout stepTitle={t('additionalInfo')} footer={renderFooter} stepNumber={5}>
      {isLoading ? (
        <SkeletonLoader numberOfFields={8} />
      ) : (
        <>
          {newPepFieldsFeatureFlag && (
            <>
              <ClientPoliticalPosition
                data={{
                  [KycKeys.IS_CLIENT_POLITICAL_POSITION]:
                    kycData[KycKeys.IS_CLIENT_POLITICAL_POSITION]
                }}
                setKycData={setKycData}
                translator={t}
                kycFields={kycFieldsOptions}
              />
              <ClientPoliticalPositionCount
                data={{
                  [KycKeys.CLIENT_POLITIAL_POSITION_COUNT]:
                    kycData[KycKeys.CLIENT_POLITIAL_POSITION_COUNT],
                  [KycKeys.IS_CLIENT_POLITICAL_POSITION]:
                    kycData[KycKeys.IS_CLIENT_POLITICAL_POSITION],
                  [KycKeys.CLIENT_POLITICAL_POSITIONS]: kycData[KycKeys.CLIENT_POLITICAL_POSITIONS]
                }}
                setKycData={setKycData}
                translator={t}
              />
              <ClientPoliticalPositions
                data={{
                  [KycKeys.CLIENT_POLITICAL_POSITIONS]: kycData[KycKeys.CLIENT_POLITICAL_POSITIONS]
                }}
                setKycData={setKycData}
                kycFields={kycFieldsOptions}
                translator={t}
              />
              <RelativePoliticalPosition
                data={{
                  [KycKeys.IS_RELATIVE_POLITICAL_POSITION]:
                    kycData[KycKeys.IS_RELATIVE_POLITICAL_POSITION]
                }}
                setKycData={setKycData}
                translator={t}
                kycFields={kycFieldsOptions}
              />
              <RelativePoliticalPositionCount
                data={{
                  [KycKeys.RELATIVE_POLITICAL_POSITION_COUNT]:
                    kycData[KycKeys.RELATIVE_POLITICAL_POSITION_COUNT],
                  [KycKeys.IS_RELATIVE_POLITICAL_POSITION]:
                    kycData[KycKeys.IS_RELATIVE_POLITICAL_POSITION],
                  [KycKeys.RELATIVE_POLITICAL_POSITIONS]:
                    kycData[KycKeys.RELATIVE_POLITICAL_POSITIONS]
                }}
                setKycData={setKycData}
                translator={t}
              />

              <RelativePoliticalPositions
                data={{
                  [KycKeys.RELATIVE_POLITICAL_POSITIONS]:
                    kycData[KycKeys.RELATIVE_POLITICAL_POSITIONS]
                }}
                setKycData={setKycData}
                kycFields={kycFieldsOptions}
                translator={t}
              />
            </>
          )}

          {!newPepFieldsFeatureFlag && (
            <>
              <PoliticalPositionExisting
                data={{
                  [KycKeys.POLITICAL_POSITION_EXISTING]:
                    kycData[KycKeys.POLITICAL_POSITION_EXISTING]
                }}
                setKycData={setKycData}
                translator={t}
                kycFields={kycFieldsOptions}
              />
              <PoliticalPositionIsMe
                data={{
                  [KycKeys.POLITICAL_POSITION_IS_ME]: kycData[KycKeys.POLITICAL_POSITION_IS_ME],
                  [KycKeys.POLITICAL_POSITION_EXISTING]:
                    kycData[KycKeys.POLITICAL_POSITION_EXISTING]
                }}
                setKycData={setKycData}
                translator={t}
                kycFields={kycFieldsOptions}
              />
              <PoliticalPositionName
                data={{
                  [KycKeys.POLITICAL_POSITION_NAME]: kycData[KycKeys.POLITICAL_POSITION_NAME],
                  [KycKeys.POLITICAL_POSITION_IS_ME]: kycData[KycKeys.POLITICAL_POSITION_IS_ME]
                }}
                setKycData={setKycData}
                translator={t}
              />
              <PoliticalPositionRelationship
                data={{
                  [KycKeys.POLITICAL_POSITION_RELATIONSHIP]:
                    kycData[KycKeys.POLITICAL_POSITION_RELATIONSHIP],
                  [KycKeys.POLITICAL_POSITION_IS_ME]: kycData[KycKeys.POLITICAL_POSITION_IS_ME]
                }}
                setKycData={setKycData}
                translator={t}
                kycFields={kycFieldsOptions}
              />
              <PoliticalPositionRole
                data={{
                  [KycKeys.POLITICAL_POSITION_ROLE]: kycData[KycKeys.POLITICAL_POSITION_ROLE],
                  [KycKeys.POLITICAL_POSITION_EXISTING]:
                    kycData[KycKeys.POLITICAL_POSITION_EXISTING]
                }}
                setKycData={setKycData}
                translator={t}
                kycFields={kycFieldsOptions}
              />
            </>
          )}
          <UsCitizen
            data={{
              [KycKeys.US_CITIZEN]: kycData[KycKeys.US_CITIZEN]
            }}
            isUSCitizenByAnyNationality={isUSCitizenByAnyNationality}
            setKycData={setKycData}
            kycFields={kycFieldsOptions}
            translator={t}
          />
          <USPassportFullName
            data={{
              [KycKeys.FULL_NAME_AS_IN_US_PASSPORT]: kycData[KycKeys.FULL_NAME_AS_IN_US_PASSPORT],
              [KycKeys.US_CITIZEN]: kycData[KycKeys.US_CITIZEN]
            }}
            setKycData={setKycData}
            isUSCitizenByAnyNationality={isUSCitizenByAnyNationality}
            kycFields={kycFieldsOptions}
            translator={t}
          />
          <USPassportNumber
            data={{
              [KycKeys.US_PASSPORT_NUMBER]: kycData[KycKeys.US_PASSPORT_NUMBER],
              [KycKeys.PASSPORT_NUMBER]: kycData[KycKeys.PASSPORT_NUMBER],
              [KycKeys.US_CITIZEN]: kycData[KycKeys.US_CITIZEN],
              [KycKeys.NATIONALITY]: kycData[KycKeys.NATIONALITY]
            }}
            isUSCitizenByAnyNationality={isUSCitizenByAnyNationality}
            setKycData={setKycData}
            translator={t}
          />
          <USPassportExpiryDate
            data={{
              [KycKeys.US_PASSPORT_EXPIRY_DATE]: kycData[KycKeys.US_PASSPORT_EXPIRY_DATE],
              [KycKeys.PASSPORT_EXPIRY_DATE]: kycData[KycKeys.PASSPORT_EXPIRY_DATE],
              [KycKeys.US_CITIZEN]: kycData[KycKeys.US_CITIZEN],
              [KycKeys.NATIONALITY]: kycData[KycKeys.NATIONALITY]
            }}
            isUSCitizenByAnyNationality={isUSCitizenByAnyNationality}
            setKycData={setKycData}
            translator={t}
          />
          <TaxPayerIdentification
            data={{
              [KycKeys.TAX_PAYER_IDENTIFICATION]: kycData[KycKeys.TAX_PAYER_IDENTIFICATION],
              [KycKeys.US_CITIZEN]: kycData[KycKeys.US_CITIZEN]
            }}
            setKycData={setKycData}
            isUSCitizenByAnyNationality={isUSCitizenByAnyNationality}
            translator={t}
          />
          <UsAddressOne
            data={{
              [KycKeys.US_ADDRESS_1]: kycData[KycKeys.US_ADDRESS_1],
              [KycKeys.US_CITIZEN]: kycData[KycKeys.US_CITIZEN]
            }}
            setKycData={setKycData}
            isUSCitizenByAnyNationality={isUSCitizenByAnyNationality}
            translator={t}
          />
          <UsAddressTwo
            data={{
              [KycKeys.US_ADDRESS_2]: kycData[KycKeys.US_ADDRESS_2],
              [KycKeys.US_CITIZEN]: kycData[KycKeys.US_CITIZEN]
            }}
            setKycData={setKycData}
            isUSCitizenByAnyNationality={isUSCitizenByAnyNationality}
            translator={t}
          />
          <UsAddressThree
            data={{
              [KycKeys.US_ADDRESS_3]: kycData[KycKeys.US_ADDRESS_3],
              [KycKeys.US_CITIZEN]: kycData[KycKeys.US_CITIZEN]
            }}
            isUSCitizenByAnyNationality={isUSCitizenByAnyNationality}
            setKycData={setKycData}
            translator={t}
          />
          <PayTaxesInAnotherCountry
            data={{
              [KycKeys.PAYS_TAXES_IN_OTHER_COUNTRY]: kycData[KycKeys.PAYS_TAXES_IN_OTHER_COUNTRY],
              [KycKeys.US_CITIZEN]: kycData[KycKeys.US_CITIZEN]
            }}
            setKycData={setKycData}
            isUSCitizenByAnyNationality={isUSCitizenByAnyNationality}
            kycFields={kycFieldsOptions}
            translator={t}
          />
          <TaxCountryOne
            data={{
              [KycKeys.TAX_COUNTRY_1]: kycData[KycKeys.TAX_COUNTRY_1],
              [KycKeys.TAX_COUNTRY_2]: kycData[KycKeys.TAX_COUNTRY_2],
              [KycKeys.TAX_COUNTRY_3]: kycData[KycKeys.TAX_COUNTRY_3],
              [KycKeys.US_CITIZEN]: kycData[KycKeys.US_CITIZEN],
              [KycKeys.PAYS_TAXES_IN_OTHER_COUNTRY]: kycData[KycKeys.PAYS_TAXES_IN_OTHER_COUNTRY]
            }}
            setKycData={setKycData}
            isUSCitizenByAnyNationality={isUSCitizenByAnyNationality}
            translator={t}
            countries={countries}
            isKuwaityCitizen={isKuwaityCitizen}
          />
          <TaxPayerIdOne
            data={{
              [KycKeys.TAX_PAYER_ID_1]: kycData[KycKeys.TAX_PAYER_ID_1],
              [KycKeys.PAYS_TAXES_IN_OTHER_COUNTRY]: kycData[KycKeys.PAYS_TAXES_IN_OTHER_COUNTRY],
              [KycKeys.US_CITIZEN]: kycData[KycKeys.US_CITIZEN],
              [KycKeys.TAX_PAYER_IDENTIFICATION]: kycData[KycKeys.TAX_PAYER_IDENTIFICATION]
            }}
            isUSCitizenByAnyNationality={isUSCitizenByAnyNationality}
            isKuwaityCitizen={isKuwaityCitizen}
            customerCivilIdNumber={customerCivilIdNumber}
            setKycData={setKycData}
            translator={t}
          />
          <TaxCountryTwo
            data={{
              [KycKeys.TAX_COUNTRY_1]: kycData[KycKeys.TAX_COUNTRY_1],
              [KycKeys.TAX_COUNTRY_2]: kycData[KycKeys.TAX_COUNTRY_2],
              [KycKeys.TAX_COUNTRY_3]: kycData[KycKeys.TAX_COUNTRY_3],
              [KycKeys.US_CITIZEN]: kycData[KycKeys.US_CITIZEN],
              [KycKeys.PAYS_TAXES_IN_OTHER_COUNTRY]: kycData[KycKeys.PAYS_TAXES_IN_OTHER_COUNTRY]
            }}
            setKycData={setKycData}
            isUSCitizenByAnyNationality={isUSCitizenByAnyNationality}
            translator={t}
            countries={countries}
            isKuwaityCitizen={isKuwaityCitizen}
          />
          <TaxPayerIdTwo
            data={{
              [KycKeys.TAX_COUNTRY_2]: kycData[KycKeys.TAX_COUNTRY_2],
              [KycKeys.TAX_PAYER_ID_2]: kycData[KycKeys.TAX_PAYER_ID_2],
              [KycKeys.US_CITIZEN]: kycData[KycKeys.US_CITIZEN],
              [KycKeys.PAYS_TAXES_IN_OTHER_COUNTRY]: kycData[KycKeys.PAYS_TAXES_IN_OTHER_COUNTRY],
              [KycKeys.TAX_PAYER_IDENTIFICATION]: kycData[KycKeys.TAX_PAYER_IDENTIFICATION]
            }}
            isUSCitizenByAnyNationality={isUSCitizenByAnyNationality}
            isKuwaityCitizen={isKuwaityCitizen}
            setKycData={setKycData}
            translator={t}
          />
          <TaxCountryThree
            data={{
              [KycKeys.TAX_COUNTRY_1]: kycData[KycKeys.TAX_COUNTRY_1],
              [KycKeys.TAX_COUNTRY_2]: kycData[KycKeys.TAX_COUNTRY_2],
              [KycKeys.TAX_COUNTRY_3]: kycData[KycKeys.TAX_COUNTRY_3],
              [KycKeys.US_CITIZEN]: kycData[KycKeys.US_CITIZEN],
              [KycKeys.PAYS_TAXES_IN_OTHER_COUNTRY]: kycData[KycKeys.PAYS_TAXES_IN_OTHER_COUNTRY]
            }}
            isUSCitizenByAnyNationality={isUSCitizenByAnyNationality}
            setKycData={setKycData}
            translator={t}
            countries={countries}
          />
          <TaxPayerIdThree
            data={{
              [KycKeys.TAX_COUNTRY_3]: kycData[KycKeys.TAX_COUNTRY_3],
              [KycKeys.TAX_PAYER_ID_3]: kycData[KycKeys.TAX_PAYER_ID_3],
              [KycKeys.PAYS_TAXES_IN_OTHER_COUNTRY]: kycData[KycKeys.PAYS_TAXES_IN_OTHER_COUNTRY]
            }}
            setKycData={setKycData}
            translator={t}
          />
        </>
      )}
    </MainLayout>
  );
};

export default AdditionalInfoStep;
