import React from 'react';
import { Navigate, Outlet } from 'react-router';
import { otpSuccessRenewalRoute, otpSuccessRoute } from './routes.const';
import { getToken } from '../utils/auth';
import { isWebRenewalEnv } from '../config';

const NoAuthRoute = props => {
  const token = getToken();

  if (token && isWebRenewalEnv) {
    return <Navigate to={otpSuccessRenewalRoute} />;
  } else if (token) {
    return <Navigate to={otpSuccessRoute} />;
  }

  return <Outlet {...props} />;
};

export default NoAuthRoute;
