import { isWebRenewalEnv } from '../config';
import { isInvestEnv } from '../config';

export const landingRoute = '/';
export const onboarding = '/on-boarding';
export const otpVerification = '/otp-verification';
export const otpVerificationRenewal = '/update/otp-verification';
export const productsListing = '/products-list';
export const civildIDetailsRoute = '/civil-id-details';
export const civildIDetailsRenewalRoute = isInvestEnv
  ? '/update'
  : isWebRenewalEnv
    ? '/'
    : '/update/civil-id-details';
export const phoneNumberDetailsRoute = '/phone-number-details';
export const otpSuccessRoute = '/verified-otp';
export const emailAddressDetailsRoute = '/email-address';
export const journeyTimeLineRoute = '/journey-timeline';
export const kycDetailsRoute = '/kyc-details';
export const identityVerificationRoute = '/identity-verification';
export const reviewAndSignRoute = '/review-and-sign';
export const personalInfoStepRoute = '/personal-info-step';
export const employmentInfoStepRoute = '/employment-info-step';
export const contactInfoStepRoute = '/contact-info-step';
export const kycSummaryStepRoute = '/kyc-summary-step';
export const kycRenewalSummaryStepRoute = '/update/kyc-summary-step';
export const additionalInfoStepRoute = '/additional-info-step';
export const incomeAndWealthInfoStepRoute = '/income-wealth-info-step';
export const internationalBrokerageRoute = '/international-brokerage';
export const applicationSubmittedRoute = '/application-submitted';
export const contactSupportRoute = '/contact-support';
export const personalRenewalInfoStepRoute = '/update/personal-info-step';
export const employmentRenewalInfoStepRoute = '/update/employment-info-step';
export const contactRenewalInfoStepRoute = '/update/contact-info-step';
export const additionalRenewalInfoStepRoute = '/update/additional-info-step';
export const incomeAndWealthRenewalInfoStepRoute = '/update/income-wealth-info-step';
export const notRenewalRoute = '/update/onboarding-client';
export const otpSuccessRenewalRoute = '/update/verified-otp';
export const journeyTimeLineRenewalRoute = '/update/journey-timeline';
export const identityVerificationRenewalRoute = '/update/identity-verification';
export const emailAddressDetailsRenewalRoute = '/update/email-address';
export const uploadAdditionalDocumentsRoute = '/update/upload-additional-documents';
export const applicationSubmittedRenewalRoute = '/update/application-submitted';
