import React from 'react';
import { Link } from 'react-router';
import { localEn, localAr } from './localization';

import './style.scss';

const Footer = ({ lang }) => {
  const isArabic = lang === 'ar';
  const local = isArabic ? localAr : localEn;
  return (
    <>
      <div id='footer' className={`${isArabic ? 'rtl' : 'ltr'}`}>
        <div className='footer-flex-row container'>
          <div className='footer-links'>
            <div className='link'>
              <Link to={`/privacy${isArabic ? '?lang=ar' : ''}`} className='link-item'>
                {local.privacy}
              </Link>
            </div>
            <div className='link'>
              <Link to={`/disclosure${isArabic ? '?lang=ar' : ''}`} className='link-item'>
                {local.disclosure}
              </Link>
            </div>
          </div>
          <div className='copyright'>
            <p>{local.copyright}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
