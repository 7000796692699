import React, { useEffect } from "react";
import KYCValidator from "../../../helpers/validation";
import InputField from "../../../widgets/inputs/primary-input";
import KycKeys from "../../../enums/kyc-enum";
import { countriesEnums } from "../../../enums/general";
import PropTypes from "prop-types";

const PassportNumber = ({ data, setKycData, translator }) => {
  const fieldKey = KycKeys.PASSPORT_NUMBER;
  const nationalityKey = KycKeys.NATIONALITY;

  const {
    [fieldKey]: { value, error, isDisabled },
    [nationalityKey]: { value: nationalityValue },
  } = data;

  const isVisible =
    nationalityValue && nationalityValue !== countriesEnums.KUWAIT;

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validatePassportNumber(value);
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isValidated: true,
        error: fieldErrorObject.errorMessage,
        value,
      },
    }));
  };

  const handleChange = (e) => {
    validateField(e.target.value);
  };

  useEffect(() => {
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isVisible: isVisible,
      },
    }));
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <InputField
      name="passportNumber"
      label={translator("passportNumber")}
      onChange={handleChange}
      value={value}
      errorMessage={error}
      isDisabled={isDisabled}
    />
  );
};

PassportNumber.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  translator: PropTypes.func.isRequired,
};

export default PassportNumber;
