import React from "react";
import Header from "../../components/privacy-disclosure-header";
import { disclosureLocalesEn } from "./localization/en";
import Section from "../../components/Section";
import parse from "html-react-parser";

import "./style.scss";

const Disclosure = () => {
  const local = disclosureLocalesEn;
  const displayDescriptions = () => {
    const components = [];
    for (let i = 1; i <= 17; i++) {
      components.push(
        <div key={i} className="disclosure-section">
          <div className="section-text">
            {parse(local[`disclaimersDesc_${i}`])}
          </div>
        </div>
      );
    }
    return components;
  };
  return (
    <>
      <div id="disclosure">
        <Header />
        <div className="banner">
          <div className="container">
            <div className="title">
              <p>{local.disclosureTitle}</p>
            </div>
          </div>
        </div>
        <div className="sections-body">
          <div className="container">
            <Section title={local.introductionTitle}>
              <div className="section-content">
                <div className="section-text">
                  {local.introductionDescription}
                </div>
              </div>
            </Section>
            <Section title={local.investmentRiskTitle}>
              <div className="section-content">
                <div className="section-text">
                  {local.investmentRiskDescription}
                </div>
                <div className="section-text">
                  <span className="section-subtitle">
                    {local.marketRiskTitle}
                  </span>
                  {local.marketDesc}
                </div>
                <div className="section-text">
                  <span className="section-subtitle">
                    {local.advisoryRiskTitle}
                  </span>
                  {local.advisoryDesc}
                </div>
                <div className="section-text">
                  <span className="section-subtitle">
                    {local.volatilityRiskTitle}
                  </span>
                  {local.volatilityDesc}
                </div>
                <div className="section-text">
                  <span className="section-subtitle">
                    {local.liquidityRiskTitle}
                  </span>
                  {local.liquidityDesc}
                </div>
                <div className="section-text">
                  <span className="section-subtitle">
                    {local.creditRiskTitle}
                  </span>
                  {local.creditDesc}
                </div>
                <div className="section-text">
                  <span className="section-subtitle">
                    {local.legislativeRiskTitle}
                  </span>
                  {local.legislativeDesc}
                </div>
                <div className="section-text">
                  <span className="section-subtitle">
                    {local.foreignRiskTitle}
                  </span>
                  {local.foreignDesc}
                </div>
                <div className="section-text">
                  <span className="section-subtitle">{local.etfRiskTitle}</span>
                  {local.etfDesc}
                </div>
              </div>
            </Section>
            <Section title={local.disclaimersTitle}>
              <div className="section-content">
                <div className="section-content">{displayDescriptions()}</div>
              </div>
            </Section>
          </div>
        </div>
      </div>
    </>
  );
};

export default Disclosure;
