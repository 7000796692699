import moment from "moment";
import { bankENums } from "../enums/income-and-wealth";
import KycKeys from "../enums/kyc-enum";
import KYCValidator from "./validation";
import { countriesEnums } from "../enums/general";
import { politicalPositionEnums } from "../enums/additional-info-enums";

const getAreaByCityName = (cities, cityName) => {
  if (!cities || !cityName) {
    return [];
  }

  const foundCity = cities.find((cityObj) => {
    const {
      name: { en: city },
    } = cityObj;
    return city === cityName;
  });

  if (!foundCity) {
    return [];
  }

  return foundCity.areas || [];
};

const filterObjectByKey = (objectTobeFiltered, keyToFilter, filterValue) => {
  const filteredObjects = Object.keys(objectTobeFiltered)
    .filter((key) => objectTobeFiltered[key][keyToFilter] === filterValue)
    .reduce((obj, key) => {
      obj[key] = objectTobeFiltered[key];
      return obj;
    }, {});
  return filteredObjects;
};
export { getAreaByCityName, filterObjectByKey };

export const getCountryByAlpha2 = (countries, alpha2Code) =>
  countries.find((country) => country["alpha-2"] === alpha2Code) || "";

export const createChildrenDetailsList = (childrenNumber, childrentList) => {
  const resultArray = childrentList.slice(0, childrenNumber);

  if (childrenNumber < childrentList.length) {
    resultArray.forEach((child) => {
      child[KycKeys.FIRST_NAME] = "";
      child[KycKeys.MIDDLE_NAME] = "";
      child[KycKeys.FAMILY_NAME] = "";
    });
  }

  for (let i = resultArray.length + 1; i <= childrenNumber; i++) {
    resultArray.push({
      name: `${KycKeys.CHILDREN_NAME}_${i}`,
      [KycKeys.FIRST_NAME]: "",
      [KycKeys.MIDDLE_NAME]: "",
      [KycKeys.FAMILY_NAME]: "",
    });
  }

  return resultArray;
};
export const childrenFullNameDynamicLabel = (counter) =>
  `Child full name (${counter})`;

export function isStepDoneById(array, id) {
  return array.some((task) => task.id === id && task.isDone);
}

export function areAllStepsDone(array) {
  return array.every((step) => step.isDone);
}

export function getIbanValidation(bankName) {
  if (!bankName) {
    return;
  }

  if (bankName === bankENums.NATIONAL_BANK_KUWAIT) {
    return { min: 10, max: 34 };
  } else if (bankName === bankENums.NATIONAL_BANK_KUWAIT_INTERNATIONAL) {
    return { min: 8, max: 34 };
  } else {
    return { min: 5, max: 34 };
  }
}

export const parseMessageWithUrls = (message) => {
  const urlPattern = /(https?:\/\/[^\s]+)/g;
  const parts = message.split(urlPattern);

  return parts.map((part, index) => {
    if (urlPattern.test(part)) {
      return (
        <a
          key={index}
          href={part}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "inherit" }}
        >
          {part}
        </a>
      );
    } else {
      return part;
    }
  });
};

export const extractCustomerName = (fullName = "") => {
  fullName = fullName.trim();
  const nameParts = fullName.split(/\s+/);
  if (nameParts.length === 1) {
    return { firstName: nameParts[0], middleName: "", familyName: "" };
  } else if (nameParts.length === 2) {
    return {
      firstName: nameParts[0],
      familyName: nameParts[1],
      middleName: "",
    };
  } else if (nameParts.length >= 3) {
    const firstName = nameParts[0];
    const familyName = nameParts[nameParts.length - 1];
    const middleName = nameParts.slice(1, -1).join(" ");
    return { firstName, middleName, familyName };
  }
};

export const updateChildrensNamesInfo = (
  data,
  childrenDetailsValue,
  childrenNumber
) => {
  if (!childrenDetailsValue) {
    return;
  }

  if (childrenDetailsValue.length !== childrenNumber) {
    data[KycKeys.CHILDREN_DETAILS].value = createChildrenDetailsList(
      childrenNumber,
      childrenDetailsValue
    );
  }

  childrenDetailsValue.map((children) => {
    const {
      [KycKeys.FIRST_NAME]: childrenFirstName,
      [KycKeys.MIDDLE_NAME]: childrenMiddleName,
      [KycKeys.FAMILY_NAME]: childrenFamilyName,
      value,
    } = children;
    if (!childrenFirstName && !childrenMiddleName && !childrenFamilyName) {
      const { firstName, middleName, familyName } = extractCustomerName(value);
      children[KycKeys.FIRST_NAME] = firstName;
      children[KycKeys.MIDDLE_NAME] = middleName;
      children[KycKeys.FAMILY_NAME] = familyName;
    }
  });
};

export const validateExpiryDates = (kycData, missedFields) => {
  const passportValue = kycData[KycKeys.PASSPORT_EXPIRY_DATE].value;
  const civilIdDateValue = kycData[KycKeys.CIVIL_ID_EXPIRY_DATE].value;
  const nationalityValue = kycData[KycKeys.NATIONALITY].value;

  const formattedPassportDate = moment(passportValue, "YYYY-MM-DD").format(
    "DD/MM/YYYY"
  );
  const formattedCivilIdDate = moment(civilIdDateValue, "YYYY-MM-DD").format(
    "DD/MM/YYYY"
  );

  const passportFieldErrorObject = KYCValidator.validatePassportExpiryDate(
    formattedPassportDate
  );
  const civilIdDateFieldError =
    KYCValidator.validateCivilIdExpiryDate(formattedCivilIdDate);
  if (
    passportFieldErrorObject.isErrorExists &&
    nationalityValue != countriesEnums.KUWAIT &&
    !missedFields.validations.personalInfo.includes(
      KycKeys.PASSPORT_EXPIRY_DATE
    )
  ) {
    missedFields.validations.personalInfo.push(KycKeys.PASSPORT_EXPIRY_DATE);
  }

  if (
    civilIdDateFieldError.isErrorExists &&
    !missedFields.validations.personalInfo.includes(
      KycKeys.CIVIL_ID_EXPIRY_DATE
    )
  ) {
    missedFields.validations.personalInfo.push(KycKeys.CIVIL_ID_EXPIRY_DATE);
  }
};
export const createClientPoliticalPositionList = (
  isPoliticalExisting,
  counter
) => {
  if (!isPoliticalExisting) {
    return [];
  }

  const clientPoliticalPositionList = [];
  for (let i = 0; i < counter; i++) {
    clientPoliticalPositionList.push({
      [politicalPositionEnums.POSITION]: "",
      [politicalPositionEnums.STATUS]: "",
    });
  }
  return clientPoliticalPositionList;
};

export const createRelativePoliticalPositionList = (
  isRelativePoliticalExisting,
  counter
) => {
  if (!isRelativePoliticalExisting) {
    return [];
  }

  const relativePoliticalPositionList = [];
  for (let i = 0; i < counter; i++) {
    relativePoliticalPositionList.push({
      [politicalPositionEnums.FIRST_NAME]: "",
      [politicalPositionEnums.FAMILY_NAME]: "",
      [politicalPositionEnums.RELATIONSHIP]: "",
      [politicalPositionEnums.POSITION]: "",
      [politicalPositionEnums.STATUS]: "",
    });
  }
  return relativePoliticalPositionList;
};

export const validatePepFieldCounts = (kycData, missedFields) => {
  const clientPositionsCount =
    kycData[KycKeys.CLIENT_POLITICAL_POSITIONS].value;
  const relativePoliticalPositions =
    kycData[KycKeys.RELATIVE_POLITICAL_POSITIONS].value;
  const isClientPolitical = kycData[KycKeys.IS_CLIENT_POLITICAL_POSITION].value;
  const isRelativePolitical =
    kycData[KycKeys.IS_RELATIVE_POLITICAL_POSITION].value;

  if (!clientPositionsCount.length && isClientPolitical) {
    missedFields.validations.additionalInfo.push(
      KycKeys.CLIENT_POLITIAL_POSITION_COUNT
    );
  }

  if (!relativePoliticalPositions.length && isRelativePolitical) {
    missedFields.validations.additionalInfo.push(
      KycKeys.RELATIVE_POLITICAL_POSITION_COUNT
    );
  }
};
