import React, { useEffect, useState } from "react";
import KycKeys from "../../../enums/kyc-enum";
import KYCValidator from "../../../helpers/validation";
import { politicalPositionEnums } from "../../../enums/additional-info-enums";
import PropTypes from "prop-types";
import CustomSelect from "../../../widgets/inputs/custom-select";

const PoliticalPositionStatus = ({
  data,
  setKycData,
  kycFields,
  translator,
  isRenewal,
  counterValue,
}) => {
  const clientPoliticalPositions = KycKeys.CLIENT_POLITICAL_POSITIONS;
  const clientPoliticalPositionStatusKey =
    KycKeys.CLIENT_POLITICAL_POSITION_STATUS;
  const {
    index: statusIndex,
    [clientPoliticalPositions]: { value: clientPoliticalPositionsList },
  } = data;

  const { [politicalPositionEnums.STATUS]: value } =
    clientPoliticalPositionsList[statusIndex];

  const { political_position_status: politicalPositionStatus } = kycFields;

  const [errorMessage, setErrorMessage] = useState("");

  const items = Object.entries(politicalPositionStatus)?.map(
    ([key, value]) => ({
      label: value,
      value: key,
    })
  );

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);

    if (fieldErrorObject.errorMessage) {
      setErrorMessage(fieldErrorObject.errorMessage);
    } else {
      setErrorMessage("");
    }

    const updatedList = [...clientPoliticalPositionsList];

    updatedList[statusIndex] = {
      ...updatedList[statusIndex],
      [politicalPositionEnums.STATUS]: value,
    };

    setKycData((prev) => ({
      ...prev,
      [clientPoliticalPositions]: {
        ...prev[clientPoliticalPositions],
        error: "",
        value: updatedList,
      },
    }));
  };

  const handleChange = (value) => {
    validateField(value);
  };

  useEffect(() => {
    if (isRenewal && counterValue == 1) {
      validateField(value);
    } else if (isRenewal && counterValue !== 1) {
      setErrorMessage("");
    }
  }, [counterValue]);

  return (
    <CustomSelect
      name={`${clientPoliticalPositionStatusKey}_${statusIndex}`}
      inputOptions={items}
      label={translator("clientStatus", { counter: statusIndex + 1 })}
      onChange={handleChange}
      value={value}
      errorMessage={errorMessage}
    />
  );
};

PoliticalPositionStatus.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  validationCycle: PropTypes.number.isRequired,
  isSaveForLater: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  kycFields: PropTypes.object.isRequired,
  optionalHandleChange: PropTypes.func,
  isSectionDisabled: PropTypes.bool,
};

export default PoliticalPositionStatus;
