export const disclosureLocalesEn = {
  disclosureTitle: "Disclosure",
  introductionTitle: "Introduction",
  introductionDescription:
    "Watani Investment Company K.S.C.C. is an investment company established in the State of Kuwait in 2005. NBK Invest is a brand registered under the name of Watani Investment Company K.S.C.C. and provides prospective clients with digital access to the investment products, services, and solutions offered by Watani Investment Company K.S.C.C.",
  investmentRiskTitle: "Investment Risk Disclosure",
  investmentRiskDescription:
    "Watani Investment Company K.S.C.C. cannot guarantee any level of performance of the Plan or that the Client will avoid a loss (total or partial) of Account assets. Any investment in securities involves the possibility of financial loss that clients should be prepared to bear. The following risks should be considered carefully by Client before retaining NBK Invest services:",
  marketRiskTitle: "Market Risk: ",
  marketDesc:
    "The price of any security or the value of an entire asset class can decline for reasons beyond the control of Watani Investment Company K.S.C.C. including but not limited to changes in the macroeconomic environment, unpredictable market sentiments forecasted or unforeseen economic developments, interest rate fluctuations, regulatory changes, and domestic or foreign political, demographic or social events.",
  advisoryRiskTitle: "Advisory Risk: ",
  advisoryDesc:
    "There is no guarantee that Watani Investment Company’s K.S.C.C.’s judgment or investment decisions about particular securities or asset class will necessarily produce the intended results. Watani Investment Company’s K.S.C.C.’s judgment in selection may prove to be incorrect. Watani Investment Company K.S.C.C.’ may also make future changes to the investing algorithms and advisory services that it provides. Watani Investment Company K.S.C.C. may rely upon Third Parties / Specialists to advise on the selection of ETFs under each asset class and the asset allocation.",
  volatilityRiskTitle: "Volatility and Correlation Risk: ",
  volatilityDesc:
    "Clients should be aware that Watani Investment Company K.S.C.C. asset selection process is based upon recommendations and research provided by third parties. In any event careful evaluation of past price performance and volatility and trading instructions is undertaken to evaluate future probabilities. It is possible that different or unrelated asset classes may exhibit similar price changes in similar directions which may adversely affect a Client and may become acute in times of market upheaval or high volatility. Past performance is no guarantee of future results and do not reflect actual future performance.",
  liquidityRiskTitle: "Liquidity and Valuation Risk: ",
  liquidityDesc:
    "High volatility and/or lack of adequate active liquid markets for a ETF may prevent a Client from selling his / her ETFs entirely or partially or at a particular time or at a particular price because Watani Investment Company K.S.C.C.  may have difficulty liquidating the same or may be forced to sell at a significant discount to market value. While Watani Investment Company K.S.C.C. shall ensure that the ETFs held in the Client's accounts are based on reasonably available exchange traded security data, Watani Investment Company K.S.C.C. may from time to time receive or use inaccurate data which could adversely affect ETFs valuations, liquidity and the resulting fees paid by the Client to Watani Investment Company K.S.C.C..",
  creditRiskTitle: "Credit Risk: ",
  creditDesc:
    "Clients should be aware that adverse economic consequences may result in impaired credit rating, default, bankruptcy or insolvency any of which may affect portfolio values or its management and that such risk is beyond the control of Watani Investment Company K.S.C.C.. This risk applies to assets held with any broker utilized by Watani Investment Company K.S.C.C. notwithstanding segregation of assets and insurance requirements that would in most cases apply to the benefit of the Client. In addition, exchange trading venues or trade settlement and clearing intermediaries could experience adverse events that may temporarily or permanently limit trading or adversely affect the value of Client’s securities. Finally, any issuer of securities may experience a credit event that could impair or erase the value of the issuer’s securities held by a Client.",
  legislativeRiskTitle: "Legislative and Tax Risk: ",
  legislativeDesc:
    "Changes in government legislation and regulations may have a direct or indirect impact on performance with respect to any securities. Watani Investment Company K.S.C.C. does not engage in financial or tax planning and Client may incur taxes on his / her investments and be required to pay the tax due.",
  foreignRiskTitle: "Foreign Investing and Emerging Markets Risk: ",
  foreignDesc:
    "Foreign investing involves risks not typically associated with local investments and such risks may be intensified further in emerging market countries. These risks may include among others, adverse fluctuations in foreign currency values as well as adverse political and social economic development affecting one or more foreign countries. In addition, foreign investing may involve less publically available information and more volatile or less liquid securities markets, particularly markets that trade a small number of securities, have unstable governments or involve limited industry. Investments in foreign countries may be affected by factors such as restrictions on receiving the investment proceeds form a foreign country, foreign tax laws or tax withholding requirements, unique trade clearance or settlement procedures and potential difficulties in enforcing contractual obligations or other legal rules that jeopardize shareholder protection. Foreign accounting may be less transparent than international accounting practices and foreign regulation may be inadequate or irregular.",
  etfRiskTitle: "ETF Risk including net asset valuations and tracking error: ",
  etfDesc:
    "ETF performance may not exactly match the performance of the index or market benchmark that the ETF is designed to track because 1) the ETF will incur expenses and transaction costs not incurred by any applicable index or market benchmark; 2) certain securities comprising the index or market benchmark tracked by the ETF may, from time to time, temporarily be unavailable; and 3) supply and demand in the market for either the ETF and/or for the securities held by the ETF may cause the ETF shares to trade at a premium or discount to the actual net asset value of the securities owned by the ETF. Certain ETF strategies may from time to time include the purchase of fixed income, commodities, foreign securities, American Depositary Receipts, or other securities for which expenses and commission rates could be higher than normally charged for exchange traded equity securities, and for which market quotations or valuations may be limited or inaccurate.",
  disclaimersTitle: "Disclaimer",
  disclaimersDesc_1:
    'The information, opinions, tools, and materials contained in this website and application (the "Content") are not addressed to, or intended for publication, distribution to, or use by, any individual or legal entity who is a citizen or resident of or domiciled in any jurisdiction where such distribution, publication, availability, or use would constitute a breach of the laws or regulations of such jurisdiction or that would require Watani Investment Company K.S.C.C. or its parent company, its subsidiaries or its affiliates (together "NBK Group") to obtain licenses, approvals, or permissions from the regulatory bodies or authorities of such jurisdiction.',
  disclaimersDesc_2:
    "Watani Investment Company K.S.C.C. is an investment company established in the State of Kuwait in 2005. NBK Invest is a brand registered under the name of Watani Investment Company K.S.C.C. and provides prospective clients with digital access to the investment products, services, and solutions offered by Watani Investment Company K.S.C.C.",
  disclaimersDesc_3:
    "The Content, unless expressly mentioned otherwise, is under copyright to Watani Investment Company K.S.C.C. Neither the Content nor any copy of it may be in any way reproduced, amended, transmitted to, copied, or distributed to any other party without the prior express written consent of Watani Investment Company K.S.C.C. All trademarks, service marks, and logos used in this report are registered trademarks.",
  disclaimersDesc_4:
    "<span className='boldText'>The Content is provided for general circulation and promotional purposes only.</span> No representation or warranty, express or implied, is given by NBK Invest or Watani Investment Company K.S.C.C. or any of its respective directors, partners, officers, affiliates, employees, advisors, or representatives that the investment referred to in this website/application is suitable for you or for any particular investor. Some statements included express opinions that should not be construed as a financial advice.",
  disclaimersDesc_5:
    "If you are in doubt about such investment, we recommend that you consult an independent investment advisor since the investment contained or referred to on this website or application may not be suitable for you and Watani Investment Company K.S.C.C. makes no representation or warranty in this respect.",
  disclaimersDesc_6:
    "The Content shall not be considered investment, legal, accounting, or tax advice or a representation that any investment or strategy is suitable or appropriate for your individual circumstances or otherwise constitutes a personal recommendation to you. Watani Investment Company K.S.C.C. does not offer advice on the tax consequences of investments, and you are advised to contact an independent tax adviser.",
  disclaimersDesc_7:
    "The information and opinions contained on this website/application have been obtained or derived from sources that Watani Investment Company K.S.C.C. believes are reliable without being independently verified as to their accuracy or completeness. Watani Investment Company K.S.C.C. believes the information and opinions expressed on this website/application are accurate and complete; however, Watani Investment Company K.S.C.C. gives no representations or warranty, express or implied, as to the accuracy or completeness of the Content. Additional information may be available upon request.",
  disclaimersDesc_8:
    "Watani Investment Company K.S.C.C. accepts no liability for any direct, indirect, or consequential loss arising from the use of the Content. This website/application is not to be relied upon as a substitution for the exercise of independent judgment. In addition, Watani Investment Company K.S.C.C. may have issued, and may in the future issue, other websites or reports that are inconsistent with and reach different conclusions from the information presented on this website. Those reports reflect the different assumptions, views, and analytical methods of the analysts who prepared the reports, and Watani Investment Company K.S.C.C. is under no obligation to ensure that such other reports are brought to your attention.",
  disclaimersDesc_9:
    "Watani Investment Company K.S.C.C. may be involved in many businesses that relate to companies mentioned on this website/application and may engage with them. Past performance should not be taken as an indication or guarantee of future performance, and no representation or warranty, express or implied, is made regarding future performance. Information, opinions, and estimates contained on this website/application reflect a judgment at the Full Disclosure’s original date of publication by Watani Investment Company K.S.C.C. and are subject to change without notice.",
  disclaimersDesc_10:
    "The value of any investment or income may fall as well as rise, and you may not get back the full amount invested. Where an investment is denominated in a currency other than the local currency of the user of the website/application , changes in the exchange rates may have an adverse effect on the value, price, or income of that investment. In the case of investments for which there is no recognized market, it may be difficult for investors to sell their investments or to obtain reliable information about their value or the extent of the risk to which they are exposed. Investors should consider these risks before investing:",
  disclaimersDesc_11:
    "There is no guarantee that the investment objective will be achieved. ETF prices may fall or fail to rise for several reasons, including and not limited to general financial market conditions, changing market perceptions of the risk of default and issuer-specific events. These factors may also lead to periods of high volatility and reduced liquidity in the ETF markets.",
  disclaimersDesc_12:
    "NBK Group may have a financial interest in one or any of the securities that are the subject of this website/application. Funds managed by NBK Group may own the securities that are the subject of this website/application . NBK Group may own units in one or more of the aforementioned funds.",
  disclaimersDesc_13:
    "NBK Group may be in the process of soliciting or executing fee-earning mandate or doing business for companies that either are the subject of this website/application or are mentioned in this website/application. As a result, you should be aware that NBK Group might have material conflict of interest that could affect the objectivity of the Content in this website/application .",
  disclaimersDesc_14:
    "Watani Investment Company K.S.C.C. undertakes that no necessary information about the investment subject of the Content in this website/application has been omitted. Data is provided by financial exchanges and may be delayed by financial exchanges or our data providers. Watani Investment Company K.S.C.C. does not verify any data and disclaims any obligation to do so.",
  disclaimersDesc_15:
    "Watani Investment Company K.S.C.C., its data or content providers, the financial exchanges and each of their affiliates and business partners (A) expressly disclaim the accuracy, adequacy, or completeness of any data and (B) shall not be liable for any errors, omissions or other defects in, delays or interruptions in such data, or for any actions taken in reliance thereon. Neither Watani Investment Company K.S.C.C. nor any of our information providers will be liable for any damages relating to your use of the information provided herein. As used here, “business partners” does not refer to an agency, partnership, or joint venture relationship between Watani Investment Company K.S.C.C. and any such parties.",
  disclaimersDesc_16:
    "Either Watani Investment Company K.S.C.C. or its third-party data or content providers have exclusive proprietary rights in the data and information provided.",
  disclaimersDesc_17:
    "Unless otherwise specified, all return figures shown are for illustrative purposes only, and are not actual customer or model returns. Actual returns will vary greatly and depend on personal and market circumstances.",
};
