const businessSectorEnums = {
  RETAIL_TRADE: "retail_trade",
  ENERGY_UTILITIES_AND_RESOURCES: "energy_utilities_and_natural_resources",
  BANKING_AND_FINANCE: "banking_and_finance",
  HEALTH_AND_SOCIAL_SERVICES: "health_and_social_services",
  MANUFACTURING_INDUSTRIES: "manufacturing_industries",
  PROFESSIONAL_AND_SPECIALIZED_SERVICES:
    "professional_and_specialized_services",
  REAL_ESTATE_HOSPITALITY_AND_RENTAL_SERVICCES:
    "real_estate_hospitality_and_rental_services",
  INFORMATION_AND_TECHNOLOGY_SERVICES: "information_and_technology_services",
  LOGISTICS_AND_COMMUNICATIONS: "logistics_and_communications",
  PUBLIC_SERVICES: "public_services",
  SPORTS_CULTURE_AND_ENTERTAINMENT: "sports_culture_and_entertainment",
  WHOLESALE_TRADE: "wholesale_trade",
  OFF_SHORE_ORGANIZATIONS_AND_BODIES: "off_shore_organizations_and_bodies",
  OTHER_ASOC_PERS_CULT_AND_TOUR: "other_assoc_pers_cult_and_tour",
};
export default businessSectorEnums;
