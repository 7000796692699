import React from "react";
import styles from "./fields-skeleton.module.scss";
import PropTypes from "prop-types";

const SkeletonLoader = ({ numberOfFields }) => {
  const fieldsArray = Array.from(
    { length: numberOfFields },
    (_, index) => index
  );
  return (
    <div className={styles["container"]} data-testid="section-loading-skeleton">
      {fieldsArray.map((index) => (
        <div
          className={styles["skeleton"]}
          key={index}
          data-testid={"skeleton"}
        >
          <div className={styles["skeleton-text"]}></div>
        </div>
      ))}
    </div>
  );
};

export default SkeletonLoader;
SkeletonLoader.propTypes = {
  numberOfFields: PropTypes.number.isRequired,
};
