import React, { useEffect } from "react";
import PropTypes from "prop-types";
import KycKeys from "../../../enums/kyc-enum";
import RelativeFirstName from "../components/relative-first-name";
import RelativeFamilyName from "../components/relative-family-name";
import RelativeRelationship from "../components/relative-relationship";
import RelativePoliticalPositionRole from "../components/relative-political-position-role";
import RelativePoliticalStatus from "../components/relative-political-status";

const RelativePoliticalPositions = ({
  data,
  setKycData,
  kycFields,
  translator,
  isRenewal,
  counterValue,
}) => {
  const fieldKey = KycKeys.RELATIVE_POLITICAL_POSITIONS;

  const {
    [fieldKey]: { value, isVisible },
  } = data;

  const renderDynamicFields = () =>
    value?.map((option, index) => {
      const counter = index + 1;
      return (
        <React.Fragment key={index}>
          <RelativeFirstName
            data={{
              [fieldKey]: { value, isVisible },
              index,
            }}
            setKycData={setKycData}
            translator={translator}
            isRenewal={isRenewal}
            counterValue={counterValue}
          />
          <RelativeFamilyName
            data={{
              [fieldKey]: { value, isVisible },
              index,
            }}
            setKycData={setKycData}
            translator={translator}
            isRenewal={isRenewal}
            counterValue={counterValue}
          />
          <RelativeRelationship
            data={{
              [fieldKey]: { value, isVisible },
              index,
            }}
            setKycData={setKycData}
            kycFields={kycFields}
            translator={translator}
            isRenewal={isRenewal}
            counterValue={counterValue}
          />
          <RelativePoliticalPositionRole
            data={{
              [fieldKey]: { value, isVisible },
              index,
            }}
            setKycData={setKycData}
            kycFields={kycFields}
            translator={translator}
            isRenewal={isRenewal}
            counterValue={counterValue}
          />
          <RelativePoliticalStatus
            data={{
              [fieldKey]: { value, isVisible },
              index,
            }}
            setKycData={setKycData}
            kycFields={kycFields}
            translator={translator}
            isRenewal={isRenewal}
            counterValue={counterValue}
          />
        </React.Fragment>
      );
    });

  useEffect(() => {
    if (value.length && !isVisible) {
      setKycData((prev) => ({
        ...prev,
        [fieldKey]: {
          ...prev[fieldKey],
          isVisible: true,
        },
      }));
    }
  }, [value]);

  if (!isVisible) {
    return null;
  }

  return renderDynamicFields();
};

RelativePoliticalPositions.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  kycFields: PropTypes.object.isRequired,
};

export default RelativePoliticalPositions;
