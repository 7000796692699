import { locale } from './localiztion';
import { brokerageEums, internationalBrokerageTypesEnums } from './enums/general';
import { languageEnum } from './enums/language-enum';

export const apiUrl = process.env.REACT_APP_API_URL;
export const languageOptions = [
  { value: languageEnum.english, label: 'EN', optionLabel: 'English (EN)' },
  { value: languageEnum.arabic, label: 'ع', optionLabel: 'عربي (ع)' }
];

export const brokerageOptions = [
  { value: brokerageEums.REGIONAL_BROKERAGE_RBU, label: 'regionalMarkets' },
  {
    value: brokerageEums.INTERNATIONAL_BROKERAGE,
    label: 'internationalMarkets'
  }
];

export const alertMessagesMapping = {
  [locale.issueEncountered]: 'issueEncountered',
  [locale.kycExpired]: 'cidOrKycExpired',
  [locale.alreadyOnboarded]: 'alreadyOnboarded',
  [locale.invalidOTP]: 'invalidOTP',
  [locale.expiredOTP]: 'expiredOTP',
  [locale.maxAttempts]: 'maxAttempts',
  [locale.maxAttemptsArabic]: 'maxAttempts',
  [locale.tooManyRequestMessage]: 'maxAttempts',
  [locale.validSerialNumber]: 'enterValidSerialNumber',
  [locale.issueInCivilId]: 'issueEncountered',
  [locale.somethingWentWrongMessage]: 'somethingWentWrong',
  [locale.issueInCivilId2]: 'contactBank',
  [locale.alreadyOnboardedNonPB]: 'alreadyOnboardedNonPB'
};

export const internationalTypeOptions = [
  { value: internationalBrokerageTypesEnums.CASH, label: 'cashAccounts' },
  {
    value: internationalBrokerageTypesEnums.MARGIN,
    label: 'marginAccounts'
  }
];

export const availableMediumsEnum = {
  SMS: 'SMS',
  EMAIL: 'EMAIL'
};

export const acceptedFileTypes = 'image/png, image/jpg, image/jpeg, application/pdf';

export const allowedFileTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];

export const acceptedCivilIdFileTypes = 'image/png, image/jpg, image/jpeg';

export const allowedCivilIdFileTypes = ['image/png', 'image/jpg', 'image/jpeg'];

export const customerRiskEnums = {
  LOW: 'low',
  HIGH: 'high'
};

export const returnReasons = {
  KYC_AND_DOCUMENTS: 'kyc_and_documents',
  SUPPORTING_DOCUMENTS: 'supporting_documents'
};

const webRenewalEnvironments = [
  'web-renewal',
  'web-renewal-invest',
  'web-renewal-invest-uat',
  'web-renewal-dev',
  'web-renewal-uat',
  'dev-web-renewal-uat'
];

const renewalInvestEnvironments = ['web-renewal-invest', 'web-renewal-invest-uat'];

export const isInvestEnv = renewalInvestEnvironments.includes(process.env.REACT_APP_ENV);
export const isWebRenewalEnv = webRenewalEnvironments.includes(process.env.REACT_APP_ENV);
export const isDevEnv = process.env.REACT_APP_ENV?.includes('dev');
export const isUATEnv = process.env.REACT_APP_ENV?.includes('uat');
export const getBaseURL = isInvest => {
  const urls = {
    true: {
      true: 'https://renewal-invest.nbkcapital-smartwealth.com',
      false: 'https://invest.nbk.com'
    },
    false: {
      true: 'https://renewal.nbkcapital-smartwealth.com',
      false: 'https://update.nbkwealth.com'
    }
  };

  return urls[isInvest][isUATEnv];
};
