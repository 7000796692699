import axiosInstance from './network/axios-instance';
import {
  PostCustomerUrl,
  PostCustomerOrderUrl,
  GetCustomerUrl,
  GetCustomerOrderUrl,
  GetCountriesAndCitiesUrl,
  GetCustomerOrderKycUrl,
  GetKycFieldsUrl,
  PutCustomerOrderKycUrl,
  PutCutomerOrderBrokerage,
  PutEmailAndLanguage,
  AuthorizeCustomer,
  GetCustomerOrderValidationUrl,
  GetCustomerOrderKycUrlV2,
  PutCustomerOrderKycUrlV2,
  SendAndResendOtpUrl,
  GetCustomerStatusUrl,
  GetCustomerRiskUrl,
  PostCustomerRiskUrl,
  SubmissionToAdminUrl,
  GetAllCustomerDocuments,
  DeleteDocument,
  GenerateClientDocuments,
  PutCustomerOrderKycUrlV3,
  GetCustomerOrderKycUrlV3,
  GetCustomerOrderValidationUrlV3
} from './general-urls.const';
import {
  deserializeKycData,
  serializeCountriesAndCitiesResponse,
  serializeKycData,
  serializeCustomerResponse,
  serializeCustomerOrderResponse,
  serializePostCustomerResponse,
  serializedKycValidationResponse,
  serializeKycDataV2,
  serializeCustomerStatusResponse,
  serializeGetCustomerRiskResponse,
  serializeGetDocumentsResponse
} from './serializer';
import { getToken } from './utils/auth';

export const postCustomer = async payload => {
  try {
    const response = await axiosInstance.post(PostCustomerUrl, payload);
    return serializePostCustomerResponse(response.data) || {};
  } catch (exception) {
    throw new Error('posting customer failed');
  }
};

export const postCustomerOrder = async payload => {
  try {
    const response = await axiosInstance.post(PostCustomerOrderUrl, payload);
    return response.data || {};
  } catch (exception) {
    throw new Error('posting customer order failed!');
  }
};

export const getCustomer = async () => {
  try {
    const response = await axiosInstance.get(GetCustomerUrl);
    return serializeCustomerResponse(response.data) || {};
  } catch (exception) {
    throw exception;
  }
};

export const getCustomerOrder = async orderId => {
  try {
    const response = await axiosInstance.get(GetCustomerOrderUrl(orderId));
    return serializeCustomerOrderResponse(response.data) || {};
  } catch (exception) {
    throw new Error('Fetching customer order data failed!');
  }
};

export const getCustomerOrderValidation = async (orderId, orderKycId) => {
  try {
    const response = await axiosInstance.get(GetCustomerOrderValidationUrl(orderId, orderKycId));
    return serializedKycValidationResponse(response.data) || {};
  } catch (exception) {
    throw new Error('Fetching customer order data failed!');
  }
};

export const getCustomerOrderValidationV3 = async (orderId, orderKycId) => {
  try {
    const response = await axiosInstance.get(GetCustomerOrderValidationUrlV3(orderId, orderKycId));
    return serializedKycValidationResponse(response.data) || {};
  } catch (exception) {
    throw new Error('Fetching customer order data failed!');
  }
};

export const putCustomerOrder = async (orderId, isDocumentsSubmitted = false) => {
  try {
    const response = await axiosInstance.put(GetCustomerOrderUrl(orderId), {
      is_document_submitted: isDocumentsSubmitted
    });
    return response.data || {};
  } catch (exception) {
    throw new Error('failed updating order!');
  }
};

export const getCountriesAndCities = async () => {
  try {
    const response = await axiosInstance.get(GetCountriesAndCitiesUrl);
    return (
      serializeCountriesAndCitiesResponse(response.data) || {
        cities: [],
        countriesAr: [],
        countriesEn: []
      }
    );
  } catch (exception) {
    throw new Error('Fetching countries and cities data failed!');
  }
};

export const getKycFields = async () => {
  try {
    const response = await axiosInstance.get(GetKycFieldsUrl);
    return response.data || {};
  } catch (exception) {
    throw new Error('Fetching kyc fields data failed!');
  }
};

export const putKycData = async (orderId, payload, category) => {
  try {
    const deserialzedData = deserializeKycData(payload, category);
    const response = await axiosInstance.put(PutCustomerOrderKycUrl(orderId), deserialzedData);

    return response || {};
  } catch (exception) {
    throw new Error('Updating kyc data failed!');
  }
};

export const putKycDataV3 = async (orderId, payload, category) => {
  try {
    const deserialzedData = deserializeKycData(payload, category);
    const response = await axiosInstance.put(PutCustomerOrderKycUrlV3(orderId), deserialzedData);

    return response || {};
  } catch (exception) {
    throw exception;
  }
};

export const getKycData = async (orderId, kycOrderId) => {
  try {
    const response = await axiosInstance.get(GetCustomerOrderKycUrl(orderId, kycOrderId));
    const serializedData = serializeKycData(response.data);
    return serializedData || {};
  } catch (exception) {
    throw new Error('Fetching kyc data failed!');
  }
};

export const getKycDataV3 = async (orderId, kycOrderId) => {
  try {
    const response = await axiosInstance.get(GetCustomerOrderKycUrlV3(orderId, kycOrderId));
    const serializedData = serializeKycData(response.data);
    return serializedData || {};
  } catch (exception) {
    throw new Error('Fetching kyc data failed!');
  }
};

export const putCustomerOrderBrokerage = async (
  orderId,
  brokerageId,
  updateCustomerOrderPayload
) => {
  try {
    const response = await axiosInstance.put(
      PutCutomerOrderBrokerage(orderId, brokerageId),
      updateCustomerOrderPayload
    );
    return response.data || {};
  } catch (exception) {
    throw new Error('Updating customer order brokerage failed!');
  }
};

export const putEmail = async email => {
  try {
    const response = await axiosInstance.put(PutEmailAndLanguage, {
      email: email
    });
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (exception) {
    throw new Error('Updating kyc data failed!');
  }
};

export const putLanguage = async language => {
  try {
    const response = await axiosInstance.put(PutEmailAndLanguage, {
      language: language
    });
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (exception) {
    throw new Error('Updating kyc data failed!');
  }
};

export const putCivilSerialNumberAndLanguage = async (serialNumber, language) => {
  try {
    const response = await axiosInstance.put(PutEmailAndLanguage, {
      civil_id_serial: serialNumber,
      language: language
    });
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (exception) {
    throw new Error('Updating serial and language failed!');
  }
};

export const authorizeCustomer = async civilId => {
  try {
    const response = await axiosInstance.post(AuthorizeCustomer, {
      civil_id_number: civilId,
      access_token: getToken()
    });
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (exception) {
    throw new Error('Authorize client failed!');
  }
};

export const sendAndResendOTP = async payload => {
  try {
    const response = await axiosInstance.post(SendAndResendOtpUrl, payload);
    return response.data || {};
  } catch (exception) {
    throw new Error('Sending Otp failed!');
  }
};

export const getKycDataV2 = async (orderId, kycOrderId) => {
  try {
    const response = await axiosInstance.get(GetCustomerOrderKycUrlV2(orderId, kycOrderId));
    const serializedData = serializeKycDataV2(response.data);
    return serializedData || {};
  } catch (exception) {
    throw new Error('Fetching kyc data failed!');
  }
};

export const getKycDataNewVersion2 = async (orderId, kycOrderId) => {
  try {
    const response = await axiosInstance.get(GetCustomerOrderKycUrlV3(orderId, kycOrderId));
    const serializedData = serializeKycDataV2(response.data);
    return serializedData || {};
  } catch (exception) {
    throw new Error('Fetching kyc data failed!');
  }
};

export const putKycDataV2 = async (orderId, payload, category) => {
  try {
    const deserialzedData = deserializeKycData(payload, category);
    const response = await axiosInstance.put(PutCustomerOrderKycUrlV2(orderId), deserialzedData);

    return response || {};
  } catch (exception) {
    throw new Error('Updating kyc data failed!');
  }
};

export const getCustomerStatus = async () => {
  try {
    const response = await axiosInstance.get(GetCustomerStatusUrl);
    return serializeCustomerStatusResponse(response.data) || {};
  } catch (exception) {
    throw new Error('Fetching customer status failed!');
  }
};

export const getCustomerRisk = async orderKycId => {
  try {
    const response = await axiosInstance.get(GetCustomerRiskUrl(orderKycId));
    return serializeGetCustomerRiskResponse(response.data) || {};
  } catch (exception) {
    throw new Error('Fetching risk data failed!');
  }
};

export const postCustomerRisk = async orderKycId => {
  try {
    const response = await axiosInstance.post(PostCustomerRiskUrl(orderKycId));
    return response.data;
  } catch (exception) {
    throw new Error('posting risk data failed!');
  }
};

export const submitToAdmin = async orderId => {
  try {
    const response = await axiosInstance.post(SubmissionToAdminUrl(orderId));
    return response.data || {};
  } catch (exception) {
    throw new Error('Submission failed!');
  }
};

export const getAllCustomerDocuments = async orderId => {
  try {
    const response = await axiosInstance.get(GetAllCustomerDocuments(orderId));
    return serializeGetDocumentsResponse(response.data) || {};
  } catch (exception) {
    throw new Error('Fetch Document Failed');
  }
};

export const deleteDocument = async (orderId, documentId) => {
  try {
    const response = await axiosInstance.delete(DeleteDocument(orderId, documentId));
    return response.data || {};
  } catch (exception) {
    throw new Error('Delete document failed!');
  }
};

export const generateClientDocuments = async orderId => {
  try {
    const response = await axiosInstance.post(GenerateClientDocuments(orderId));
    return response.data || {};
  } catch (exception) {
    throw new Error('Document Generation failed');
  }
};
