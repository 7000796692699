import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import KycKeys from "../../../enums/kyc-enum";
import { politicalPositionEnums } from "../../../enums/additional-info-enums";
import KYCValidator from "../../../helpers/validation";
import { capitalizeWords } from "../../../utils";
import InputField from "../../../widgets/inputs/primary-input";

const RelativeFamilyName = ({
  data,
  translator,
  setKycData,
  isRenewal,
  counterValue,
}) => {
  const relativePoliticalPositionsKey = KycKeys.RELATIVE_POLITICAL_POSITIONS;
  const relativeFamilyNameKey = KycKeys.RELATIVE_FAMILY_NAME_ROLE;
  const {
    index: familyNameIndex,
    [relativePoliticalPositionsKey]: { value: relativePoliticalPositionsList },
  } = data;

  const { [politicalPositionEnums.FAMILY_NAME]: value } =
    relativePoliticalPositionsList[familyNameIndex];

  const [errorMessage, setErrorMessage] = useState("");

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateNames(value);

    if (fieldErrorObject.errorMessage) {
      setErrorMessage(fieldErrorObject.errorMessage);
    } else {
      setErrorMessage("");
    }

    const updatedList = [...relativePoliticalPositionsList];

    updatedList[familyNameIndex] = {
      ...updatedList[familyNameIndex],
      [politicalPositionEnums.FAMILY_NAME]: value,
    };

    setKycData((prev) => ({
      ...prev,
      [relativePoliticalPositionsKey]: {
        ...prev[relativePoliticalPositionsKey],
        error: "",
        value: updatedList,
      },
    }));
  };

  const handleBlur = ({ target: { value } }) => {
    const updatedList = [...relativePoliticalPositionsList];

    updatedList[familyNameIndex] = {
      ...updatedList[familyNameIndex],
      [politicalPositionEnums.FAMILY_NAME]: capitalizeWords(value),
    };

    setKycData((prev) => ({
      ...prev,
      [relativePoliticalPositionsKey]: {
        ...prev[relativePoliticalPositionsKey],
        error: "",
        value: updatedList,
      },
    }));
  };

  const handleChange = (e) => {
    validateField(e.target.value);
  };

  useEffect(() => {
    if (isRenewal && counterValue == 1) {
      validateField(value);
    } else if (isRenewal && counterValue !== 1) {
      setErrorMessage("");
    }
  }, [counterValue]);
  return (
    <InputField
      name={`${relativeFamilyNameKey}_${familyNameIndex}`}
      label={translator("relativeFamilyName", { counter: familyNameIndex + 1 })}
      onChange={handleChange}
      value={value}
      errorMessage={errorMessage}
      onBlur={handleBlur}
    />
  );
};

RelativeFamilyName.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default RelativeFamilyName;
