import React from "react";
import Icons from "../../../../assets/icons";
import Images from "../../../../assets/images";
import { LandingLocalEn } from "../../localization/en";
import "./style.scss";

const MobileLanding = () => {
  const translations = LandingLocalEn;
  const translate = (key) => translations[key] || key;
  return (
    <>
      <div className={`landing-flex mobile`}>
        <div className="device-section">
          <div className="logo">
            <img src={Icons.Logo} alt="logo" />
          </div>
          <div className="devices-image">
            <img src={Images.Phones} alt="devices" />
          </div>
        </div>
        <div className="landing-info">
          <div className="title">
            <p>{translate("title")}</p>
          </div>
          <div className="list">
            <ol>
              <li>{translate("point1")}</li>
              <li>{translate("point2")}</li>
              <li>{translate("point3")}</li>
            </ol>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileLanding;
