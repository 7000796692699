import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getKycDataV2,
  putKycDataV2,
  getCustomer,
  postCustomerRisk,
  getKycDataNewVersion2,
  putKycDataV3,
  getCustomerOrderValidationV3
} from '../../../general-services.proxy';
import EmploymentStatus from '../components/employment-status';
import LastEmploymentStatus from '../components/last-employment-status';
import EmployerType from '../components/employer-type';
import BusinessSector from '../components/business-sector';
import EntityType from '../components/entity-type';
import BusinessType from '../components/business-type';
import businessSectorEnums from '../../../enums/business-sector';
import entityTypeEnums from '../../../enums/entity-type';
import PrivateBusinessName from '../components/private-business-name';
import OrganizationName from '../components/organization-name';
import Department from '../components/department';
import SeniorExecutiveOrOwner from '../components/is-senior-executive-or-owner';
import SeniorLeadershipPosition from '../components/is-senior-leadership-position';
import BoradMemberOrInsider from '../components/is-board-member-or-insider';
import CompanyNameTwo from '../components/company-name-two';
import CompanyNameThree from '../components/company-name-three';
import CompanyNameOne from '../components/company-name-one';
import KycKeys from '../../../enums/kyc-enum';
import kycFields from '../../../config/kyc';
import StepEnums from '../../../enums/step-enum';
import EntityName from '../components/entity-name';
import JobTitle from '../components/job-title';
import SkeletonLoader from '../../../widgets/fields-skeleton';
import {
  incomeAndWealthRenewalInfoStepRoute,
  kycRenewalSummaryStepRoute
} from '../../../routes/routes.const';
import { useNavigate } from 'react-router';
import MainLayout from '../../../layouts/main-layout';
import { scrollToTop } from '../../../utils';
import { useTranslation } from 'react-i18next';
import { calculateKycProgress } from '../../../helpers';
import KycStepFooter from '../../../components/kyc-step-footer';
import { commonValidations } from '../../../utils/validation/validation-messages';
import { getCustomerOrderValidation } from '../../../general-services.proxy';
import { newPepFieldsFeatureFlag } from '../../../config/feature-flags';

const EmploymentRenewalInfoStep = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const [kycData, setKycData] = useState(kycFields);
  const stepName = StepEnums.EMPLOYMENT_INFO_STEP;
  const [isLoading, setLoading] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [orderKycId, setOrderKycId] = useState('');
  const [kycProgress, setKycProgress] = useState(0);

  const isContinueDisabled = Object.keys(kycData)
    .filter(key => kycData[key].step === stepName && kycData[key].isVisible)
    .some(
      key =>
        (!kycData[key].isOptional && !kycData[key].value && kycData[key].value !== false) ||
        kycData[key].error
    );

  const { kycFields: kycFieldsOptions } = useSelector(state => state.general);
  const {
    employment_status: employmentStatus = {},
    employer_type: employerType = {},
    private_sector_industries_v2: privateSectorIndustry = {},
    government_entity_type: governmentEntityType = {},
    last_employment_status: lastEmploymentStatus = {},
    retail_trade: retailTradeSectors = {},
    energy_utilities_and_natural_resources: energyUtilitySectors = {},
    banking_and_finance: bankingAndFinanceSectors = {},
    health_and_social_services: healthServicesSectors = {},
    manufacturing_industries: manufacturingIndustriesSectors = {},
    professional_and_specialized_services: professionalAndSpecializedSectors = {},
    real_estate_hospitality_and_rental_services: realEstateSectors = {},
    information_and_technology_services: informationAndTechnologySectors = {},
    logistics_and_communications: logisticsSectors = {},
    public_services: publicSectors = {},
    sports_culture_and_entertainment: sportsCultureSectors = {},
    wholesale_trade: wholeSaleTradeSectors = {},
    off_shore_organizations_and_bodies: offShoreOrganizationSectors = {},
    other_assoc_pers_cult_and_tour: otherAssocSectors = {},
    ministry_entities: ministryEntries = {},
    state_organization_entities: stateOrganizationEntries = {},
    independant_government_entities: independentGovernmentEntries = {},
    yes_no: yesOrNo = {}
  } = kycFieldsOptions;

  const businessTypeMapping = {
    [businessSectorEnums.RETAIL_TRADE]: retailTradeSectors,
    [businessSectorEnums.ENERGY_UTILITIES_AND_RESOURCES]: energyUtilitySectors,
    [businessSectorEnums.BANKING_AND_FINANCE]: bankingAndFinanceSectors,
    [businessSectorEnums.HEALTH_AND_SOCIAL_SERVICES]: healthServicesSectors,
    [businessSectorEnums.MANUFACTURING_INDUSTRIES]: manufacturingIndustriesSectors,
    [businessSectorEnums.PROFESSIONAL_AND_SPECIALIZED_SERVICES]: professionalAndSpecializedSectors,
    [businessSectorEnums.REAL_ESTATE_HOSPITALITY_AND_RENTAL_SERVICCES]: realEstateSectors,
    [businessSectorEnums.INFORMATION_AND_TECHNOLOGY_SERVICES]: informationAndTechnologySectors,
    [businessSectorEnums.LOGISTICS_AND_COMMUNICATIONS]: logisticsSectors,
    [businessSectorEnums.PUBLIC_SERVICES]: publicSectors,
    [businessSectorEnums.SPORTS_CULTURE_AND_ENTERTAINMENT]: sportsCultureSectors,
    [businessSectorEnums.WHOLESALE_TRADE]: wholeSaleTradeSectors,
    [businessSectorEnums.OFF_SHORE_ORGANIZATIONS_AND_BODIES]: offShoreOrganizationSectors,
    [businessSectorEnums.OTHER_ASOC_PERS_CULT_AND_TOUR]: otherAssocSectors
  };

  const entityNameMapping = {
    [entityTypeEnums.MINISTRY_SIMILAR]: ministryEntries,
    [entityTypeEnums.STATE_ORGANIZATION_AUTHORITY]: stateOrganizationEntries,
    [entityTypeEnums.INDEPENDENT_GOVERNMENT_ENTITY]: independentGovernmentEntries,
    [entityTypeEnums.STATE_OWNED_COMPANY]: {},
    [entityTypeEnums.OTHER]: {}
  };

  const businessTypes = kycData[KycKeys.PRIVATE_SECTOR_INDUSTRY].value
    ? businessTypeMapping[kycData[KycKeys.PRIVATE_SECTOR_INDUSTRY].value] || {}
    : {};

  const entityNames = kycData[KycKeys.GOVERNMENT_ENTITY_TYPE].value
    ? entityNameMapping[kycData[KycKeys.GOVERNMENT_ENTITY_TYPE].value]
    : {};

  const handleNavigation = () => {
    navigate({
      pathname: incomeAndWealthRenewalInfoStepRoute
    });
  };

  const runRiskEngine = async () => {
    try {
      setLoading(true);
      await postCustomerRisk(orderKycId);
    } catch (exception) {
      console.error(exception);
    } finally {
      setLoading(false);
    }
  };

  const fetchKycData = async () => {
    try {
      setLoading(true);
      const { orderId, orderKycId } = await getCustomer();
      const missedFields = await (newPepFieldsFeatureFlag
        ? getCustomerOrderValidationV3(orderId, orderKycId)
        : getCustomerOrderValidation(orderId, orderKycId));
      setOrderKycId(orderKycId);
      setOrderId(orderId);
      const employmentInfoMissedFields = missedFields.validations.employment;
      const { serializedData: data } = await (newPepFieldsFeatureFlag
        ? getKycDataNewVersion2(orderId, orderKycId)
        : getKycDataV2(orderId, orderKycId));

      const dataClone = structuredClone(data);
      employmentInfoMissedFields.forEach(field => {
        if (dataClone[field]) {
          dataClone[field].error = t(commonValidations.required);
        }
      });
      if (dataClone[KycKeys.BOARD_MEMBERSHIP_EXISTING]?.value) {
        dataClone[KycKeys.BOARD_MEMBERSHIP_COMPANY_NAME_1].isVisible = true;
      }
      setKycData(dataClone);
    } catch (exception) {
      console.error(exception);
    } finally {
      setLoading(false);
      scrollToTop();
    }
  };

  useEffect(() => {
    const progress = calculateKycProgress(kycData);
    setKycProgress(progress);
  }, [kycData]);

  const saveKycData = async () => {
    try {
      setLoading(true);
      await (newPepFieldsFeatureFlag
        ? putKycDataV3(orderId, kycData, StepEnums.EMPLOYMENT_INFO_STEP)
        : putKycDataV2(orderId, kycData, StepEnums.EMPLOYMENT_INFO_STEP));

      await runRiskEngine();
      handleNavigation();
    } catch (exception) {
      console.error(exception);
    } finally {
      setLoading(false);
    }
  };

  const handleNextActionClick = () => {
    saveKycData();
  };

  const handleNextActionClickToSummary = () => {
    saveKycDataAndNavigate();
  };
  const saveKycDataAndNavigate = async () => {
    try {
      setLoading(true);
      await (newPepFieldsFeatureFlag
        ? putKycDataV3(orderId, kycData, StepEnums.EMPLOYMENT_INFO_STEP)
        : putKycDataV2(orderId, kycData, StepEnums.EMPLOYMENT_INFO_STEP));

      await runRiskEngine();
      navigate({
        pathname: kycRenewalSummaryStepRoute
      });
    } catch (exception) {
      console.error(exception);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchKycData();
    return () => {
      setKycData(kycFields);
    };
  }, []);

  const renderFooter = () => (
    <KycStepFooter
      firstButtonTitle={t('continueEditing')}
      firstButtonAction={handleNextActionClick}
      secondButtonTitle={t('saveAndContinueToSummary')}
      secondButtonAction={handleNextActionClickToSummary}
      firstButtonDisabled={isContinueDisabled || isLoading}
      secondButtonDisabled={isContinueDisabled || isLoading}
    />
  );

  return (
    <MainLayout
      stepTitle={t('EmploymentInfoStepTitle')}
      footer={renderFooter}
      stepNumber={kycProgress}
      isShowStepNumber={false}
      isKycRenewal
    >
      {isLoading ? (
        <SkeletonLoader numberOfFields={8} />
      ) : (
        <>
          <EmploymentStatus
            data={{
              [KycKeys.EMPLOYMENT_STATUS]: kycData[KycKeys.EMPLOYMENT_STATUS]
            }}
            setKycData={setKycData}
            kycFields={{
              employmentStatuses: employmentStatus
            }}
            translator={t}
          />
          <LastEmploymentStatus
            data={{
              [KycKeys.LAST_EMPLOYMENT_STATUS]: kycData[KycKeys.LAST_EMPLOYMENT_STATUS],
              [KycKeys.EMPLOYMENT_STATUS]: kycData[KycKeys.EMPLOYMENT_STATUS]
            }}
            setKycData={setKycData}
            kycFields={{
              lastEmploymentStatuses: lastEmploymentStatus
            }}
            translator={t}
          />
          <EmployerType
            data={{
              [KycKeys.EMPLOYER_TYPE]: kycData[KycKeys.EMPLOYER_TYPE],
              [KycKeys.LAST_EMPLOYMENT_STATUS]: kycData[KycKeys.LAST_EMPLOYMENT_STATUS],
              [KycKeys.EMPLOYMENT_STATUS]: kycData[KycKeys.EMPLOYMENT_STATUS]
            }}
            setKycData={setKycData}
            kycFields={{
              employerTypes: employerType
            }}
            translator={t}
          />
          <BusinessSector
            data={{
              [KycKeys.PRIVATE_SECTOR_INDUSTRY]: kycData[KycKeys.PRIVATE_SECTOR_INDUSTRY],
              [KycKeys.EMPLOYER_TYPE]: kycData[KycKeys.EMPLOYER_TYPE]
            }}
            setKycData={setKycData}
            kycFields={{
              sectors: privateSectorIndustry
            }}
            translator={t}
          />
          <EntityType
            data={{
              [KycKeys.GOVERNMENT_ENTITY_TYPE]: kycData[KycKeys.GOVERNMENT_ENTITY_TYPE],
              [KycKeys.EMPLOYER_TYPE]: kycData[KycKeys.EMPLOYER_TYPE]
            }}
            setKycData={setKycData}
            kycFields={{
              entityTypes: governmentEntityType
            }}
            translator={t}
          />
          <BusinessType
            data={{
              [KycKeys.EMPLOYMENT_SECTOR]: kycData[KycKeys.EMPLOYMENT_SECTOR],
              [KycKeys.EMPLOYER_TYPE]: kycData[KycKeys.EMPLOYER_TYPE]
            }}
            setKycData={setKycData}
            kycFields={{
              businessTypes: businessTypes
            }}
            translator={t}
          />
          <PrivateBusinessName
            data={{
              [KycKeys.EMPLOYMENT_COMPANY]: kycData[KycKeys.EMPLOYMENT_COMPANY],
              [KycKeys.EMPLOYER_TYPE]: kycData[KycKeys.EMPLOYER_TYPE],
              [KycKeys.LAST_EMPLOYMENT_STATUS]: kycData[KycKeys.LAST_EMPLOYMENT_STATUS],
              [KycKeys.EMPLOYMENT_STATUS]: kycData[KycKeys.EMPLOYMENT_STATUS]
            }}
            setKycData={setKycData}
            translator={t}
          />
          <EntityName
            data={{
              [KycKeys.EMPLOYMENT_ENTITY_NAME]: kycData[KycKeys.EMPLOYMENT_ENTITY_NAME],
              [KycKeys.GOVERNMENT_ENTITY_TYPE]: kycData[KycKeys.GOVERNMENT_ENTITY_TYPE],
              [KycKeys.EMPLOYER_TYPE]: kycData[KycKeys.EMPLOYER_TYPE]
            }}
            setKycData={setKycData}
            kycFields={{
              entityNames: entityNames
            }}
            translator={t}
          />
          <OrganizationName
            data={{
              [KycKeys.EMPLOYMENT_ORGANIZATION_NAME]: kycData[KycKeys.EMPLOYMENT_ORGANIZATION_NAME],
              [KycKeys.EMPLOYER_TYPE]: kycData[KycKeys.EMPLOYER_TYPE]
            }}
            setKycData={setKycData}
            translator={t}
          />
          <Department
            data={{
              [KycKeys.EMPLOYMENT_DEPARTMENT]: kycData[KycKeys.EMPLOYMENT_DEPARTMENT],
              [KycKeys.EMPLOYER_TYPE]: kycData[KycKeys.EMPLOYER_TYPE]
            }}
            setKycData={setKycData}
            translator={t}
          />
          <JobTitle
            data={{
              [KycKeys.JOB_TITLE]: kycData[KycKeys.JOB_TITLE],
              [KycKeys.EMPLOYER_TYPE]: kycData[KycKeys.EMPLOYER_TYPE]
            }}
            setKycData={setKycData}
            translator={t}
          />
          <SeniorExecutiveOrOwner
            data={{
              [KycKeys.AUTH_SIGN_PROCESS_FINANCIAL_TRANSACTIONS]:
                kycData[KycKeys.AUTH_SIGN_PROCESS_FINANCIAL_TRANSACTIONS],
              [KycKeys.EMPLOYER_TYPE]: kycData[KycKeys.EMPLOYER_TYPE]
            }}
            setKycData={setKycData}
            kycFields={{
              yesOrNo: yesOrNo
            }}
            translator={t}
          />
          <SeniorLeadershipPosition
            data={{
              [KycKeys.AUTH_SIGN_PROCESS_FINANCIAL_TRANSACTIONS]:
                kycData[KycKeys.AUTH_SIGN_PROCESS_FINANCIAL_TRANSACTIONS],
              [KycKeys.EMPLOYER_TYPE]: kycData[KycKeys.EMPLOYER_TYPE]
            }}
            setKycData={setKycData}
            kycFields={{
              yesOrNo: yesOrNo
            }}
            translator={t}
          />
          <BoradMemberOrInsider
            data={{
              [KycKeys.BOARD_MEMBERSHIP_EXISTING]: kycData[KycKeys.BOARD_MEMBERSHIP_EXISTING]
            }}
            setKycData={setKycData}
            kycFields={{
              yesOrNo: yesOrNo
            }}
            translator={t}
          />
          <CompanyNameOne
            data={{
              [KycKeys.BOARD_MEMBERSHIP_COMPANY_NAME_1]:
                kycData[KycKeys.BOARD_MEMBERSHIP_COMPANY_NAME_1],
              [KycKeys.BOARD_MEMBERSHIP_EXISTING]: kycData[KycKeys.BOARD_MEMBERSHIP_EXISTING]
            }}
            setKycData={setKycData}
            translator={t}
          />
          <CompanyNameTwo
            data={{
              [KycKeys.BOARD_MEMBERSHIP_COMPANY_NAME_2]:
                kycData[KycKeys.BOARD_MEMBERSHIP_COMPANY_NAME_2],
              [KycKeys.BOARD_MEMBERSHIP_EXISTING]: kycData[KycKeys.BOARD_MEMBERSHIP_EXISTING]
            }}
            setKycData={setKycData}
            translator={t}
          />
          <CompanyNameThree
            data={{
              [KycKeys.BOARD_MEMBERSHIP_COMPANY_NAME_3]:
                kycData[KycKeys.BOARD_MEMBERSHIP_COMPANY_NAME_3],
              [KycKeys.BOARD_MEMBERSHIP_EXISTING]: kycData[KycKeys.BOARD_MEMBERSHIP_EXISTING]
            }}
            setKycData={setKycData}
            translator={t}
          />
        </>
      )}
    </MainLayout>
  );
};
export default EmploymentRenewalInfoStep;
