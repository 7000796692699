import React from 'react';
import Icons from '../../assets/icons';
import { useLocation } from 'react-router';
import { ublockLocalEn } from './localization/en';
import { ublockLocalAr } from './localization/ar';
import { status } from './enums';

import './style.scss';
import UnblockSection from '../../components/UnblockSection';

const Unblock = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const lang = params.get('lang');
  const statusParam = params.get('status');
  const isArabic = lang === 'ar';
  const local = isArabic ? ublockLocalAr : ublockLocalEn;
  const icon = statusParam && statusParam !== status.SUCCESS ? Icons.Error : Icons.Tick;

  const getTitle = statusParam => {
    switch (statusParam) {
      case status.SUCCESS:
        return local.titleSuccess;
      case status.ERROR:
        return local.titleError;
      case status.FAILED:
        return local.titleNotSuccess;
      default:
        return local.titleSuccess;
    }
  };

  const getDescription = statusParam => {
    switch (statusParam) {
      case status.SUCCESS:
        return local.descriptionSuccess;
      case status.ERROR:
        return local.descriptionError;
      case status.FAILED:
        return local.descriptionNotSuccssess;
      default:
        return local.descriptionSuccess;
    }
  };

  return (
    <>
      <UnblockSection
        title={getTitle(statusParam)}
        description={getDescription(statusParam)}
        icon={icon}
      />
    </>
  );
};

export default Unblock;
