const KycKeys = {
  GENDER: "gender",
  FIRST_NAME: "first_name",
  MIDDLE_NAME: "middle_name",
  FAMILY_NAME: "family_name",
  BIRTH_DATE: "birth_date",
  NATIONALITY: "nationality",
  OTHER_NATIONALITIES: "other_nationalities",
  BIRTH_COUNTRY: "birth_country",
  CIVIL_ID_NUMBER: "civil_id_number",
  CIVIL_ID_SERIAL: "civil_id_serial",
  CIVIL_ID_EXPIRY_DATE: "civil_id_expiry_date",
  PASSPORT_NUMBER: "passport_number",
  PASSPORT_COUNTRY: "passport_country",
  PASSPORT_EXPIRY_DATE: "passport_expiry_date",
  GUARDIANSHIP: "guardianship",
  GUARDIAN_FULL_NAME: "guardian_full_name",
  GUARDIAN_NATIONALITY: "guardian_nationality",
  GUARDIAN_CIVIL_ID_NUMBER: "guardian_civil_id_number",
  MARRIAGE_STATUS: "marriage_status",
  PARTNER_NAME: "partner_name",
  CHILDREN_NUMBER: "children_number",
  CHILDREN_NAME: "children_name",
  ADDRESS_CITY: "address_city",
  ADDRESS_AREA: "address_area",
  ADDRESS_BLOCK: "address_block",
  ADDRESS_STREET: "address_street",
  ADDRESS_HOUSE: "address_house",
  EMAIL: "email",
  COUNTRY: "country",
  COUNTRY_CODE: "country_code",
  CONTACT_MOBILE_NUMBER: "contact_mobile_number",
  EMPLOYMENT_STATUS: "employment_status",
  EMPLOYER_TYPE: "employer_type",
  PRIVATE_SECTOR_INDUSTRY: "private_sector_industry",
  GOVERNMENT_ENTITY_TYPE: "government_entity_type",
  EMPLOYMENT_SECTOR: "employment_sector",
  EMPLOYMENT_COMPANY: "employment_company",
  EMPLOYMENT_ENTITY_NAME: "employment_entity_name",
  EMPLOYMENT_ORGANIZATION_NAME: "employment_organization_name",
  EMPLOYMENT_DEPARTMENT: "employment_department",
  JOB_TITLE: "employment_place",
  AUTH_SIGN_PROCESS_FINANCIAL_TRANSACTIONS:
    "auth_sign_process_financial_transactions",
  BOARD_MEMBERSHIP_EXISTING: "board_membership_existing",
  BOARD_MEMBERSHIP_COMPANY_NAME_1: "board_membership_company_name_1",
  BOARD_MEMBERSHIP_COMPANY_NAME_2: "board_membership_company_name_2",
  BOARD_MEMBERSHIP_COMPANY_NAME_3: "board_membership_company_name_3",
  ACCOUNT_PURPOSE: "account_purpose",
  ACCOUNT_PURPOSE_OTHER: "account_purpose_other",
  INCOME_ANNUAL_V2: "income_annual_v2",
  INCOME_SOURCE_V2: "income_source_v2",
  INCOME_TYPE: "income_type",
  INCOME_DETAILS: "income_details",
  INVESTMENT_REASON: "investment_reason",
  INVESTMENT_LEVEL: "investment_level",
  INVESTMENT_YEARS_EXPERIENCE: "investment_years_experience",
  RISK_TOLERANCE_LEVEL: "risk_tolerance_level",
  INVESTMENT_ADVISOR: "investment_advisor",
  PRIVATE_BUSINESS_SAME_EMPLOYMENT_DETAILS:
    "private_business_same_employment_details",
  PRIVATE_BUSINESS_INDUSTRY: "private_business_industry",
  PRIVATE_BUSINESS_SECTOR: "private_business_sector",
  PRIVATE_BUSINESS_COMPANY: "private_business_company",
  PRIVATE_BUSINESS_JOB: "private_business_job",
  PRIVATE_BUSINESS_SENIOR_EXECUTIVE: "private_business_senior_executive",
  ASSETS_VALUE: "assets_value",
  TRANSACTIONS_VALUE_PAST_TWO_YEARS: "transactions_value_past_two_years",
  FINANCIAL_SECTOR_YEARS_EXPERIENCE: "financial_sector_years_experience",
  WEALTH_SOURCE: "wealth_source",
  POLITICAL_POSITION_EXISTING: "political_position_existing",
  POLITICAL_POSITION_IS_ME: "political_position_is_me",
  POLITICAL_POSITION_NAME: "political_position_name",
  POLITICAL_POSITION_RELATIONSHIP: "political_position_relationship",
  POLITICAL_POSITION_ROLE: "political_position_role",
  US_CITIZEN: "us_citizen",
  TAX_PAYER_IDENTIFICATION: "tax_payer_identification",
  US_ADDRESS_1: "us_address_1",
  US_ADDRESS_2: "us_address_2",
  US_ADDRESS_3: "us_address_3",
  PAYS_TAXES_IN_OTHER_COUNTRY: "pays_taxes_in_other_country",
  TAX_COUNTRY_1: "tax_country_1",
  TAX_PAYER_ID_1: "tax_payer_id_1",
  TAX_COUNTRY_2: "tax_country_2",
  TAX_PAYER_ID_2: "tax_payer_id_2",
  TAX_COUNTRY_3: "tax_country_3",
  TAX_PAYER_ID_3: "tax_payer_id_3",
  IS_CUSTOMER_SAME_AS_BENEFICIARY: "is_customer_same_as_beneficiary",
  BENEFICIARY_NAME: "beneficiary_name",
  BENEFICIARY_RELATIONSHIP: "beneficiary_relationship",
  BENEFICIARY_NATIONALITY: "beneficiary_nationality",
  BENEFICIARY_ADDRESS: "beneficiary_address",
  BENEFICIARY_CIVILID_PASSPORT_NUMBER: "beneficiary_civilid_passport_number",
  BENEFICIARY_POWER_OF_ATTORNEY_ENABLED:
    "beneficiary_power_of_attorney_enabled",
  BENEFICIARY_POWER_OF_ATTORNEY_NAME: "beneficiary_power_of_attorney_name",
  BENEFICIARY_POWER_OF_ATTORNEY_NATIONALITY:
    "beneficiary_power_of_attorney_nationality",
  BENEFICIARY_POWER_OF_ATTORNEY_CIVIL_ID_NUMBER:
    "beneficiary_power_of_attorney_civil_id_number",
  LAST_EMPLOYMENT_STATUS: "last_employment_status",
  WEALTH_AMOUNT_INHERITANCE: "wealth_amount_inheritance",
  WEALTH_DETAILS_INHERITANCE: "wealth_details_inheritance",
  WEALTH_AMOUNT_JOB: "wealth_amount_job",
  WEALTH_AMOUNT_SAVING: "wealth_amount_saving",
  WEALTH_DETAILS_SAVING: "wealth_details_saving",
  WEALTH_AMOUNT_SALE: "wealth_amount_sale",
  WEALTH_DETAILS_SALE: "wealth_details_sale",
  WEALTH_AMOUNT_OTHER: "wealth_amount_others",
  WEALTH_DETAILS_OTHER: "wealth_details_others",
  WEALTH_AMOUNT_INVESTMENTS: "wealth_amount_investments",
  WEALTH_DETAILS_INVESTMENTS: "wealth_details_investments",
  NBKC_CLIENT_CLASSIFICATION: "nbkc_client_classification",
  CHILDREN_DETAILS: "children_details",
  CITY_TOWN_BIRTH: "city_town_birth",
  EMAIL_CONFIRM: "email_confirm",
  NBKC_STAFF: "nbkc_staff",
  RELATIONSHIP_STATUS: "relationship_status",
  BANK_NAME: "bank_name",
  IBAN_NUMBER: "iban_number",
  MOTHER_FIRST_NAME: "mother_first_name",
  MOTHER_MIDDLE_NAME: "mother_middle_name",
  MOTHER_LAST_NAME: "mother_family_name",
  FATHER_FIRST_NAME: "father_first_name",
  FATHER_MIDDLE_NAME: "father_middle_name",
  FATHER_LAST_NAME: "father_family_name",
  MOTHER_NAME: "mother_name",
  US_PASSPORT_NUMBER: "us_passport_number",
  US_PASSPORT_EXPIRY_DATE: "us_passport_expiry_date",
  FULL_NAME_AS_IN_US_PASSPORT: "us_passport_full_name",
  WEALTH_AMOUNT: "wealth_amount",
  IS_CLIENT_POLITICAL_POSITION: "is_client_political_position",
  CLIENT_POLITIAL_POSITION_COUNT: "client_political_position_count",
  CLIENT_POLITICAL_POSITIONS: "political_positions",
  IS_RELATIVE_POLITICAL_POSITION: "is_relative_political_position",
  RELATIVE_POLITICAL_POSITION_COUNT: "relative_political_position_count",
  RELATIVE_POLITICAL_POSITIONS: "relative_political_positions",
  CLIENT_POLITICAL_POSITION_ROLE: "client_political_position",
  CLIENT_POLITICAL_POSITION_STATUS: "client_political_position_status",
  RELATIVE_POLITICAL_POSITION_ROLE: "relative_political_position",
  RELATIVE_FISRT_NAME_ROLE: "relative_first_name",
  RELATIVE_FAMILY_NAME_ROLE: "relative_family_name",
  RELATIVE_RELATIONSHIP: "relative_relationship",
  RELATIVE_POLITICAL_STATUS: "relative_political_status",
};

export default KycKeys;
