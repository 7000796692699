import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import KycKeys from "../../../enums/kyc-enum";
import { politicalPositionEnums } from "../../../enums/additional-info-enums";
import KYCValidator from "../../../helpers/validation";
import CustomSelect from "../../../widgets/inputs/custom-select";

const RelativePoliticalPositionRole = ({
  data,
  translator,
  setKycData,
  kycFields,
  isRenewal,
  counterValue,
}) => {
  const relativePoliticalPositionsKey = KycKeys.RELATIVE_POLITICAL_POSITIONS;
  const relativePoliticalPositionKey = KycKeys.RELATIVE_POLITICAL_POSITION_ROLE;
  const {
    index: positionIndex,
    [relativePoliticalPositionsKey]: { value: relativePoliticalPositionsList },
  } = data;

  const { [politicalPositionEnums.POSITION]: value } =
    relativePoliticalPositionsList[positionIndex];

  const { political_position_role: positions } = kycFields;

  const [errorMessage, setErrorMessage] = useState("");

  const items = Object.entries(positions)?.map(([key, value]) => ({
    label: value,
    value: key,
  }));

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);

    if (fieldErrorObject.errorMessage) {
      setErrorMessage(fieldErrorObject.errorMessage);
    } else {
      setErrorMessage("");
    }

    const updatedList = [...relativePoliticalPositionsList];

    updatedList[positionIndex] = {
      ...updatedList[positionIndex],
      [politicalPositionEnums.POSITION]: value,
    };

    setKycData((prev) => ({
      ...prev,
      [relativePoliticalPositionsKey]: {
        ...prev[relativePoliticalPositionsKey],
        error: "",
        value: updatedList,
      },
    }));
  };

  const handleChange = (value) => {
    validateField(value);
  };

  useEffect(() => {
    if (isRenewal && counterValue == 1) {
      validateField(value);
    } else if (isRenewal && counterValue !== 1) {
      setErrorMessage("");
    }
  }, [counterValue]);
  return (
    <CustomSelect
      name={`${relativePoliticalPositionKey}_${positionIndex}`}
      inputOptions={items}
      label={translator("relativePosition", { counter: positionIndex + 1 })}
      onChange={handleChange}
      value={value}
      errorMessage={errorMessage}
    />
  );
};

RelativePoliticalPositionRole.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  kycFields: PropTypes.object.isRequired,
};

export default RelativePoliticalPositionRole;
