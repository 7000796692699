export const locale = {
  menu: "Menu",
  changeLanguage: "Change language",
  privacyPolicy: "Privacy policy",
  fullDisclosure: "Full disclosure",
  nbkWealth: "NBK Wealth",
  back: "Back",

  landingTitle: "Begin your trading journey with Brokerage",
  landingSubtitle:
    "Seamlessly trade on major global stock exchanges and manage their investments with advanced tools, including real-time pricing, portfolio analysis, and mobile trading capabilities.",
  wataniBrokerage: "Watani Brokerage",
  startHere: "Start here",
  resumeOnboarding: "Continue onboarding",
  resumeDescription:
    "Have you already started your journey? Click here to continue where you left off",
  continue: "Continue",
  brokerage: "Brokerage",
  brokerageDescription: "Select a brokerage type to continue",
  getStarted: "Get started",
  getStartedUpper: "Get started",

  regionalMarkets: "Regional Markets",
  regionalMarketsSharia: "Regional Markets - Sharia",
  internationalMarkets: "International Markets",
  realTimeStockPrices: "Real-time stock prices",
  marketWatchScreen: "Market Watch screen",
  advancedCharting: "Advanced Charting & Portfolio Analysis",
  failedOTPMessage: "The OTP you have entered is incorrect.",
  resendOTP: "Resend OTP",
  resendCountTime: "Resend OTP in ",
  enterOTPMessage: "Enter OTP",
  otpNumberLabel: "OTP number",
  otpSentMessage: "An OTP has been sent to your mobile number",
  enterCivilIdMessage: "Enter your Civil ID details",
  civilIdLabel: "Civil ID number",
  serialNumberLabel: "Civil ID serial number",
  confirmEmailLabel: "Email address confirmation",
  emailAddressLabel: "Email address",
  enterEmailAddress: "Enter your email address",
  informationForEmail:
    "The email address will be linked to your profile and will be used for future communications",
  verifyLabel: "Verify",
  successOTP: "Successfully verified",
  verifiedMessage:
    "Your OTP was successfully verified and you are now securely logged in.",
  sendOTP: "Send OTP",
  enterPhoneNumberMessage: "Please enter your phone number",
  phoneNumberLabel: "Phone number",
  openYourAccount: "Open your account",
  beginTradingJourneyDescription:
    "To ensure the security of your account and to comply with regulatory requirements, we need to verify your identity and collect your details.",
  estimatedTime: "Estimated Time: 10 Minutes",

  start: "Start",
  personalInfoStepTitle: "Personal Information",
  incomeAndWealthStepTitle: "Income and Wealth",
  saveAndContinueButton: "Save and Continue",
  firstName: "First name",
  middleName: "Middle name",
  familyName: "Family name",
  mobileNumber: "Phone Number",
  employmentStatusLabel: "Employment status",
  employerTypeLabel: "Employer type",
  businessSectorLabel: "Business sector",
  entityTypeLabel: "Entity type",
  typeOfBusinessLabel: "Type of business",
  privateBusinessNameLabel: "Name of the private business",
  employerNameLabel: "Employer name",
  entityNameLabel: "Entity name",
  organizationNameLabel: "Organization name",
  departmentLabel: "Department",
  jobTitleLabel: "Job title",
  seniorExecutiveOrOwnerLabel:
    "Are you a senior executive or owner of this organization and/or has the authority to sign on its behalf or process financial transactions?",
  seniorLeadershipPositionLabel:
    "Are you in a senior leadership position (i.e. undersecretary and above) within their entity and/or has the authority to sign on its behalf or process financial transactions?",
  boardMemberOrInsiderLabel:
    "Are you a board member or an insider of a Kuwait-listed company?",
  lastEmploymentStatusLabel: "Last employment status before retirement",
  companyName1Label: "Company name 1",
  companyName2Label: "Company name 2 (Optional)",
  companyName3Label: "Company name 3 (Optional)",
  totalStepsNumber: "/5",
  stepLabel: "Step",
  contactInformation: "Contact information",
  motherFirstName: "Mother’s first name",
  country: "Country",
  EmploymentInfoStepTitle: "Employment Info",
  yesOptionLabel: "Yes",
  noOptionLabel: "No",
  city: "City",
  area: "Area",
  block: "Block",
  street: "Street",
  house: "House",
  phoneNumber: "Phone number",
  email: "Email",
  countryCode: "Country Code",
  summaryStepTitle: "Please review your information",
  submitButtonLabel: "Submit",
  editButtonLabel: "Edit",
  noMobileNumberMessage:
    "If you are an existing client, you will receive an OTP on your registered number, please enter it below",
  resendOTPMessage: "An OTP code has been resent to your number",
  areYouPep: "Are you or one of your relatives a politically exposed person?",
  isMePep: "Are you holding the political position?",
  nameOfFamilyMember: "Full name of family member",
  relationToFamilyMember: "Relationship to family member",
  politicalPosition: "Political position",
  isUsCitizen: "Are you a US citizen?",
  taxPayerId: "Taxpayer identification number",
  usAddress1: "US address 1",
  usAddress2: "US address 2 (Optional)",
  usAddress3: "US address 3 (Optional)",
  payTaxInAnotherCountry: "Do you pay taxes in another country?",
  countryAlreadySelected: "Country already selected before",
  taxCountryOne: "Tax country 1",
  taxIdOne: "Taxpayer ID number 1",
  taxCountryTwo: "Tax country 2",
  taxIdTwo: "Taxpayer ID number 2",
  taxCountryThree: "Tax country 3",
  taxIdThree: "Taxpayer ID number 3",
  contactInfo: "Contact Info",
  additionalInfo: "Additional Info",
  NALabel: "N/A",
  gender: "Gender",
  dateOfBirth: "Date of birth",
  nationality: "Nationality",
  passportNumber: "Passport number",
  passportCountry: "Passport country",
  passportExpiryDate: "Passport expiry date",
  birthCountry: "Country of birth",
  cityTownBirth: "Town/City of birth",
  civilIdNumber: "Civil ID number",
  civilIdSerialNumber: "Civil ID serial number",
  civilIdExpiryDate: "Civil ID expiry date",
  motherName: "Mother's full name",
  maritalStatus: "What is your marital status?",
  partnerName: "Spouse's full name",
  childrenNumber: "How many children do you have?",
  purposeOfOpeningTheAccountForOtherLabel:
    'Since "Other" was selected, please provide more details for the account opening purpose',
  totalCashAndLiquiredInvestmentLabel:
    "What is the total value of your cash and liquid investments?",
  purposeOfOpeningTheAccountLabel: "Purpose for opening this account",
  annualIncomeLabel: "Annual income",
  investingReasonLabel: "What's your primary reason for investing?",
  yearsOfInvestmentExperienceLabel: "Years of investment experience",
  levelOfRiskToleranceLabel: "Level of risk tolerance",
  investmentKnowledgeLevelLabel: "Level of investment knowledge",
  incomeTypeLabel:
    'Since "Other" was selected, in the source of annual income, please specify the income type',
  incomeDetailsLabel: "Please provide your income details",
  wealthAmountJobLabel: "Estimated wealth from job indemnity in KWD",
  wealthAmountInheritanceLabel: "Estimated wealth from inheritance in KWD ",
  wealthDetailsInheritanceLabel:
    "Please provide the source of your inheritance",
  wealthAmountInvestmentsLabel: "Estimated wealth from investments in KWD",
  wealthDetailsInvestmentsLabel:
    "Please provide the source of your investments",
  wealthAmountSavingLabel: "Estimated wealth from saving in KWD",
  wealthDetailsSavingLabel: "Please provide the source of your Saving  ",
  wealthAmountSaleLabel: "Estimated wealth from sale of property in KWD ",
  wealthDetailsSaleLabel:
    "Please provide the buyer’s name and the property details",
  wealthAmountOtherLabel: "Estimated wealth from others in KWD  ",
  wealthDetailsOtherLabel: "Please provide your wealth details",
  kuwaitCurrency: "KWD",
  clientHaveInvestmentAdvisorLabel: "Do you have an investment advisor?",
  sourceOfWealthLabel: "Source of wealth",
  retailLabel: "Retail",
  qualifiedProfessionalLabel: "Qualified Professional",
  assetsValueLabel:
    "Value of assets (cash and securities) held with NBK Capital or other licensed persons",
  financialSectorYearsExperienceLabel:
    "Work experience in financial sector in a professional capacity",
  sameBusinessDetailsLabel:
    "Are your private business details the same as those provided in the previous employment details section?",
  transactionsValuePastTwoYearsLabel:
    "Value of transactions in securities per quarter during preceding 2 years",
  sourceAnnualIncomeLabel: "Source of annual income",
  oneMinuteCounter: "1 min",
  middleNameTooltipMessage:
    "In case the middle name is not applicable, you may enter your father’s name instead",
  otpVerifiedTitle: "OTP verified",
  alreadyVerifiedClientMessage:
    "Your OTP is already verified you will be redirected to the dashboard",
  maxAttempts:
    "You have reached the maximum requests allowed within 5 minutes. Please try again later.",
  brokerageInfoMessage:
    "You can change your selection at a later stage. You will just need to sign different papers",
  civilIdVerification: "Civil ID verification",
  firstStepCivilIdVerificationNumber: "01",
  secondStepCivilIdVerificationNumber: "02",
  thirdStepCivilIdVerificationNumber: "03",
  uploadFrontCivilIdTitle: "Upload front",
  uploadBackCivilIdTitle: "Upload back",
  uploadSelfieTitle: "Upload selfie with Civil ID",
  uploadFrontCivilIdSubTitle: "Click to upload the front of your Civil ID.",
  uploadBackCivilIdSubTitle: "Click to upload the back of your Civil ID.",
  uploadSelfieSubTitle: "Click to upload selfie with Civil ID.",
  expiredOTP: "OTP code has expired.",
  invalidOTP: "Invalid OTP.",
  alreadyOnboarded:
    "It looks like you already have an application in progress that's being handled by your Relationship Manager. Please reach out to your RM directly to continue with your application.",
  kycExpired:
    "It appears that your KYC has expired and requires renewal to proceed with your transaction request, ensuring compliance with regulatory requirements. Please click the link below to renew now:",
  maxAttemptsArabic:
    "لقد وصلت إلى الحد اﻷقصى من الطلبات المسموح بها في 5 دقائق. الرجاء معاودة المحاولة في وقت لاحق.",
  issueEncountered: "An error has occurred. Please contact support team.",
  tooManyRequestMessage: "Too Many Requests",
  validSerialNumber: "Please enter a valid Civil ID serial number.",
  issueInCivilId:
    "Please contact the bank for assistance with the issue encountered.",
  issueInCivilId2:
   "Please contact your bank for assistance with the issue encountered.",
  somethingWentWrongMessage: "Something went wrong",
  alreadyOnboardedNonPB: "alreadyOnboardedNonPB"
};

export default locale;
