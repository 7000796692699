import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { identityVerificationConfig } from './config';
import VerificationSteps from './components/verification-steps';
import PrimaryButton from '../../widgets/buttons/primary-button';
import { areAllStepsDone } from '../../helpers/general-helpers';
import { journeyTimeLineRoute } from '../../routes/routes.const';
import { useTranslation } from 'react-i18next';

const IdentityVerification = () => {
  const { t } = useTranslation('common');
  const location = useLocation();
  const navigate = useNavigate();
  const [verificationData, setVerificationData] = useState(identityVerificationConfig);

  const { orderId = '' } = location.state;

  const onClick = () => {
    navigate(journeyTimeLineRoute, {
      replace: true
    });
  };

  const renderFooter = () => {
    const isAllStepsDone = areAllStepsDone(verificationData);
    return (
      <PrimaryButton onClick={onClick} disabled={!isAllStepsDone} data-testid='submit'>
        {t('submitButtonLabel')}
      </PrimaryButton>
    );
  };

  return (
    <VerificationSteps
      data={verificationData}
      setVerificationData={setVerificationData}
      orderId={orderId}
      renderFooter={renderFooter}
    />
  );
};

export default IdentityVerification;
