import React, { useState, useRef, useEffect } from "react";
import styles from "../info-box.module.scss";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const InfoRenewalBox = ({
  title,
  subTitle,
  beforeDescription = "",
  description = "",
  color,
  borderColor,
}) => {
  const { t } = useTranslation("common");
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [trimmedText, setTrimmedText] = useState(description);
  const textRef = useRef(null);
  const descriptionRef = useRef(null);
  const maxHeight = 60;

  useEffect(() => {
    const checkOverflow = () => {
      // This function checks if the description text is overflowing beyond the maxHeight (60px)
      // If overflowing:
      // 1. Sets isOverflowing state to true
      // 2. Calculates how many characters can fit within maxHeight
      // 3. Truncates the text and adds "..." at the end
      // If not overflowing:
      // - Shows full description text
      // The function uses refs to measure actual DOM element heights
      // and updates states (isOverflowing, trimmedText) accordingly
      if (textRef.current && descriptionRef.current) {
        const descriptionTextHeight = textRef.current.scrollHeight;
        const isDescriptionTextOverflowing = descriptionTextHeight > maxHeight;
        setIsOverflowing(isDescriptionTextOverflowing);
        if (isDescriptionTextOverflowing) {
          const maxChars = Math.floor((maxHeight / descriptionTextHeight) * description.length);
          const truncatedText = description.slice(0, maxChars - 3) + "...";
          setTrimmedText(truncatedText);
        } else {
          setTrimmedText(description);
        }
      }
    };
    checkOverflow();  
  }, [description, maxHeight]);

  return (
    <div
      ref={descriptionRef}
      className={`${styles.containerWithShadow}`}
      style={{ borderColor: borderColor }}
    >
      <span className={`${styles.title}`} style={{ color: color }}>
        {title}
      </span>
      <div className={styles.subTitleContainer}>
        {subTitle && <div className={styles.subTitle}>{subTitle}</div>}

        <div className={styles.subTitle}> {beforeDescription}</div>
        {description && (
          <>
           <div ref={textRef} className={styles.description} style={{
            maxHeight: isExpanded ? 'none' : `${maxHeight}px`,
           }}>
              <span className={styles.descriptionLabel}>
                {t("descriptionLabel")}
              </span>{" "}
              {isOverflowing && !isExpanded ? trimmedText : description}
           </div>
           {isOverflowing && (
            <span className={styles.viewMore} onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? t("viewLess1") : t("viewMore1")}
            </span>
           )}
          </>
        )}
      </div>
    </div>
  );
};

InfoRenewalBox.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  description: PropTypes.string,
  beforeDescription: PropTypes.string,
  color: PropTypes.string,
  borderColor: PropTypes.string,
};

export default InfoRenewalBox;
