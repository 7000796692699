import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route } from 'react-router';
import ProtectedRoute from './protected-route';
import LandingPage from '../pages/landing';
import SentryRoutes from '../instrument';
import {
  civildIDetailsRoute,
  landingRoute,
  onboarding,
  productsListing,
  otpVerification,
  phoneNumberDetailsRoute,
  otpSuccessRoute,
  emailAddressDetailsRoute,
  journeyTimeLineRoute,
  kycDetailsRoute,
  identityVerificationRoute,
  reviewAndSignRoute,
  personalInfoStepRoute,
  employmentInfoStepRoute,
  contactInfoStepRoute,
  kycSummaryStepRoute,
  additionalInfoStepRoute,
  incomeAndWealthInfoStepRoute,
  internationalBrokerageRoute,
  applicationSubmittedRoute,
  contactSupportRoute,
  additionalRenewalInfoStepRoute,
  contactRenewalInfoStepRoute,
  personalRenewalInfoStepRoute,
  employmentRenewalInfoStepRoute,
  incomeAndWealthRenewalInfoStepRoute,
  kycRenewalSummaryStepRoute,
  civildIDetailsRenewalRoute,
  otpVerificationRenewal,
  notRenewalRoute,
  otpSuccessRenewalRoute,
  journeyTimeLineRenewalRoute,
  identityVerificationRenewalRoute,
  emailAddressDetailsRenewalRoute,
  uploadAdditionalDocumentsRoute,
  applicationSubmittedRenewalRoute
} from './routes.const';
import Onboarding from '../pages/onboarding';
import Navigator from '../widgets/navigator';
import Products from '../pages/products';
import NotFound from '../pages/not-found';
import CivilIdDetails from '../pages/civil-id-details';
import PersonalInfoStep from '../pages/personal-info-step';
import PhoneNumberDetails from '../pages/phone-number-details';
import OTPSuccess from '../pages/otp-success';
import EmailAddressDetails from '../pages/email-address-details';
import OTPDetails from '../pages/otp-verification';
import JourneyTimeline from '../pages/journey-timeline';
import KycDetails from '../pages/kyc-details';
import IdentityVerification from '../pages/identity-verification';
import ReviewAndSign from '../pages/review-and-sign';
import EmploymentInfoStep from '../pages/employment-info-step';
import ContactInfoStep from '../pages/contact-info-step';
import KycSummaryStep from '../pages/kyc-summary-step';
import { getCountriesAndCities, getKycFields } from '../general-services.proxy';
import {
  setCities,
  setKycFields,
  setCountries,
  setKycFieldsEn,
  setKycFieldsAr,
  setCountriesAr,
  setCountriesEn
} from '../store/slices/general.slice';
import { useDispatch } from 'react-redux';
import IncomeAndWealthStep from '../pages/income-and-wealth';
import AdditionalInfoStep from '../pages/additional-info-step';
import NoAuthRoute from './no-auth-route';
import InternationalBrokerage from '../pages/international-brokerage';
import { useTranslation } from 'react-i18next';
import { languageEnum } from '../enums/language-enum';
import KycGuardRoute from './kyc-guard-route';
import ApplicationSubmitted from '../pages/application-submitted';
import ContactSupport from '../pages/contact-support';
import EmploymentRenewalInfoStep from '../pages/employment-info-step/renewal';
import IncomeAndWealthRenewalStep from '../pages/income-and-wealth/renewal';
import PersonalRenewalInfoStep from '../pages/personal-info-step/renewal';
import ContactRenewalInfoStep from '../pages/contact-info-step/renewal';
import AdditionalRenewalInfoStep from '../pages/additional-info-step/renewal';
import KycRenewalSummaryStep from '../pages/kyc-summary-step/renewal';
import CivilIdDetailsRenewal from '../pages/civil-id-details/renewal';
import OTPDetailsRenewal from '../pages/otp-verification/renewal';
import NotRenewal from '../pages/not-renewal';
import OTPSuccessRenewal from '../pages/otp-success/renewal';
import JourneyTimelineRenewal from '../pages/journey-timeline/renewal';
import IdentityVerificationRenewal from '../pages/identity-verification/renewal';
import EmailAddressDetailsRenewal from '../pages/email-address-details/renewal';
import UploadAdditionalDocuments from '../pages/upload-additional-documents';
import ApplicationSubmittedRenewal from '../pages/application-submitted/renewal';
import TitleUpdater from '../components/title-updater';
import { isWebRenewalEnv, isInvestEnv } from '../config';
import Privacy from '../pages/privacy';
import Disclosure from '../pages/disclosure';
import Unblock from '../pages/Unblock';
import LandingInvest from '../pages/landing-invest';

const PageRouter = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const fetchCountrieAndCities = async () => {
    try {
      const response = await getCountriesAndCities();
      dispatch(setCountriesAr(response.countriesAr));
      dispatch(setCountriesEn(response.countriesEn));
      dispatch(setCities(response.cities));

      if (i18n.language === languageEnum.arabic) {
        dispatch(setCountries(response.countriesAr));
        return;
      }
      dispatch(setCountries(response.countriesEn));
    } catch (exception) {
      throw new Error(exception);
    }
  };

  const fetchKycFields = async () => {
    try {
      const response = await getKycFields();
      dispatch(setKycFieldsAr(response.kyc_fields_ar));
      dispatch(setKycFieldsEn(response.kyc_fields_en));
      if (i18n.language === languageEnum.arabic) {
        dispatch(setKycFields(response.kyc_fields_ar));
        return;
      }
      dispatch(setKycFields(response.kyc_fields_en));
    } catch (exception) {
      throw new Error(exception);
    }
  };

  const fetchData = async () => {
    try {
      await fetchKycFields();
      await fetchCountrieAndCities();
    } catch (exception) {
      console.error(exception);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  console.log(isInvestEnv);
  return (
    <Router>
      <Navigator />
      <SentryRoutes>
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/disclosure' element={<Disclosure />} />
        <Route path='/unblock' element={<Unblock />} />
        <Route exact path={otpSuccessRoute} element={<OTPSuccess />} />

        <Route exact path={otpSuccessRenewalRoute} element={<OTPSuccessRenewal />} />
        <Route element={<NoAuthRoute />}>
          <Route
            exact
            path='/'
            element={
              !isWebRenewalEnv ? (
                <LandingPage />
              ) : isInvestEnv ? (
                <LandingInvest />
              ) : (
                <CivilIdDetailsRenewal />
              )
            }
          />
          <Route exact path={productsListing} element={<Products />} />
          <Route exact path={internationalBrokerageRoute} element={<InternationalBrokerage />} />

          <Route exact path={civildIDetailsRoute} element={<CivilIdDetails />} />

          <Route exact path={civildIDetailsRenewalRoute} element={<CivilIdDetailsRenewal />} />
          <Route exact path={phoneNumberDetailsRoute} element={<PhoneNumberDetails />} />
          <Route exact path={otpVerification} element={<OTPDetails />} />
          <Route exact path={otpVerificationRenewal} element={<OTPDetailsRenewal />} />
          <Route exact path={notRenewalRoute} element={<NotRenewal />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route exact path={emailAddressDetailsRoute} element={<EmailAddressDetails />} />
          <Route
            exact
            path={emailAddressDetailsRenewalRoute}
            element={<EmailAddressDetailsRenewal />}
          />
          <Route exact path={identityVerificationRoute} element={<IdentityVerification />} />
          <Route exact path={kycDetailsRoute} element={<KycDetails />} />
          <Route exact path={reviewAndSignRoute} element={<ReviewAndSign />} />
          <Route exact path={journeyTimeLineRoute} element={<JourneyTimeline />} />

          <Route element={<KycGuardRoute />}>
            <Route exact path={employmentInfoStepRoute} element={<EmploymentInfoStep />} />
            <Route exact path={incomeAndWealthInfoStepRoute} element={<IncomeAndWealthStep />} />
            <Route exact path={personalInfoStepRoute} element={<PersonalInfoStep />} />
            <Route exact path={contactInfoStepRoute} element={<ContactInfoStep />} />

            <Route
              exact
              path={employmentRenewalInfoStepRoute}
              element={<EmploymentRenewalInfoStep />}
            />
            <Route
              exact
              path={incomeAndWealthRenewalInfoStepRoute}
              element={<IncomeAndWealthRenewalStep />}
            />
            <Route
              exact
              path={personalRenewalInfoStepRoute}
              element={<PersonalRenewalInfoStep />}
            />
            <Route exact path={contactRenewalInfoStepRoute} element={<ContactRenewalInfoStep />} />

            <Route exact path={kycSummaryStepRoute} element={<KycSummaryStep />} />
            <Route exact path={kycRenewalSummaryStepRoute} element={<KycRenewalSummaryStep />} />
            <Route exact path={additionalInfoStepRoute} element={<AdditionalInfoStep />} />
            <Route
              exact
              path={additionalRenewalInfoStepRoute}
              element={<AdditionalRenewalInfoStep />}
            />
          </Route>
          <Route exact path={applicationSubmittedRoute} element={<ApplicationSubmitted />} />
          <Route
            exact
            path={applicationSubmittedRenewalRoute}
            element={<ApplicationSubmittedRenewal />}
          />
          <Route exact path={onboarding} element={<Onboarding />} />
          <Route
            exact
            path={uploadAdditionalDocumentsRoute}
            element={<UploadAdditionalDocuments />}
          />
          <Route exact path={journeyTimeLineRenewalRoute} element={<JourneyTimelineRenewal />} />
          <Route
            exact
            path={identityVerificationRenewalRoute}
            element={<IdentityVerificationRenewal />}
          />
        </Route>
        <Route exact path={contactSupportRoute} element={<ContactSupport />} />
        <Route path='*' element={<NotFound />} />
      </SentryRoutes>
      <TitleUpdater />
    </Router>
  );
};

export default PageRouter;
