import React, { useEffect } from 'react';
import MainLayout from '../../layouts/main-layout';
import PrimaryButton from '../../widgets/buttons/primary-button';
import webNet from '../../assets/icons/web-net-icon.svg';
import webNetInternational from '../../assets/icons/international-web-icon.svg';
import styles from './products.module.scss';
import ProductCard from '../../widgets/product-card';
import { useNavigate } from 'react-router';
import { civildIDetailsRoute, internationalBrokerageRoute } from '../../routes/routes.const';
import { useDispatch, useSelector } from 'react-redux';
import { setProductChosen, resetUserInfoState } from '../../store/slices/general.slice';
import { useTranslation } from 'react-i18next';
import { setSavedProduct } from '../../utils';
import Alert from '../../components/alert-component-not-fixed';
import infoIcon from '../../assets/icons/blue-info-icon.svg';
import { brokerageEums } from '../../enums/general';
import featureFlags from '../../core/flag-config';

const Products = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const { productChosen } = useSelector(state => state.general);
  const isUSMarketsEnabled = featureFlags.showUSMarketProduct;

  const products = [
    {
      id: brokerageEums.REGIONAL_BROKERAGE_RBU,
      icon: webNet,
      title: t('regionalMarkets'),
      descriptions: [t('realTimeStockPrices'), t('marketWatchScreen'), t('advancedCharting')]
    },
    ...(isUSMarketsEnabled
      ? [
          {
            id: brokerageEums.INTERNATIONAL_BROKERAGE,
            icon: webNetInternational,
            title: t('internationalMarkets'),
            descriptions: [
              t('realTimeStockPrices2'),
              t('marketWatchScreen2'),
              t('advancedCharting2')
            ]
          }
        ]
      : [])
  ];

  const onProductClick = productId => {
    dispatch(setProductChosen(productId));
  };

  const renderFooter = () => (
    <PrimaryButton
      disabled={!productChosen}
      onClick={() => {
        setSavedProduct(productChosen);
        dispatch(resetUserInfoState());
        if (productChosen === brokerageEums.INTERNATIONAL_BROKERAGE) {
          navigate(internationalBrokerageRoute);
          return;
        }
        navigate(civildIDetailsRoute);
      }}
      data-testid='get started'
    >
      {t('getStarted')}
    </PrimaryButton>
  );

  useEffect(() => {
    if (!isUSMarketsEnabled) {
      dispatch(setProductChosen(brokerageEums.REGIONAL_BROKERAGE_RBU));
    }
  }, []);

  return (
    <MainLayout
      title={t('brokerage')}
      subTitle={isUSMarketsEnabled ? t('brokerageDescription') : ''}
      footer={renderFooter}
    >
      <div className={styles.mainContainer}>
        {products.map((product, index) => (
          <ProductCard
            product={product}
            key={index}
            productChosen={productChosen}
            onClick={onProductClick}
          />
        ))}
      </div>
      {isUSMarketsEnabled && productChosen && (
        <Alert
          text={t('brokerageInfoMessage')}
          leftIcon={infoIcon}
          styling={{ backGroundColor: '#EEF4FD', textColor: '#7595F5' }}
        />
      )}
    </MainLayout>
  );
};

export default Products;
