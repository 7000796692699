import React from 'react';
import KycKeys from '../../../enums/kyc-enum';
import PropTypes from 'prop-types';
import { politicalPositionEnums } from '../../../enums/additional-info-enums';
import CustomSelect from '../../../widgets/inputs/custom-select';
import KYCValidator from '../../../helpers/validation';

const RelativePoliticalPositionCount = ({
  data,
  setKycData,
  translator,
  counterValue,
  setCounter = () => {}
}) => {
  const fieldKey = KycKeys.RELATIVE_POLITICAL_POSITION_COUNT;
  const isRelativePoliticalPositionKey = KycKeys.IS_RELATIVE_POLITICAL_POSITION;
  const relativePoliticalPositionsKey = KycKeys.RELATIVE_POLITICAL_POSITIONS;

  const {
    [fieldKey]: { value, error },
    [isRelativePoliticalPositionKey]: { value: relativePoliticalPositionValue },
    [relativePoliticalPositionsKey]: { value: relativePoliticalPositionsList }
  } = data;

  const positionsCount = relativePoliticalPositionsList.length;

  const validateField = value => {
    const fieldErrorObject = KYCValidator.validateField(value);
    const currentList = relativePoliticalPositionsList || [];

    const updatedList = Array.from(
      { length: value },
      (_, index) =>
        currentList[index] || {
          [politicalPositionEnums.FIRST_NAME]: '',
          [politicalPositionEnums.FAMILY_NAME]: '',
          [politicalPositionEnums.RELATIONSHIP]: '',
          [politicalPositionEnums.POSITION]: '',
          [politicalPositionEnums.STATUS]: ''
        }
    );

    setKycData(prev => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        error: fieldErrorObject.errorMessage,
        value
      },
      [relativePoliticalPositionsKey]: {
        ...prev[relativePoliticalPositionsKey],
        value: updatedList,
        error: ''
      }
    }));
  };

  const handleChange = value => {
    setCounter(counterValue + 1);
    validateField(value);
  };

  if (!relativePoliticalPositionValue) {
    return;
  }

  const options = [
    { value: 1, label: '1' },
    { value: 2, label: '2' }
  ];

  return (
    <CustomSelect
      name={'relativePepCount'}
      inputOptions={options}
      label={translator('relativePoliticalPositionsNumberLabel')}
      onChange={handleChange}
      value={positionsCount}
      errorMessage={error}
    />
  );
};

RelativePoliticalPositionCount.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  optionalHandleChange: PropTypes.func,
  isSectionDisabled: PropTypes.bool,
  setCounter: PropTypes.func
};

export default RelativePoliticalPositionCount;
