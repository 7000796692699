import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
  useNavigate,
  Routes
} from 'react-router';
const ENVIRONMENT = process.env.REACT_APP_ENV;
const isDevEnvironment =
  ENVIRONMENT === 'development' ||
  ENVIRONMENT === 'dev-uat' ||
  ENVIRONMENT === 'dev-web-renewal-uat' ||
  ENVIRONMENT === 'web-renewal-dev' ||
  ENVIRONMENT === 'onboarding';

const dsn = 'https://d3c650ea1abfa6056e2f1675c4ba5bc7@o116128.ingest.us.sentry.io/4508777432088577';

ENVIRONMENT !== isDevEnvironment
  ? Sentry.init({
      dsn: dsn,
      environment: ENVIRONMENT,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
          useNavigate
        })
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for tracing.
      tracesSampleRate: 1.0,

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.0,
      replaysOnErrorSampleRate: ENVIRONMENT === 'production' ? 1.0 : 0.0
    })
  : '';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export default SentryRoutes;
