import React, { useEffect, useState } from 'react';
import MainLayout from '../../../layouts/main-layout';
import clockIcon from '../../../assets/icons/clock-icon.svg';
import alertIcon from '../../../assets/icons/blue-info-icon.svg';
import checkedBoxIcon from '../../../assets/icons/checked-box.svg';
import notCheckedBoxIcon from '../../../assets/icons/not-checked-box.svg';
import styles from '../journey-timeline.module.scss';
import TimeLine from '../../../widgets/time-line';
import {
  COMPLETE_PROFILE_ID,
  IDENTITY_VERIFICATION_ID,
  UPLOAD_DOCUMENT_ID,
  timeLineRenewalConfig
} from '../config';
import PrimaryButton from '../../../widgets/buttons/primary-button';
import { areAllStepsDone, isStepDoneById } from '../../../helpers/general-helpers';
import { useNavigate, useLocation } from 'react-router';
import {
  generateClientDocuments,
  getAllCustomerDocuments,
  getCustomer,
  getCustomerOrder,
  getCustomerRisk,
  getKycDataNewVersion2,
  getKycDataV2,
  postCustomerRisk,
  submitToAdmin
} from '../../../general-services.proxy';
import {
  applicationSubmittedRenewalRoute,
  identityVerificationRenewalRoute,
  kycRenewalSummaryStepRoute,
  uploadAdditionalDocumentsRoute
} from '../../../routes/routes.const';
import { useTranslation } from 'react-i18next';
import { setIsAllStepsDone, setKycSubmitted } from '../../../store/slices/general.slice';
import { useDispatch } from 'react-redux';
import CircularLoader from '../../../widgets/circular-loader';
import Banner from '../../../widgets/banner';
import { customerRiskEnums, returnReasons } from '../../../config';
import TermsAndConditionsModal from '../../../modals/terms-and-conditions';
import {
  CLIENT_ADDITIONAL_FILE,
  documentTypesToBeFiltered
} from '../../upload-additional-documents/config';
import LoadingComponent from '../../../components/loading-component';
import { setToken } from '../../../utils/auth';
import { setLanguage } from '../../../utils';
import KycKeys from '../../../enums/kyc-enum';
import { newPepFieldsFeatureFlag } from '../../../config/feature-flags';

const JourneyTimelineRenewal = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [journeyTimeline, setJourneyTimeLine] = useState(timeLineRenewalConfig);
  const [orderKycId, setOrderKycId] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isKycSubmitted, setIsKycSubmitted] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isCidExpired, setIsCidExpired] = useState(false);
  const [customerRisk, setCustomerRisk] = useState(null);
  const [isCustomerRiskFetched, setIsCustomerRiskFetched] = useState(null);
  const [isTermsAndConditionOpened, setIsTermsAndConditionOpened] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isFinalLoading, setIsFinalLoading] = useState(false);

  const [isReturnedRequest, setIsReturnedRequest] = useState('');
  const [documentTabSkip, setDocumenTabSkip] = useState(
    location.state?.documentSkipForNow || false
  );

  const isAllStepsDone = areAllStepsDone(journeyTimeline);
  const titlePage = t('updateYourProfile');
  const subTitlePage = t('updateYourProfileDescription');

  const runRiskEngine = async () => {
    try {
      await postCustomerRisk(orderKycId);
      navigate(kycRenewalSummaryStepRoute);
    } catch (exception) {
      console.error(exception);
    } finally {
      setIsLoading(false);
    }
  };

  const navigateToNextPage = async availableOrderId => {
    if (isStepDoneById(journeyTimeline, COMPLETE_PROFILE_ID)) {
      navigate(
        {
          pathname: uploadAdditionalDocumentsRoute
        },
        {
          state: {
            orderId: availableOrderId
          }
        }
      );
    } else if (isStepDoneById(journeyTimeline, IDENTITY_VERIFICATION_ID) || !isCidExpired) {
      if (customerRisk === null) {
        try {
          await runRiskEngine();
        } catch (exception) {
          console.error(exception);
        }
      } else {
        navigate({
          pathname: kycRenewalSummaryStepRoute
        });
      }
    } else {
      navigate(
        {
          pathname: identityVerificationRenewalRoute
        },
        {
          state: {
            orderId: availableOrderId
          }
        }
      );
    }
  };

  const onClick = async () => {
    try {
      setIsSaving(true);

      navigateToNextPage(orderId);
    } catch (exception) {
      console.error(exception);
    }
  };

  const updateJourneyTimeLine = async () => {
    try {
      const {
        isCivilIdBackUploaded,
        isCivilIdFrontUploaded,
        isKycSubmitted,
        isReturnedRequest,
        clientRequestId,
        isDocumentsSubmitted,
        returnReason
      } = await getCustomerOrder(orderId);
      const { serializedData } = await (newPepFieldsFeatureFlag
        ? getKycDataNewVersion2(orderId, orderKycId)
        : getKycDataV2(orderId, orderKycId));

      const documents = await getAllCustomerDocuments(orderId);
      const filteredClientDocuments = documents.filter(doc =>
        documentTypesToBeFiltered.includes(doc.documentType)
      );
      if (clientRequestId && !isReturnedRequest) {
        navigate(applicationSubmittedRenewalRoute);
      }
      const currentDate = new Date();
      const customerCIDExpired =
        !serializedData[KycKeys.CIVIL_ID_EXPIRY_DATE].value ||
        new Date(serializedData[KycKeys.CIVIL_ID_EXPIRY_DATE].value) < currentDate
          ? true
          : false;

      setIsCidExpired(customerCIDExpired);
      setIsKycSubmitted(isKycSubmitted);
      dispatch(setKycSubmitted(isKycSubmitted));
      setIsReturnedRequest(isReturnedRequest);
      if (!customerCIDExpired) {
        setJourneyTimeLine(prevTimeline =>
          prevTimeline.filter(item => item.id !== IDENTITY_VERIFICATION_ID)
        );
      }

      const isCustomerHighRisk = customerRisk === customerRiskEnums.HIGH;
      const isReturnReasonSD =
        isReturnedRequest && returnReason === returnReasons.SUPPORTING_DOCUMENTS;

      if (isReturnReasonSD || (isReturnedRequest && isCustomerHighRisk)) {
        setJourneyTimeLine(prevTimeline =>
          prevTimeline.map(item =>
            item.id === UPLOAD_DOCUMENT_ID ? { ...item, optional: false } : item
          )
        );
      }

      if (!isCustomerHighRisk && !isReturnReasonSD) {
        setJourneyTimeLine(prevTimeline =>
          prevTimeline.filter(item => item.id !== UPLOAD_DOCUMENT_ID)
        );
      }

      if (isCivilIdBackUploaded && isCivilIdFrontUploaded) {
        setJourneyTimeLine(prevTimeline =>
          prevTimeline.map(item =>
            item.id === IDENTITY_VERIFICATION_ID ? { ...item, isDone: true } : item
          )
        );
      }

      if (isKycSubmitted) {
        setJourneyTimeLine(prevTimeline =>
          prevTimeline.map(item =>
            item.id === COMPLETE_PROFILE_ID ? { ...item, isDone: true } : item
          )
        );
      }

      if (
        (isDocumentsSubmitted &&
          filteredClientDocuments.find(doc => doc.id) &&
          !isReturnedRequest) ||
        (isDocumentsSubmitted && !filteredClientDocuments.find(doc => !doc.id) && isReturnedRequest)
      ) {
        setJourneyTimeLine(prevTimeline =>
          prevTimeline.map(item =>
            item.id === UPLOAD_DOCUMENT_ID ? { ...item, isDone: true } : item
          )
        );
      }

      if (
        isDocumentsSubmitted &&
        isReturnReasonSD &&
        !isCustomerHighRisk &&
        documents.find(doc => doc.id && doc.documentType === CLIENT_ADDITIONAL_FILE)
      ) {
        setJourneyTimeLine(prevTimeline =>
          prevTimeline.map(item =>
            item.id === UPLOAD_DOCUMENT_ID ? { ...item, isDone: true } : item
          )
        );
      }

      if (
        isDocumentsSubmitted &&
        !filteredClientDocuments.find(doc => doc.id) &&
        !isReturnedRequest
      ) {
        setDocumenTabSkip(true);
      }
    } catch (exception) {
      console.error(exception);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCustomerRisk = async () => {
    try {
      const { riskLevel } = await getCustomerRisk(orderKycId);
      setCustomerRisk(riskLevel);
      setIsCustomerRiskFetched(true);
    } catch (exception) {
      console.error(exception);
    }
  };

  const fetchCustomerData = async () => {
    try {
      setIsLoading(true);
      const customer = await getCustomer();

      setCustomer(customer);
      setOrderId(customer.orderId);
      setOrderKycId(customer.orderKycId);
    } catch (exception) {
      console.error(exception);
    }
  };

  useEffect(() => {
    fetchCustomerData();
  }, []);

  useEffect(() => {
    if (orderKycId) {
      fetchCustomerRisk();
    }
  }, [orderKycId]);

  useEffect(() => {
    if (orderId && isCustomerRiskFetched) {
      updateJourneyTimeLine();
    }
  }, [orderId, isCustomerRiskFetched]);

  useEffect(() => {
    if (isKycSubmitted) {
      dispatch(setIsAllStepsDone(isAllStepsDone));
    }
  }, [isKycSubmitted]);

  const toggleCheck = () => {
    setIsTermsChecked(!isTermsChecked);
  };

  const submitOrderToAdmin = async () => {
    try {
      setIsFinalLoading(true);
      await generateClientDocuments(orderId);
      await submitToAdmin(orderId);
      navigate(applicationSubmittedRenewalRoute);
    } catch (exception) {
      console.error(exception);
    } finally {
      setIsFinalLoading(false);
    }
  };

  const handleTermsAndConditionModalActions = () => {
    setIsTermsAndConditionOpened(!isTermsAndConditionOpened);
  };

  const renderSubmittingCta = () => (
    <div className={styles.ctaContainer}>
      <div className={styles.termsContainer}>
        <img
          src={isTermsChecked ? checkedBoxIcon : notCheckedBoxIcon}
          alt='check'
          onClick={toggleCheck}
          className={styles.checkBox}
          data-testid='check-box-icon'
        />

        <span className={styles.termsText1}>
          {t('checkTermsAndCondition')}{' '}
          <span className={styles.termsText2} onClick={handleTermsAndConditionModalActions}>
            {t('termsAndCondition')}
          </span>
        </span>
      </div>
      <PrimaryButton
        onClick={submitOrderToAdmin}
        disabled={isSaving || !isTermsChecked}
        data-testid='start'
      >
        {t('submitForReview')}
      </PrimaryButton>
      <span
        className={styles.reviewText}
        onClick={() => {
          navigate(kycRenewalSummaryStepRoute);
        }}
      >
        {t('review')}
      </span>
    </div>
  );

  const renderFooter = () => {
    if (
      isAllStepsDone ||
      (!isStepDoneById(journeyTimeline, UPLOAD_DOCUMENT_ID) && documentTabSkip)
    ) {
      return renderSubmittingCta();
    }

    return (
      <PrimaryButton onClick={onClick} disabled={isSaving} data-testid='start'>
        {t('continue')}
      </PrimaryButton>
    );
  };

  const showAdditionalDocumentBanner = () => {
    if (
      isAllStepsDone ||
      journeyTimeline.filter(item => item.id === UPLOAD_DOCUMENT_ID).length > 0
    ) {
      return <></>;
    }

    return (
      <Banner
        backgroundColor={'#EEF4FD'}
        textColor={'#7595F5'}
        text={t('additionalDocumentBannerText')}
        icon={alertIcon}
      />
    );
  };

  if (isLoading) {
    return <CircularLoader />;
  }

  if (isFinalLoading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <MainLayout
        title={titlePage}
        subTitle={subTitlePage}
        footer={renderFooter}
        isAllStepsDone={isAllStepsDone}
      >
        <div>
          <div className={styles.container}>
            <img src={clockIcon} alt='icon' />
            <span className={styles.estimatedTime}>{t('estimatedTime')}</span>
          </div>
          <div className={styles.timeLineContainer}>
            <TimeLine steps={journeyTimeline} documentTabSkip={documentTabSkip} />
          </div>
          {showAdditionalDocumentBanner()}
          {isTermsAndConditionOpened && (
            <TermsAndConditionsModal handleCloseClick={handleTermsAndConditionModalActions} />
          )}
        </div>
      </MainLayout>
    </>
  );
};

export default JourneyTimelineRenewal;
