import React from 'react';
import KycKeys from '../../../enums/kyc-enum';
import PropTypes from 'prop-types';
import { politicalPositionEnums } from '../../../enums/additional-info-enums';
import CustomSelect from '../../../widgets/inputs/custom-select';
import KYCValidator from '../../../helpers/validation';

const ClientPoliticalPositionCount = ({
  data,
  setKycData,
  translator,
  counterValue,
  setCounter = () => {}
}) => {
  const fieldKey = KycKeys.CLIENT_POLITIAL_POSITION_COUNT;
  const isClientPoliticalPositionKey = KycKeys.IS_CLIENT_POLITICAL_POSITION;
  const clientPoliticalPositionsKey = KycKeys.CLIENT_POLITICAL_POSITIONS;

  const {
    [fieldKey]: { value: clientPoliticalPositionCountValue, error },
    [isClientPoliticalPositionKey]: { value: isClientPoliticalPositionValue },
    [clientPoliticalPositionsKey]: { value: clientPoliticalPositionsList }
  } = data;

  const positionsCount = clientPoliticalPositionsList.length;

  const validateField = value => {
    const fieldErrorObject = KYCValidator.validateField(value);
    const currentList = clientPoliticalPositionsList || [];

    const updatedList = Array.from(
      { length: value },
      (_, index) =>
        currentList[index] || {
          [politicalPositionEnums.POSITION]: '',
          [politicalPositionEnums.STATUS]: ''
        }
    );

    setKycData(prev => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        error: fieldErrorObject.errorMessage,
        value
      },
      [clientPoliticalPositionsKey]: {
        ...prev[clientPoliticalPositionsKey],
        value: updatedList,
        error: ''
      }
    }));
  };

  const handleChange = value => {
    setCounter(counterValue + 1);
    validateField(value);
  };

  if (!isClientPoliticalPositionValue) {
    return;
  }

  const options = [
    { value: 1, label: '1' },
    { value: 2, label: '2' }
  ];

  return (
    <CustomSelect
      name={'pepCount'}
      inputOptions={options}
      label={translator('politicalPositionsNumberLabel')}
      onChange={handleChange}
      value={positionsCount}
      errorMessage={error}
    />
  );
};

ClientPoliticalPositionCount.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  optionalHandleChange: PropTypes.func,
  isSectionDisabled: PropTypes.bool,
  setCounter: PropTypes.func
};

export default ClientPoliticalPositionCount;
