import React, { useEffect, useState } from 'react';
import styles from './responsive-navbar.module.scss';
import drawerIcon from '../../assets/icons/drawer-icon.svg';
import { useLocation, useNavigate } from 'react-router';
import leftArrowIcon from '../../assets/icons/left-arrow-icon.svg';
import { useSelector } from 'react-redux';
import logo from '../../assets/icons/nbkwealth-logo.svg';
import investLogo from '../../assets/icons/invest.svg';
import brokerageLogo from '../../assets/icons/brokerage.svg';
import { isInvestEnv } from '../../config';
import {
  applicationSubmittedRenewalRoute,
  civildIDetailsRenewalRoute
} from '../../routes/routes.const';

const ResponsiveNavbar = ({ openModal }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isBackArrowVisible, setIsBackArrowVisible] = useState(false);
  const { hideNavigation, isAllStepsDone } = useSelector(state => state.general);

  useEffect(() => {
    const isBackButtonVisible =
      location.pathname !== '/' &&
      !isAllStepsDone &&
      location.pathname !== civildIDetailsRenewalRoute &&
      location.pathname !== applicationSubmittedRenewalRoute;
    setIsBackArrowVisible(isBackButtonVisible);
  }, [location, isAllStepsDone]);

  return (
    <div className={hideNavigation ? styles.navbarWithNoBorder : styles.navbar}>
      {hideNavigation ? (
        <></>
      ) : (
        <>
          <div
            className={styles.burgerIcon}
            onClick={() => {
              openModal();
            }}
            data-testid={'drarwe-id'}
          >
            <img src={drawerIcon} alt='Logo' />
          </div>
          {isInvestEnv ? (
            <div className={styles.logoContainer}>
              <img src={brokerageLogo} className={styles.brokerageLogo} />
              <img src={investLogo} className={styles.investLogo} />
            </div>
          ) : (
            <img src={logo} />
          )}
          <div style={{ width: 26 }}>
            {isBackArrowVisible ? (
              <img
                src={leftArrowIcon}
                alt='Logo'
                className={styles.leftArrowIcon}
                onClick={() => {
                  navigate(-1);
                }}
              />
            ) : (
              <></>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ResponsiveNavbar;
