export const localEn = {
  privacy: "Privacy Policy",
  disclosure: "Full Disclosure",
  copyright:
    "Copyright © 2024 Watani Investment Company K.S.C.C. All rights reserved.",
};

export const localAr = {
  privacy: "سياسة الخصوصية",
  disclosure: "الإفصاح الكامل",
  copyright: "حقوق النشر © 2024 الوطني للثروات. كل الحقوق محفوظة.",
};
