import React, { useEffect, useState } from "react";
import KYCValidator from "../../../helpers/validation";
import DatePicker from "../../../widgets/date-picker";
import KycKeys from "../../../enums/kyc-enum";
import PropTypes from "prop-types";
import moment from "moment";
import { countriesEnums } from "../../../enums/general";

const PassportExpiryDateRenewal = ({ data, setKycData, translator }) => {
  const fieldKey = KycKeys.PASSPORT_EXPIRY_DATE;
  const nationalityKey = KycKeys.NATIONALITY;

  const {
    [fieldKey]: { value, error, isDisabled },
    [nationalityKey]: { value: nationalityValue },
  } = data;

  const [date, setDate] = useState(
    value ? moment(value).format("DD/MM/YYYY") : ""
  );

  const isVisible =
    !!nationalityValue && nationalityValue !== countriesEnums.KUWAIT;

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validatePassportExpiryDate(value);
    const formattedDate = moment(value, "DD/MM/YYYY").format("YYYY-MM-DD");
    const isDateValid = moment(formattedDate, "YYYY-MM-DD").isValid();
    setDate(value);
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isValidated: true,
        error: fieldErrorObject.errorMessage,
        value: isDateValid ? formattedDate : "",
      },
    }));
  };

  const handleChange = (value) => {
    validateField(value);
  };

  const validateFieldOnLoad = () => {
    const fieldErrorObject = KYCValidator.validatePassportExpiryDate(date);
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        error: fieldErrorObject.errorMessage,
      },
    }));
  };

  useEffect(() => {
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isVisible: isVisible,
      },
    }));
  }, [isVisible]);

  useEffect(() => {
    if (!value) {
      setDate("");
    }
  }, [nationalityValue]);

  if (!isVisible) {
    return null;
  }

  return (
    <DatePicker
      name="passportExpiryDate"
      label={translator("passportExpiryDate")}
      onChange={handleChange}
      value={date}
      error={error}
      isDisabled={isDisabled}
    />
  );
};

PassportExpiryDateRenewal.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  translator: PropTypes.func.isRequired,
};

export default PassportExpiryDateRenewal;
