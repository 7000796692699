import React from 'react';
import MainLayout from '../../layouts/main-layout';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../widgets/buttons/primary-button';
import infoIcon from '../../assets/icons/info-icon.svg';
import styles from './not-renewal.module.scss';
import { webOnboardingLiveUrl, webOnboardingUatUrl } from '../../config/outside-urls';

const NotRenewal = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const env = process.env.REACT_APP_ENV;
  const renderFooter = () => {
    const envUrls = {
      'web-renewal-uat': webOnboardingUatUrl,
      'dev-web-renewal-uat': webOnboardingUatUrl,
      'web-renewal': webOnboardingLiveUrl
    };
    const onContinue = () => {
      const targetUrl = envUrls[env];
      targetUrl ? window.open(targetUrl) : navigate('/', { replace: true });
    };
    return (
      <PrimaryButton onClick={onContinue} data-testid={'continue'}>
        {t('continue')}
      </PrimaryButton>
    );
  };

  return (
    <MainLayout footer={renderFooter}>
      <div className={styles.container}>
        <img className={styles.imageStyle} src={infoIcon} alt='Success' />
        <span className={styles.title}>{t('onboardingCivilId')}</span>
        <span className={styles.subtitleMessage}>{t('onboardingCivilIDdescription')}</span>
      </div>
    </MainLayout>
  );
};

export default NotRenewal;
