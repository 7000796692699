import React, { useState, useRef, useEffect } from 'react';
import styles from './custom-select.module.scss';
import arrowDownIcon from '../../../assets/icons/arrow-down.svg';
import correctIcon from '../../../assets/icons/correct-icon.svg';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TooltipMessage from '../../tooltip-message';

const CustomSelect = ({
  isDisabled,
  label,
  onChange = () => {},
  value,
  errorMessage,
  inputOptions,
  isLabelOutsideInput,
  placeholder,
  name,
  tooltipIcon,
  tooltipMessage
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isSelectItemsAtBottom, setIsSelectItemsAtBottom] = useState(false);
  const { t } = useTranslation('common');
  const dropdownRef = useRef(null);
  const dropdownContentRef = useRef(null);

  const selectedOptionObj = inputOptions?.find(item => item.value === value);

  const toggleDropdown = () => {
    if (isDisabled) {
      return;
    }
    setIsActive(!isActive);
  };

  const handleChange = value => {
    onChange(value);
    setIsActive(!isActive);
  };

  const dropdownOptions = inputOptions?.map((entry, index) =>
    entry.disabled ? (
      <div key={index} style={{ display: 'hidden' }} />
    ) : (
      <div key={entry.value} className={styles.selectedOptionContainer}>
        <div
          data-testid={`${name}${entry.value}${name === 'bankName' ? '-1' : ''}`}
          onClick={() => handleChange(entry.value)}
          className={`${styles.dropdownItem} 
          ${value === entry.value ? styles.active : ''}`}
        >
          {entry.label}
        </div>
        {value === entry.value && <img src={correctIcon} alt='Selected option icon' />}
      </div>
    )
  );

  useEffect(() => {
    const handleClickOutside = e => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!dropdownRef.current) {
      return;
    }

    const handleResize = () => {
      if (!dropdownRef.current) {
        return;
      }

      const rect = dropdownRef?.current.getBoundingClientRect();
      const windowWidth = window.innerWidth;
      let windowHeight = window.innerHeight;

      if (windowWidth <= 700) {
        windowHeight = window.innerHeight - 98;
      }

      if (rect.bottom > windowHeight / 2) {
        setIsSelectItemsAtBottom(true);
      } else {
        setIsSelectItemsAtBottom(false);
      }
    };

    handleResize();
    window.addEventListener('scroll', handleResize);

    return () => {
      window.removeEventListener('scroll', handleResize);
    };
  }, [dropdownRef?.current]);

  return (
    <div className={styles.container} ref={dropdownRef}>
      <div className={styles.customSelectContainer}>
        {isLabelOutsideInput && (
          <label className={styles.fixedLabel}>
            {label}
            {tooltipIcon && <TooltipMessage message={tooltipMessage} icon={tooltipIcon} />}
          </label>
        )}
        <div
          data-testid={name} // TODO: This should be dynamic based on the field (Update Unit Tests)
          className={`${styles.dropdownButton} ${isActive ? styles.active : ''} 
            ${errorMessage ? styles.error : ''} ${isDisabled ? styles.disabled : ''}`}
          onClick={toggleDropdown}
        >
          {!isLabelOutsideInput && (
            <label
              className={`${styles.floatingLabel} ${value || value === 0 ? styles.active : ''} ${
                errorMessage ? styles.error : ''
              } ${isDisabled ? styles.disabled : ''}`}
            >
              {label}
            </label>
          )}

          <div className={styles.innerContent}>
            {(value || value === 0) && (
              <p className={styles.dropdownValue}>
                {selectedOptionObj ? selectedOptionObj.label : placeholder}
              </p>
            )}
            {!!placeholder && !value && <p className={styles.placeholder}>{placeholder}</p>}
            <img
              className={`${styles.arrowIcon} ${isDisabled ? styles.disabled : ''}`}
              src={arrowDownIcon}
              alt='Arrow Down Icon'
            />
            <div />
          </div>
        </div>

        <div
          ref={dropdownContentRef}
          className={styles.dropdownContent}
          style={{
            display: isActive ? 'block' : 'none',
            overflowY: inputOptions.length > 4 ? 'scroll' : 'auto',
            bottom: isSelectItemsAtBottom ? '50px' : 'auto',
            marginBottom: isSelectItemsAtBottom ? '10px' : '',
            top: !isSelectItemsAtBottom ? '100%' : 'auto'
          }}
        >
          {dropdownOptions}
        </div>
      </div>
      {errorMessage && <span className={styles.errorMessage}>{t(errorMessage)}</span>}
    </div>
  );
};

// CustomSelect.defaultProps = {
//   isDisabled: false,
//   errorMessage: "",
//   isLabelOutsideInput: false,
// };

CustomSelect.propTypes = {
  isDisabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  errorMessage: PropTypes.string,
  inputOptions: PropTypes.array.isRequired,
  isLabelOutsideInput: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string
};

export default CustomSelect;
