import React, { useEffect, useState } from "react";
import InputField from "../../../widgets/inputs/primary-input";
import infoIcon from "../../../assets/icons/info-icon.svg";
import civilIdInfo from "../../../assets/images/civil-id-info.svg";
import civilIdInfoArabic from "../../../assets/images/civil-id-info-arabic.svg";
import FieldsValidation from "../../../validation/fields-validation";
import OverlayModal from "../../../modals/overlay/overlay-modal";
import KycKeys from "../../../enums/kyc-enum";
import { setHideNavigation } from "../../../store/slices/general.slice";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const CivilIdField = ({ data, setData, setException }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("common");
  const { civilIDNumber } = useSelector((state) => state.general.userInfo);
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const [language, setLanguage] = useState("ar");

  const fieldKey = KycKeys.CIVIL_ID_NUMBER;
  const {
    [fieldKey]: { value, error },
  } = data;

  useEffect(() => {
    const language = i18n.language;
    setLanguage(language);
  }, [i18n, i18n.language]);

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      [fieldKey]: {
        value: civilIDNumber,
      },
    }));
  }, []);
  const handleIconClick = () => {
    dispatch(setHideNavigation(true));
    setOverlayVisible(true);
  };

  const handleCloseClick = () => {
    dispatch(setHideNavigation(false));
    setOverlayVisible(false);
  };

  const validateField = (value) => {
    setException("");
    const fieldErrorObject = FieldsValidation.validateCivilIdNumber(value);
    const errorMessageReceived = fieldErrorObject.errorMessage;
    setData((prev) => ({
      ...prev,
      [fieldKey]: {
        value,
        error: errorMessageReceived,
      },
    }));
  };

  const handleChange = (e) => {
    validateField(e.target.value);
  };

  const getImageVersion = () =>
    language === "en" ? civilIdInfo : civilIdInfoArabic;

  return (
    <>
      <InputField
        icon={infoIcon}
        inputadornment="end"
        onIconClick={handleIconClick}
        isDisabled={false}
        label={t("civilIdLabel")}
        onChange={handleChange}
        value={value}
        errorMessage={error}
        name={"civilId"}
        data-testid="civilIdField"
      />
      {isOverlayVisible && (
        <OverlayModal
          title={t("civilIdGuide")}
          fieldInfoImage={getImageVersion()}
          handleCloseClick={handleCloseClick}
        />
      )}
    </>
  );
};
export default CivilIdField;
