import React from 'react';
import Header from '../../components/privacy-disclosure-header';
import { privacyLocalesEn } from './localization/en';
import { Link } from 'react-router';
import Section from '../../components/Section';

import './style.scss';

const Privacy = () => {
  const local = privacyLocalesEn;
  return (
    <>
      <div id='privacy'>
        <Header />
        <div className='banner'>
          <div className='container'>
            <div className='title'>
              <p>{local.privacyTitle}</p>
            </div>
          </div>
        </div>
        <div className='sections-body container'>
          <Section title={local.introductionTitle}>
            <div className='section-content'>
              <div className='section-text'>{local.introductionDescription_1}</div>
              <div className='section-text'>{local.introductionDescription_2}</div>
              <div className='section-text'>
                {local.introductionDescription_3}
                <Link to={local.mailTo} className='link-item'>
                  {local.email}
                </Link>
                .
              </div>
            </div>
          </Section>
          <Section title={local.informationTitle}>
            <div className='section-content'>
              <div className='section-text'>{local.informationDescription}</div>
            </div>
          </Section>
          <Section title={local.visitorTitle}>
            <div className='section-content'>
              <div className='section-text'>{local.visitorDescription}</div>
            </div>
          </Section>
          <Section title={local.customerTitle}>
            <div className='section-content'>
              <div className='section-text'>{local.customerDescription_1}</div>
              <div className='section-text'>{local.customerDescription_2}</div>
            </div>
          </Section>
          <Section title={local.collectInformationTitle}>
            <div className='section-content'>
              <div className='section-text'>{local.collectInformationDescription}</div>
            </div>
          </Section>
          <Section title={local.cookiesTitle}>
            <div className='section-content'>
              <div className='section-text'>{local.cookiesDescription_1}</div>
              <div className='section-text'>{local.cookiesDescription_2}</div>
              <div className='section-text'>{local.cookiesDescription_3}</div>
            </div>
          </Section>
          <Section title={local.advertizingTitle}>
            <div className='section-content'>
              <div className='section-text'>{local.advertizingDescription_1}</div>
              <div className='section-text'>
                {local.advertizingDescription_2}
                <Link to={local.naiOptPageLink} className='link-item' target='_blank'>
                  {local.naiOptPage}
                </Link>
                {local.advertizingDescription_3}
              </div>
              <div className='section-text'>
                {local.advertizingDescription_4}
                <Link to={local.naiOptPageLink} className='link-item' target='_blank'>
                  {local.naiOptPage}
                </Link>
                {local.advertizingDescription_5}
              </div>
              <div className='section-text'>{local.advertizingDescription_6}</div>
            </div>
          </Section>
          <Section title={local.analyticsTitle}>
            <div className='section-content'>
              <div className='section-text'>{local.analyticsDescription_1}</div>
              <div className='section-text'>{local.analyticsDescription_2}</div>
              <div className='section-text'>
                {local.analyticsDescription_3}
                <Link to={local.analyticsLink} className='link-item' target='_blank'>
                  {local.here}
                </Link>
                .
              </div>
            </div>
          </Section>
          <div className='section'>
            <div className='section-title-big'>{local.otherImportantInformation}</div>
          </div>
          <Section title={local.formerCustomerTitle}>
            <div className='section-content'>
              <div className='section-text'>{local.formerCustomerDescription}</div>
            </div>
          </Section>
          <Section title={local.acceptPolicyTitle}>
            <div className='section-content'>
              <div className='section-text'>{local.acceptPolicyDescription}</div>
            </div>
          </Section>
          <Section title={local.changePolicyTitle}>
            <div className='section-content'>
              <div className='section-text'>{local.changePolicyDescription}</div>
            </div>
          </Section>
          <Section title={local.childrenTitle}>
            <div className='section-content'>
              <div className='section-text'>
                {local.childrenDescription_1}
                <Link to={local.mailTo} className='link-item'>
                  {local.supportEmail}
                </Link>
                {local.childrenDescription_2}
              </div>
            </div>
          </Section>
          <Section title={local.questionsTitle}>
            <div className='section-content'>
              <div className='section-text'>
                {local.questionsDescription}
                <br />
                <br />
                {local.location_1}
                <br />
                {local.location_2}
                <br />
                {local.location_3}
                <br />
                {local.location_4}
                <br />
                {local.tel}
                <br />
                {local.fax}
              </div>
            </div>
          </Section>
        </div>
      </div>
    </>
  );
};

export default Privacy;
