import React from 'react';
import KYCValidator from '../../../helpers/validation';
import CustomSelect from '../../../widgets/inputs/custom-select';
import KycKeys from '../../../enums/kyc-enum';
import { convertBooleanToYesNo, convertYesNoToBoolean } from '../../../helpers';
import PropTypes from 'prop-types';
import { createClientPoliticalPositionList } from '../../../helpers/general-helpers';

const ClientPoliticalPosition = ({
  data,
  setKycData,
  translator,
  kycFields,
  counterValue,
  setCounter = () => {}
}) => {
  const fieldKey = KycKeys.IS_CLIENT_POLITICAL_POSITION;
  const clientPoliticalPositionsKey = KycKeys.CLIENT_POLITICAL_POSITIONS;

  const {
    [fieldKey]: { value, error }
  } = data;

  const { yes_no: yesOrNo } = kycFields;

  const clientPoliticalPositionValue = convertBooleanToYesNo(value);

  const options = Object.keys(yesOrNo)?.map(key => ({
    label: yesOrNo[key],
    value: key
  }));

  const validateField = value => {
    const isPoliticalExistingValue = convertYesNoToBoolean(value);
    const clientPoliticalPositionList = createClientPoliticalPositionList(
      isPoliticalExistingValue,
      1
    );
    const fieldErrorObject = KYCValidator.validateField(value);

    setKycData(prev => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        error: fieldErrorObject.errorMessage,
        value: convertYesNoToBoolean(value)
      },
      [clientPoliticalPositionsKey]: {
        ...prev[clientPoliticalPositionsKey],
        value: clientPoliticalPositionList,
        error: '',
        isVisible: isPoliticalExistingValue ? true : false
      }
    }));
  };

  const handleChange = value => {
    setCounter(counterValue + 1);
    validateField(value);
  };

  return (
    <CustomSelect
      name={'areYouPepClient'}
      inputOptions={options}
      label={translator('isClientPep')}
      onChange={handleChange}
      value={clientPoliticalPositionValue}
      errorMessage={error}
      isLabelOutsideInput
      placeholder={translator('selectYesNoOptions')}
    />
  );
};

ClientPoliticalPosition.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  translator: PropTypes.func.isRequired,
  kycFields: PropTypes.object.isRequired,
  setCounter: PropTypes.func
};

export default ClientPoliticalPosition;
