import React, { useState, useEffect } from 'react';
import kycFields from '../../../config/kyc';
import Country from '../components/country';
import KycKeys from '../../../enums/kyc-enum';
import { useSelector } from 'react-redux';
import City from '../components/city';
import Area from '../components/area';
import Block from '../components/block';
import Street from '../components/street';
import House from '../components/house';
import StepEnums from '../../../enums/step-enum';
import {
  getKycDataV2,
  putKycDataV2,
  getCustomer,
  postCustomerRisk,
  getKycDataNewVersion2,
  putKycDataV3,
  getCustomerOrderValidationV3
} from '../../../general-services.proxy';
import { useNavigate } from 'react-router';
import SkeletonLoader from '../../../widgets/fields-skeleton';
import {
  employmentRenewalInfoStepRoute,
  kycRenewalSummaryStepRoute
} from '../../../routes/routes.const';
import MainLayout from '../../../layouts/main-layout';
import { scrollToTop } from '../../../utils';
import { useTranslation } from 'react-i18next';
import { calculateKycProgress } from '../../../helpers';
import KycStepFooter from '../../../components/kyc-step-footer';
import { commonValidations } from '../../../utils/validation/validation-messages';
import { getCustomerOrderValidation } from '../../../general-services.proxy';
import { newPepFieldsFeatureFlag } from '../../../config/feature-flags';

const ContactRenewalInfoStep = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const [kycData, setKycData] = useState(kycFields);
  const { countries, cities } = useSelector(state => state.general);
  const [isLoading, setLoading] = useState(true);
  const [orderId, setOrderId] = useState('');
  const [orderKycId, setOrderKycId] = useState('');
  const [kycProgress, setKycProgress] = useState(0);

  const handleNavigation = () => {
    navigate({
      pathname: employmentRenewalInfoStepRoute
    });
  };

  const runRiskEngine = async () => {
    try {
      setLoading(true);
      await postCustomerRisk(orderKycId);
    } catch (exception) {
      console.error(exception);
    } finally {
      setLoading(false);
    }
  };

  const saveKycData = async () => {
    try {
      setLoading(true);
      await (newPepFieldsFeatureFlag
        ? putKycDataV3(orderId, kycData, StepEnums.CONTACT_INFO_STEP)
        : putKycDataV2(orderId, kycData, StepEnums.CONTACT_INFO_STEP));

      await runRiskEngine();
      handleNavigation();
    } catch (exception) {
      console.error(exception);
    } finally {
      setLoading(false);
    }
  };

  const handleNextActionClick = () => {
    saveKycData();
  };

  const saveKycDataAndNavigate = async () => {
    try {
      setLoading(true);
      await (newPepFieldsFeatureFlag
        ? putKycDataV3(orderId, kycData, StepEnums.CONTACT_INFO_STEP)
        : putKycDataV2(orderId, kycData, StepEnums.CONTACT_INFO_STEP));

      await runRiskEngine();
      navigate({
        pathname: kycRenewalSummaryStepRoute
      });
    } catch (exception) {
      console.error(exception);
    } finally {
      setLoading(false);
    }
  };
  const handleNextActionClickToSummary = () => {
    saveKycDataAndNavigate();
  };

  const fetchKycData = async () => {
    try {
      setLoading(true);
      const { orderId, orderKycId, mobileNumber, email } = await getCustomer();
      const missedFields = await (newPepFieldsFeatureFlag
        ? getCustomerOrderValidationV3(orderId, orderKycId)
        : getCustomerOrderValidation(orderId, orderKycId));

      setOrderId(orderId);
      setOrderKycId(orderKycId);

      const contactInfoMissedFields = missedFields.validations.contactInfo.filter(
        field => field !== 'country'
      );

      const { serializedData: data } = await (newPepFieldsFeatureFlag
        ? getKycDataNewVersion2(orderId, orderKycId)
        : getKycDataV2(orderId, orderKycId));
      const dataClone = structuredClone(data);
      contactInfoMissedFields.forEach(field => {
        if (dataClone[field]) {
          dataClone[field].error = t(commonValidations.required);
        }
      });
      setKycData(dataClone);
      setKycData(prev => ({
        ...prev,
        [KycKeys.EMAIL]: {
          ...prev[KycKeys.EMAIL],
          value: email
        },
        [KycKeys.CONTACT_MOBILE_NUMBER]: {
          ...prev[KycKeys.CONTACT_MOBILE_NUMBER],
          value: mobileNumber
        }
      }));
    } catch (exception) {
      console.error(exception);
    } finally {
      setLoading(false);
      scrollToTop();
    }
  };

  useEffect(() => {
    const progress = calculateKycProgress(kycData);
    setKycProgress(progress);
  }, [kycData]);

  const isContinueDisabled = Object.keys(kycData)
    .filter(key => kycData[key].step === StepEnums.CONTACT_INFO_STEP && kycData[key].isVisible)
    .some(
      key =>
        (!kycData[key].isOptional && !kycData[key].value && kycData[key].value !== false) ||
        kycData[key].error
    );

  useEffect(() => {
    fetchKycData();
    return () => {
      setKycData(kycFields);
    };
  }, []);

  const renderFooter = () => (
    <KycStepFooter
      firstButtonTitle={t('continueEditing')}
      firstButtonAction={handleNextActionClick}
      secondButtonTitle={t('saveAndContinueToSummary')}
      secondButtonAction={handleNextActionClickToSummary}
      firstButtonDisabled={isContinueDisabled || isLoading}
      secondButtonDisabled={isContinueDisabled || isLoading}
    />
  );

  return (
    <MainLayout
      stepTitle={t('contactInfo')}
      footer={renderFooter}
      stepNumber={kycProgress}
      isShowStepNumber={false}
      isKycRenewal
    >
      {isLoading ? (
        <SkeletonLoader numberOfFields={8} />
      ) : (
        <>
          <Country
            data={{
              [KycKeys.COUNTRY]: kycData[KycKeys.COUNTRY]
            }}
            setData={setKycData}
            countries={countries}
            translator={t}
          />
          <City
            data={{
              [KycKeys.ADDRESS_CITY]: kycData[KycKeys.ADDRESS_CITY]
            }}
            setData={setKycData}
            cities={cities}
            translator={t}
          />
          <Area
            data={{
              [KycKeys.ADDRESS_AREA]: kycData[KycKeys.ADDRESS_AREA],
              [KycKeys.ADDRESS_CITY]: kycData[KycKeys.ADDRESS_CITY]
            }}
            setData={setKycData}
            cities={cities}
            translator={t}
          />
          <Block
            data={{
              [KycKeys.ADDRESS_BLOCK]: kycData[KycKeys.ADDRESS_BLOCK]
            }}
            setData={setKycData}
            translator={t}
          />
          <Street
            data={{
              [KycKeys.ADDRESS_STREET]: kycData[KycKeys.ADDRESS_STREET]
            }}
            setData={setKycData}
            translator={t}
          />
          <House
            data={{
              [KycKeys.ADDRESS_HOUSE]: kycData[KycKeys.ADDRESS_HOUSE]
            }}
            setData={setKycData}
            translator={t}
          />
          {/* <PhoneNumber
            data={{
              [KycKeys.CONTACT_MOBILE_NUMBER]:
                kycData[KycKeys.CONTACT_MOBILE_NUMBER],
              [KycKeys.COUNTRY_CODE]: kycData[KycKeys.COUNTRY_CODE],
            }}
            setData={setKycData}
          /> */}
          {/* <Email
            data={{
              [KycKeys.EMAIL]: kycData[KycKeys.EMAIL],
            }}
            setData={setKycData}
            translator={t}
          /> */}
        </>
      )}
    </MainLayout>
  );
};

export default ContactRenewalInfoStep;
