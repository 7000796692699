import React from 'react';
import successIcon from '../../../assets/icons/sucess-icon.svg';
import { useTranslation } from 'react-i18next';
import InfoContainer from '../../../widgets/info-container';
import MainLayout from '../../../layouts/main-layout';
import { civildIDetailsRenewalRoute } from '../../../routes/routes.const';
import { useNavigate } from 'react-router';
import PrimaryButton from '../../../widgets/buttons/primary-button';
import { useDispatch } from 'react-redux';
import { resetStateOnLogout } from '../../../store/slices/general.slice';

const ApplicationSubmittedRenewal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const renderFooter = () => {
    const onContinue = () => {
      sessionStorage.clear();
      dispatch(resetStateOnLogout());
      navigate(civildIDetailsRenewalRoute, {
        replace: true
      });
    };
    return (
      <PrimaryButton onClick={onContinue} data-testid='done'>
        {t('doneLabel')}
      </PrimaryButton>
    );
  };
  return (
    <MainLayout footer={renderFooter}>
      <InfoContainer
        icon={successIcon}
        title={t('profileUnderReview')}
        subTitle={t('profileunderReviewDescription')}
      />
    </MainLayout>
  );
};

export default ApplicationSubmittedRenewal;
