import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MainLayout from '../../../layouts/main-layout';
import PrimaryButton from '../../../widgets/buttons/primary-button';
import EmailAddressField from '../fields/email-address';
import ConfirmEmailAddressField from '../fields/confirm-email-address';
import '../../../../src/theme/spacing.scss';
import styles from '../email-address-details.module.scss';
import emailAddressDetailsData from '../config';
import KycKeys from '../../../enums/kyc-enum';
import { useNavigate } from 'react-router';
import { journeyTimeLineRenewalRoute, journeyTimeLineRoute } from '../../../routes/routes.const';
import { setToken } from '../../../utils/auth.js';
import { setLanguage } from '../../../utils/index.js';
import { useTranslation } from 'react-i18next';
import { setUserInfo } from '../../../store/slices/general.slice';
import { putEmail } from '../../../general-services.proxy.js';

const EmailAddressDetailsRenewal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const { userInfo } = useSelector(state => state.general);
  const [emailFields, setEmailFields] = useState(emailAddressDetailsData);

  const isContinueDisabled = Object.keys(emailFields).some(
    key => !emailFields[key].value || emailFields[key].error
  );

  const {
    [KycKeys.EMAIL]: { value: emailAddressValue },
    [KycKeys.EMAIL_CONFIRM]: { value: confirmEmailAddressValue }
  } = emailFields;

  const onContinue = async () => {
    dispatch(
      setUserInfo({
        ...userInfo,
        emailAddress: emailAddressValue,
        confirmEmailAddress: confirmEmailAddressValue
      })
    );
    try {
      await putEmail(emailFields[KycKeys.EMAIL].value);
      navigate(journeyTimeLineRenewalRoute, {
        replace: 'true'
      });
    } catch (exception) {
      console.error(exception);
    }
  };

  const renderFooter = () => (
    <PrimaryButton onClick={onContinue} disabled={isContinueDisabled} data-testid='continue'>
      {t('continue')}
    </PrimaryButton>
  );

  return (
    <MainLayout
      title={t('enterEmailAddress')}
      subTitle={t('informationForEmail')}
      footer={renderFooter}
    >
      <div className={styles.container}>
        <EmailAddressField
          data={{
            [KycKeys.EMAIL]: emailFields[KycKeys.EMAIL],
            [KycKeys.EMAIL_CONFIRM]: emailFields[KycKeys.EMAIL_CONFIRM]
          }}
          setData={setEmailFields}
        />
        <ConfirmEmailAddressField
          data={{
            [KycKeys.EMAIL]: emailFields[KycKeys.EMAIL],
            [KycKeys.EMAIL_CONFIRM]: emailFields[KycKeys.EMAIL_CONFIRM]
          }}
          setData={setEmailFields}
        />
      </div>
    </MainLayout>
  );
};
export default EmailAddressDetailsRenewal;
