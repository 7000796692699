import React, { useEffect, useState } from 'react';
import Footer from '../../components/footer';
import MobileLanding from './components/MobileLanding';
import DesktopLanding from './components/DesktopLanding';
import Images from '../../assets/images';

import './style.scss';

const LandingInvest = () => {
  const [isMobile, setIsMobile] = useState(false);

  const checkWindowSize = () => {
    setIsMobile(window.innerWidth < 992);
  };

  useEffect(() => {
    checkWindowSize();
    window.addEventListener('resize', checkWindowSize);
    return () => {
      window.removeEventListener('resize', checkWindowSize);
    };
  }, []);

  return (
    <>
      <div id='landing' style={{ backgroundImage: `url(${Images.MainBackground})` }}>
        {isMobile ? <MobileLanding /> : <DesktopLanding />}
        <Footer />
      </div>
    </>
  );
};

export default LandingInvest;
