import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import FirstName from '../components/first-name';
import FamilyName from '../components/family-name';
import MiddleName from '../components/middle-name';
import MotherFirstName from '../components/mother-first-name';
import MotherMiddleName from '../components/mother-middle-name';
import MotherLastName from '../components/mother-last-name';
import KycKeys from '../../../enums/kyc-enum';
import kycFields from '../../../config/kyc';
import StepEnums from '../../../enums/step-enum';
import Gender from '../components/gender';
import DateOfBirth from '../components/dob';
import Nationality from '../components/nationality';
import PassportNumber from '../components/passport-number';
import PassportCountry from '../components/passport-country';
import BirthCountry from '../components/birth-country';
import CityTownBirth from '../components/city-town-birth';
import CivilIdNumber from '../components/civil-id-number';
import FatherMiddleName from '../components/father-middle-name';
import FatherLastName from '../components/father-last-name';
import FatherFirstName from '../components/father-first-name';
import MaritalStatus from '../components/marital-status';
import SpouseName from '../components/spouse-name';
import ChildrenNumber from '../components/children-number';
import ChildrenDetails from '../components/children-details';
import OtherNationalities from '../components/other-nationalities';
import {
  contactRenewalInfoStepRoute,
  kycRenewalSummaryStepRoute
} from '../../../routes/routes.const';
import {
  putKycDataV2,
  getCustomer,
  getKycDataV2,
  postCustomerRisk,
  getKycDataNewVersion2,
  putKycDataV3,
  getCustomerOrderValidationV3
} from '../../../general-services.proxy';
import SkeletonLoader from '../../../widgets/fields-skeleton';
import MainLayout from '../../../layouts/main-layout';
import { scrollToTop } from '../../../utils';
import { useTranslation } from 'react-i18next';
import CivilIdSerialRenewalNumber from '../components/civil-id-serial-number-renewal';
import { calculateKycProgress } from '../../../helpers';
import { updateChildrensNamesInfo, validateExpiryDates } from '../../../helpers/general-helpers';
import CivilIdExpiryDateRenewal from '../components/civil-id-expiry-date-renewal';
import PassportExpiryDateRenewal from '../components/passport-expiry-date-renewal';
import KycStepFooter from '../../../components/kyc-step-footer';
import { commonValidations, dateValidations } from '../../../utils/validation/validation-messages';
import { getCustomerOrderValidation } from '../../../general-services.proxy';
import { newPepFieldsFeatureFlag } from '../../../config/feature-flags';

const PersonalRenewalInfoStep = () => {
  const { t } = useTranslation('common');

  const navigate = useNavigate();
  const stepName = StepEnums.PERSONAL_INFO_STEP;
  const [kycData, setKycData] = useState(kycFields);
  const [isLoading, setLoading] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [orderKycId, setOrderKycId] = useState('');
  const [counter, setCounter] = useState(1);

  const { kycFields: kycFieldsOptions, countries } = useSelector(state => state.general);

  const { gender: genderOptions = {}, relationship_status: relationshipStatus = {} } =
    kycFieldsOptions;

  const childrenNumber = kycData[KycKeys.CHILDREN_NUMBER].value;
  const childrenDetailsData = kycData[KycKeys.CHILDREN_DETAILS].value;
  const childrenDetailsDataFilled = childrenDetailsData.every(
    item => Object.values(item).every(value => value !== '') && !item.error
  );

  const [kycProgress, setKycProgress] = useState(0);
  const isContinueDisabled = Object.keys(kycData)
    .filter(
      key => kycData[key].step === stepName && kycData[key].isVisible && !kycData[key].isDisabled
    )
    .some(
      key =>
        (!kycData[key].value && kycData[key].value !== false && kycData[key].value !== 0) ||
        (Number(childrenNumber) > 0 && !childrenDetailsDataFilled) ||
        kycData[key].error
    );

  const handleNavigation = () => {
    navigate({
      pathname: contactRenewalInfoStepRoute
    });
  };

  const runRiskEngine = async () => {
    try {
      setLoading(true);
      await postCustomerRisk(orderKycId);
    } catch (exception) {
      console.error(exception);
    } finally {
      setLoading(false);
    }
  };

  const saveKycData = async () => {
    try {
      setLoading(true);
      await (newPepFieldsFeatureFlag
        ? putKycDataV3(orderId, kycData, StepEnums.PERSONAL_INFO_STEP)
        : putKycDataV2(orderId, kycData, StepEnums.PERSONAL_INFO_STEP));

      await runRiskEngine();
      handleNavigation();
    } catch (exception) {
      console.error(exception);
    } finally {
      setLoading(false);
    }
  };

  const saveKycDataAndNavigate = async () => {
    try {
      setLoading(true);
      await (newPepFieldsFeatureFlag
        ? putKycDataV3(orderId, kycData, StepEnums.PERSONAL_INFO_STEP)
        : putKycDataV2(orderId, kycData, StepEnums.PERSONAL_INFO_STEP));

      await runRiskEngine();
      navigate({
        pathname: kycRenewalSummaryStepRoute
      });
    } catch (exception) {
      console.error(exception);
    } finally {
      setLoading(false);
    }
  };

  const handleNextActionClick = () => {
    saveKycData();
  };

  const handleNextActionClickToSummary = () => {
    saveKycDataAndNavigate();
  };

  const fetchCustomerData = async () => {
    try {
      setLoading(true);
      const { orderId, orderKycId } = await getCustomer();
      const missedFields = await (newPepFieldsFeatureFlag
        ? getCustomerOrderValidationV3(orderId, orderKycId)
        : getCustomerOrderValidation(orderId, orderKycId));
      setOrderKycId(orderKycId);
      setOrderId(orderId);

      const personalInfoMissedFields = missedFields.validations.personalInfo;
      const { serializedData: data } = await (newPepFieldsFeatureFlag
        ? getKycDataNewVersion2(orderId, orderKycId)
        : getKycDataV2(orderId, orderKycId));

      const dataClone = structuredClone(data);
      validateExpiryDates(data, missedFields);
      personalInfoMissedFields.forEach(field => {
        if (field === KycKeys.CIVIL_ID_EXPIRY_DATE && dataClone[field].value) {
          dataClone[field].error = dateValidations.expired;
        } else if (field === KycKeys.PASSPORT_EXPIRY_DATE && dataClone[field].value) {
          dataClone[field].error = dateValidations.expiredPassport;
        } else if (dataClone[field]) {
          dataClone[field].error = commonValidations.required;
        }
      });
      updateChildrensNamesInfo(
        dataClone,
        dataClone[KycKeys.CHILDREN_DETAILS].value,
        dataClone[KycKeys.CHILDREN_NUMBER].value
      );
      setKycData(dataClone);
    } catch (exception) {
      console.error(exception);
    } finally {
      setLoading(false);
      scrollToTop();
    }
  };

  useEffect(() => {
    const progress = calculateKycProgress(kycData);
    setKycProgress(progress);
  }, [kycData]);
  const renderFooter = () => (
    <KycStepFooter
      firstButtonTitle={t('continueEditing')}
      firstButtonAction={handleNextActionClick}
      secondButtonTitle={t('saveAndContinueToSummary')}
      secondButtonAction={handleNextActionClickToSummary}
      firstButtonDisabled={isContinueDisabled || isLoading}
      secondButtonDisabled={isContinueDisabled || isLoading}
    />
  );

  useEffect(() => {
    fetchCustomerData();
    return () => {
      setKycData(kycFields);
    };
  }, []);

  return (
    <MainLayout
      stepTitle={t('personalInfoStepTitle')}
      footer={renderFooter}
      stepNumber={kycProgress}
      isShowStepNumber={false}
      isKycRenewal
    >
      {isLoading ? (
        <SkeletonLoader numberOfFields={15} />
      ) : (
        <>
          <Gender
            data={{
              [KycKeys.GENDER]: kycData[KycKeys.GENDER]
            }}
            setKycData={setKycData}
            kycFieldOptions={{
              genderOptions: genderOptions
            }}
            translator={t}
          />
          <FirstName
            data={{
              [KycKeys.FIRST_NAME]: {
                ...kycData[KycKeys.FIRST_NAME],
                isDisabled: true
              }
            }}
            setKycData={setKycData}
            translator={t}
          />
          <MiddleName
            data={{
              [KycKeys.MIDDLE_NAME]: kycData[KycKeys.MIDDLE_NAME]
            }}
            setKycData={setKycData}
            translator={t}
          />
          <FamilyName
            data={{
              [KycKeys.FAMILY_NAME]: {
                ...kycData[KycKeys.FAMILY_NAME],
                isDisabled: true
              }
            }}
            setKycData={setKycData}
            translator={t}
          />
          <DateOfBirth
            data={{
              [KycKeys.BIRTH_DATE]: kycData[KycKeys.BIRTH_DATE]
            }}
            setKycData={setKycData}
            translator={t}
          />
          <Nationality
            data={{
              [KycKeys.NATIONALITY]: {
                ...kycData[KycKeys.NATIONALITY],
                isDisabled: true
              },
              [KycKeys.OTHER_NATIONALITIES]: kycData[KycKeys.OTHER_NATIONALITIES]
            }}
            setKycData={setKycData}
            countries={countries}
            translator={t}
          />

          <PassportNumber
            data={{
              [KycKeys.PASSPORT_NUMBER]: kycData[KycKeys.PASSPORT_NUMBER],
              [KycKeys.NATIONALITY]: kycData[KycKeys.NATIONALITY]
            }}
            setKycData={setKycData}
            translator={t}
          />
          <PassportCountry
            data={{
              [KycKeys.PASSPORT_COUNTRY]: kycData[KycKeys.PASSPORT_COUNTRY],
              [KycKeys.NATIONALITY]: kycData[KycKeys.NATIONALITY]
            }}
            setKycData={setKycData}
            translator={t}
            countries={countries}
          />
          <PassportExpiryDateRenewal
            data={{
              [KycKeys.PASSPORT_EXPIRY_DATE]: kycData[KycKeys.PASSPORT_EXPIRY_DATE],
              [KycKeys.NATIONALITY]: kycData[KycKeys.NATIONALITY]
            }}
            setKycData={setKycData}
            translator={t}
          />
          <OtherNationalities
            data={{
              [KycKeys.OTHER_NATIONALITIES]: kycData[KycKeys.OTHER_NATIONALITIES],
              [KycKeys.NATIONALITY]: kycData[KycKeys.NATIONALITY]
            }}
            setKycData={setKycData}
            countries={countries}
            translator={t}
          />
          <BirthCountry
            data={{
              [KycKeys.BIRTH_COUNTRY]: kycData[KycKeys.BIRTH_COUNTRY]
            }}
            setKycData={setKycData}
            translator={t}
            countries={countries}
          />
          <CityTownBirth
            data={{
              [KycKeys.CITY_TOWN_BIRTH]: kycData[KycKeys.CITY_TOWN_BIRTH]
            }}
            setKycData={setKycData}
            translator={t}
          />
          <CivilIdNumber
            data={{
              [KycKeys.CIVIL_ID_NUMBER]: kycData[KycKeys.CIVIL_ID_NUMBER]
            }}
            translator={t}
          />
          <CivilIdSerialRenewalNumber
            data={{
              [KycKeys.CIVIL_ID_SERIAL]: kycData[KycKeys.CIVIL_ID_SERIAL]
            }}
            setKycData={setKycData}
            translator={t}
          />
          <CivilIdExpiryDateRenewal
            data={{
              [KycKeys.CIVIL_ID_EXPIRY_DATE]: kycData[KycKeys.CIVIL_ID_EXPIRY_DATE]
            }}
            setKycData={setKycData}
            translator={t}
          />
          <FatherFirstName
            data={{
              [KycKeys.FATHER_FIRST_NAME]: kycData[KycKeys.FATHER_FIRST_NAME]
            }}
            setKycData={setKycData}
            translator={t}
          />
          <FatherMiddleName
            data={{
              [KycKeys.FATHER_MIDDLE_NAME]: kycData[KycKeys.FATHER_MIDDLE_NAME]
            }}
            setKycData={setKycData}
            translator={t}
          />
          <FatherLastName
            data={{
              [KycKeys.FATHER_LAST_NAME]: kycData[KycKeys.FATHER_LAST_NAME]
            }}
            setKycData={setKycData}
            translator={t}
          />
          <MotherFirstName
            data={{
              [KycKeys.MOTHER_FIRST_NAME]: kycData[KycKeys.MOTHER_FIRST_NAME],
              [KycKeys.MOTHER_NAME]: kycData[KycKeys.MOTHER_NAME]
            }}
            setKycData={setKycData}
            translator={t}
          />
          <MotherMiddleName
            data={{
              [KycKeys.MOTHER_MIDDLE_NAME]: kycData[KycKeys.MOTHER_MIDDLE_NAME]
            }}
            setKycData={setKycData}
            translator={t}
          />
          <MotherLastName
            data={{
              [KycKeys.MOTHER_LAST_NAME]: kycData[KycKeys.MOTHER_LAST_NAME]
            }}
            setKycData={setKycData}
            translator={t}
          />
          <MaritalStatus
            data={{
              [KycKeys.MARRIAGE_STATUS]: kycData[KycKeys.MARRIAGE_STATUS],
              [KycKeys.RELATIONSHIP_STATUS]: kycData[KycKeys.RELATIONSHIP_STATUS]
            }}
            setKycData={setKycData}
            translator={t}
            kycFieldOptions={{
              relationshipStatus: relationshipStatus
            }}
          />
          <SpouseName
            data={{
              [KycKeys.PARTNER_NAME]: kycData[KycKeys.PARTNER_NAME],
              [KycKeys.MARRIAGE_STATUS]: kycData[KycKeys.MARRIAGE_STATUS]
            }}
            setKycData={setKycData}
            translator={t}
          />
          <ChildrenNumber
            data={{
              [KycKeys.CHILDREN_NUMBER]: kycData[KycKeys.CHILDREN_NUMBER],
              [KycKeys.CHILDREN_DETAILS]: kycData[KycKeys.CHILDREN_DETAILS]
            }}
            setKycData={setKycData}
            translator={t}
            counterValue={counter}
            setCounter={setCounter}
          />
          <ChildrenDetails
            data={{
              [KycKeys.CHILDREN_DETAILS]: kycData[KycKeys.CHILDREN_DETAILS],
              [KycKeys.CHILDREN_NUMBER]: kycData[KycKeys.CHILDREN_NUMBER]
            }}
            setKycData={setKycData}
            translator={t}
            isRenewal
            counterValue={counter}
          />
        </>
      )}
    </MainLayout>
  );
};

export default PersonalRenewalInfoStep;
