import React, { useState, useEffect } from "react";
import InputField from "../../../widgets/inputs/primary-input";
import KycKeys from "../../../enums/kyc-enum";
import PropTypes from "prop-types";
import civilIdSerialInfo from "../../../assets/images/civil-id-serial-number.svg";
import civilIdSerialInfoArabic from "../../../assets/images/civil-id-serial-number-arabic.svg";
import { useTranslation } from "react-i18next";
import OverlayModal from "../../../modals/overlay/overlay-modal";
import infoIcon from "../../../assets/icons/info-icon.svg";
import FieldsValidation from "../../../validation/fields-validation";

const CivilIdSerialRenewalNumber = ({ data, translator, setKycData }) => {
  const fieldKey = KycKeys.CIVIL_ID_SERIAL;
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const [language, setLanguage] = useState("ar");
  const {
    [fieldKey]: { value, error },
  } = data;
  const { t, i18n } = useTranslation("common");

  useEffect(() => {
    const language = i18n.language;
    setLanguage(language);
  }, [i18n, i18n.language]);

  const handleIconClick = () => {
    setOverlayVisible(true);
  };

  const handleCloseClick = () => {
    setOverlayVisible(false);
  };

  const getImageVersion = () =>
    language === "en" ? civilIdSerialInfo : civilIdSerialInfoArabic;

  const validateField = (value) => {
    const fieldErrorObject = FieldsValidation.validateSerialIdNumber(value);
    const errorMessageReceived = fieldErrorObject.errorMessage;
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        value,
        isDisabled: false,
        error: errorMessageReceived,
      },
    }));
  };

  const handleChange = (e) => {
    validateField(e.target.value);
  };

  return (
    <>
      <InputField
        name="civilIdSerialNumber"
        icon={infoIcon}
        inputadornment="end"
        onIconClick={handleIconClick}
        label={translator("civilIdSerialNumber")}
        value={value}
        errorMessage={error}
        isDisabled={false}
        onChange={handleChange}
      />
      {isOverlayVisible && (
        <OverlayModal
          title={t("serialIdGuide")}
          fieldInfoImage={getImageVersion()}
          handleCloseClick={handleCloseClick}
        />
      )}
    </>
  );
};

CivilIdSerialRenewalNumber.propTypes = {
  data: PropTypes.object.isRequired,
  translator: PropTypes.func.isRequired,
  setKycData: PropTypes.func.isRequired,
};

export default CivilIdSerialRenewalNumber;
