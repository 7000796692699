import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import kycFields from '../../config/kyc';
import AccountPurpose from './fields/account-purpose';
import OtherAccountPurpose from './fields/other-account-purpose';
import InvestmentReason from './fields/investment-reason';
import InvestmentKnowledgeLevel from './fields/investment-knowledge-level';
import InvestmentYearsExperience from './fields/investment-years-of-experience';
import RiskToleranceLevel from './fields/risk-tolerance-level';
import InvestmentAdvisor from './fields/investment-advisor';
import AnnualIncome from './fields/annual-income';
import AnnualIncomeSource from './fields/annual-income-source';
import IncomeType from './fields/income-type';
import ClientIncomeDetails from './fields/client-income-details';
import AssetsValue from './fields/assets-value';
import TransactionsValuePastTwoYears from './fields/transactions-value-past-two-years';
import FinancialSectorYearsExperience from './fields/financial-sector-years-experience';
import WealthSource from './fields/wealth-source';
import WealthAmount from './fields/wealth-amount';
import WealthAmountJob from './fields/wealth-amount-job';
import WealthAmountInheritance from './fields/wealth-amount-inheritance';
import WealthDetailsInheritance from './fields/wealth-detail-inheritance';
import WealthAmountInvestments from './fields/wealth-amount-investments';
import WealthDetailsInvestments from './fields/wealth-details-investments';
import { incomeSourceEnums } from '../../enums/income-and-wealth';
import WealthAmountSaving from './fields/wealth-amount-saving';
import WealthDetailSaving from './fields/wealth-details-saving';
import WealthAmountSale from './fields/wealth-amount-sale';
import WealthDetailSale from './fields/wealth-details-sale';
import WealthAmountOther from './fields/wealth-amount-other';
import WealthDetailsOther from './fields/wealth-details-other';
import BankName from './fields/bank-name';
import Iban from './fields/iban-number';
import SameBusinessDetails from './fields/same-business-details';
import BusinessSector from './fields/business-sector';
import BusinessType from './fields/business-type';
import PrivateBusinessName from './fields/private-business-name';
import JobTitle from './fields/job-title';
import SeniorExecutiveOrOwner from './fields/is-senior-executive-or-owner';
import PrimaryButton from '../../widgets/buttons/primary-button';
import StepEnums from '../../enums/step-enum';
import SkeletonLoader from '../../widgets/fields-skeleton';
import {
  getIncomeSourceValue,
  getPossibleIncomeSourceOptions,
  getDefaultIncomeSourceValues,
  getClientClassificationResult,
  getClientClassificationLabel
} from './helper';
import {
  getKycData,
  putKycData,
  getCustomer,
  getCustomerOrder,
  putCustomerOrderBrokerage,
  putKycDataV3,
  getKycDataV3
} from '../../general-services.proxy';
import { additionalInfoStepRoute } from '../../routes/routes.const';
import MainLayout from '../../layouts/main-layout';
import { scrollToTop } from '../../utils';
import { useTranslation } from 'react-i18next';
import { brokerageEums } from '../../enums/general';
import KycKeys from '../../enums/kyc-enum';
import { newPepFieldsFeatureFlag } from '../../config/feature-flags';
import businessSectorEnums from '../../enums/business-sector';

const IncomeAndWealthStep = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const [kycData, setKycData] = useState(kycFields);
  const stepName = StepEnums.INCOME_AND_WEALTH_STEP;
  const [orderId, setOrderId] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [issOrderBrokerageLoading, setIsOrderBrokerageLoading] = useState(true);

  const { kycFields: kycFieldsOptions } = useSelector(state => state.general);
  const {
    income_source_v2: incomeSource,
    account_purpose: accountPurpose,
    income_annual_v2: incomeAnnual,
    investment_reason: investmentReason,
    investment_level: investmentLevel,
    risk_tolerance_level: riskToleranceLevel,
    investment_years_experience: yearsExperience,
    yes_no: yesOrNo = {},
    wealth_source: wealthSource,
    bank_names: bankNamesList,
    private_sector_industries_v2: privateBusinessIndustry,
    retail_trade: retailTradeSectors = {},
    energy_utilities_and_natural_resources: energyUtilitySectors = {},
    banking_and_finance: bankingAndFinanceSectors = {},
    health_and_social_services: healthServicesSectors = {},
    manufacturing_industries: manufacturingIndustriesSectors = {},
    professional_and_specialized_services: professionalAndSpecializedSectors = {},
    real_estate_hospitality_and_rental_services: realEstateSectors = {},
    information_and_technology_services: informationAndTechnologySectors = {},
    logistics_and_communications: logisticsSectors = {},
    public_services: publicSectors = {},
    sports_culture_and_entertainment: sportsCultureSectors = {},
    wholesale_trade: wholeSaleTradeSectors = {},
    off_shore_organizations_and_bodies: offShoreOrganizationSectors = {},
    other_assoc_pers_cult_and_tour: otherAssocSectors = {},
    assets_value: assetsValues,
    transactions_value_past_two_years: transactionsValuePastTwoYears,
    financial_sector_years_experience: financialSectorYearsExperience
  } = kycFieldsOptions;

  const businessTypeMapping = {
    [businessSectorEnums.RETAIL_TRADE]: retailTradeSectors,
    [businessSectorEnums.ENERGY_UTILITIES_AND_RESOURCES]: energyUtilitySectors,
    [businessSectorEnums.BANKING_AND_FINANCE]: bankingAndFinanceSectors,
    [businessSectorEnums.HEALTH_AND_SOCIAL_SERVICES]: healthServicesSectors,
    [businessSectorEnums.MANUFACTURING_INDUSTRIES]: manufacturingIndustriesSectors,
    [businessSectorEnums.PROFESSIONAL_AND_SPECIALIZED_SERVICES]: professionalAndSpecializedSectors,
    [businessSectorEnums.REAL_ESTATE_HOSPITALITY_AND_RENTAL_SERVICCES]: realEstateSectors,
    [businessSectorEnums.INFORMATION_AND_TECHNOLOGY_SERVICES]: informationAndTechnologySectors,
    [businessSectorEnums.LOGISTICS_AND_COMMUNICATIONS]: logisticsSectors,
    [businessSectorEnums.PUBLIC_SERVICES]: publicSectors,
    [businessSectorEnums.SPORTS_CULTURE_AND_ENTERTAINMENT]: sportsCultureSectors,
    [businessSectorEnums.WHOLESALE_TRADE]: wholeSaleTradeSectors,
    [businessSectorEnums.OFF_SHORE_ORGANIZATIONS_AND_BODIES]: offShoreOrganizationSectors,
    [businessSectorEnums.OTHER_ASOC_PERS_CULT_AND_TOUR]: otherAssocSectors
  };

  const businessTypes = kycData[KycKeys.PRIVATE_BUSINESS_INDUSTRY].value
    ? businessTypeMapping[kycData[KycKeys.PRIVATE_BUSINESS_INDUSTRY].value]
    : {};

  const employmentStatusKey = KycKeys.EMPLOYMENT_STATUS;
  const annualIncomeSourceKey = KycKeys.INCOME_SOURCE_V2;
  const nbkcClientClassificationFieldKey = KycKeys.NBKC_CLIENT_CLASSIFICATION;
  const {
    [annualIncomeSourceKey]: { value: incomeSourceValue },
    [KycKeys.ASSETS_VALUE]: { value: assetsValue },
    [KycKeys.TRANSACTIONS_VALUE_PAST_TWO_YEARS]: { value: transactionsPastTwoYearsValue },
    [KycKeys.FINANCIAL_SECTOR_YEARS_EXPERIENCE]: { value: financialSectorYearsExperienceValue }
  } = kycData;
  const [orderBrokerageId, setOrderBrokerageId] = useState('');
  const [productType, setProductType] = useState('');
  const [internationalBrokerageType, setInternationalBrokerageType] = useState('');
  const isContinueDisabled = Object.keys(kycData)
    .filter(key => kycData[key].step === stepName && kycData[key].isVisible)
    .some(
      key =>
        (!kycData[key].value && kycData[key].value !== false) ||
        (Array.isArray(kycData[key].value) && !kycData[key].value.length) ||
        kycData[key].error
    );
  const isBusinessDetailsSectionVisible = incomeSourceValue.some(
    obj => obj === incomeSourceEnums.PRIVATE_BUSINESS
  );
  const selectedOptions = {
    assetsValue,
    transactionsPastTwoYearsValue,
    financialSectorYearsExperienceValue
  };

  const handleNavigation = () => {
    navigate({
      pathname: additionalInfoStepRoute
    });
  };

  const saveKycData = async () => {
    try {
      setLoading(true);
      await (newPepFieldsFeatureFlag
        ? putKycDataV3(orderId, kycData, StepEnums.INCOME_AND_WEALTH_STEP)
        : putKycData(orderId, kycData, StepEnums.INCOME_AND_WEALTH_STEP));
    } catch (exception) {
      console.error(exception);
    }
  };

  const updateBankandIbaninBrokerage = async () => {
    try {
      const payload =
        productType === brokerageEums.REGIONAL_BROKERAGE_RBU
          ? {
              product_type: productType,
              payload: {
                bank_information: {
                  bank_name: kycData[KycKeys.BANK_NAME].value,
                  iban_number: kycData[KycKeys.IBAN_NUMBER].value
                },
                is_share_transfer_enabled: false
              }
            }
          : {
              product_type: productType,
              payload: {
                international_account_information: {
                  brokerage_account_type: internationalBrokerageType
                },
                bank_information: {
                  bank_name: kycData[KycKeys.BANK_NAME].value,
                  iban_number: kycData[KycKeys.IBAN_NUMBER].value
                }
              }
            };

      setLoading(true);
      await putCustomerOrderBrokerage(orderId, orderBrokerageId, payload);
      handleNavigation();
    } catch (exception) {
      console.error(exception);
    } finally {
      setLoading(false);
    }
  };

  const handleNextActionClick = async () => {
    await saveKycData();
    await updateBankandIbaninBrokerage();
  };

  const checkIncomeSourceDefaultValues = (incomeSourceValue, employmentStatusValue) => {
    const defaultValue = getDefaultIncomeSourceValues(incomeSource, employmentStatusValue);

    if (!defaultValue) {
      return;
    }
    const annualIncomeSourceValues = incomeSourceValue.map(val => ({
      label: incomeSource[val],
      value: val
    }));

    const incomeSourceOptions = getPossibleIncomeSourceOptions(incomeSource, employmentStatusValue);
    setKycData(prev => ({
      ...prev,
      [annualIncomeSourceKey]: {
        ...prev[annualIncomeSourceKey],
        error: '',
        value: [
          ...defaultValue.map(option => option.value),
          ...getIncomeSourceValue(annualIncomeSourceValues, defaultValue, incomeSourceOptions)
        ]
      }
    }));
  };

  const fetchKycData = async () => {
    try {
      setLoading(true);
      const { orderId, orderKycId } = await getCustomer();
      setOrderId(orderId);
      const data = await (newPepFieldsFeatureFlag
        ? getKycDataV3(orderId, orderKycId)
        : getKycData(orderId, orderKycId));
      const {
        [annualIncomeSourceKey]: { value: incomeSourceValue },
        [employmentStatusKey]: { value: employmentStatusValue }
      } = data;
      setKycData(data);
      checkIncomeSourceDefaultValues(incomeSourceValue, employmentStatusValue);
    } catch (exception) {
      console.error(exception);
    } finally {
      scrollToTop();
      setLoading(false);
    }
  };

  const getOrderBrokerage = async () => {
    try {
      setIsOrderBrokerageLoading(true);
      const { orderBrokerageId, productType, brokerageAccountType } =
        await getCustomerOrder(orderId);
      setOrderBrokerageId(orderBrokerageId);
      setInternationalBrokerageType(brokerageAccountType);
      setProductType(productType);
    } catch (exception) {
      console.error(exception);
    } finally {
      setIsOrderBrokerageLoading(false);
    }
  };

  useEffect(() => {
    if (orderId) {
      getOrderBrokerage();
    }
  }, [orderId]);

  useEffect(() => {
    if (incomeSource) {
      fetchKycData();
    }

    return () => {
      setKycData(kycFields);
    };
  }, [incomeSource]);

  useEffect(() => {
    if (assetsValue && transactionsPastTwoYearsValue && financialSectorYearsExperienceValue) {
      const result = getClientClassificationResult(selectedOptions);
      setKycData(prev => ({
        ...prev,
        [nbkcClientClassificationFieldKey]: {
          ...prev[nbkcClientClassificationFieldKey],
          value: result,
          label: getClientClassificationLabel(result)
        }
      }));
    }
  }, [assetsValue, transactionsPastTwoYearsValue, financialSectorYearsExperienceValue]);

  const renderFooter = () => (
    <PrimaryButton
      data-testid={'saveandcontinue'}
      disabled={isContinueDisabled || isLoading}
      onClick={() => {
        handleNextActionClick();
      }}
    >
      {t('saveAndContinueButton')}
    </PrimaryButton>
  );

  return (
    <MainLayout stepTitle={t('incomeAndWealthStepTitle')} footer={renderFooter} stepNumber={4}>
      {isLoading || issOrderBrokerageLoading ? (
        <SkeletonLoader numberOfFields={13} />
      ) : (
        <>
          <AccountPurpose
            data={{
              [KycKeys.ACCOUNT_PURPOSE]: kycData[KycKeys.ACCOUNT_PURPOSE]
            }}
            setKycData={setKycData}
            kycFields={{
              accountPurposes: accountPurpose
            }}
            translator={t}
          />

          <OtherAccountPurpose
            data={{
              [KycKeys.ACCOUNT_PURPOSE_OTHER]: kycData[KycKeys.ACCOUNT_PURPOSE_OTHER],
              [KycKeys.ACCOUNT_PURPOSE]: kycData[KycKeys.ACCOUNT_PURPOSE]
            }}
            setKycData={setKycData}
            translator={t}
          />
          <AnnualIncome
            data={{
              [KycKeys.INCOME_ANNUAL_V2]: kycData[KycKeys.INCOME_ANNUAL_V2]
            }}
            setKycData={setKycData}
            kycFields={{
              incomeAnnual: incomeAnnual
            }}
            translator={t}
          />
          <AnnualIncomeSource
            data={{
              [KycKeys.INCOME_SOURCE_V2]: kycData[KycKeys.INCOME_SOURCE_V2],
              [KycKeys.EMPLOYMENT_STATUS]: kycData[KycKeys.EMPLOYMENT_STATUS]
            }}
            setKycData={setKycData}
            kycFields={{
              incomeSource: incomeSource
            }}
            translator={t}
          />

          <IncomeType
            data={{
              [KycKeys.INCOME_TYPE]: kycData[KycKeys.INCOME_TYPE],
              [KycKeys.INCOME_SOURCE_V2]: kycData[KycKeys.INCOME_SOURCE_V2]
            }}
            setKycData={setKycData}
            translator={t}
          />
          <ClientIncomeDetails
            data={{
              [KycKeys.INCOME_DETAILS]: kycData[KycKeys.INCOME_DETAILS],
              [KycKeys.INCOME_SOURCE_V2]: kycData[KycKeys.INCOME_SOURCE_V2]
            }}
            setKycData={setKycData}
            translator={t}
          />

          <InvestmentReason
            data={{
              [KycKeys.INVESTMENT_REASON]: kycData[KycKeys.INVESTMENT_REASON]
            }}
            setKycData={setKycData}
            kycFields={{
              investmentReasons: investmentReason
            }}
            translator={t}
          />
          <InvestmentKnowledgeLevel
            data={{
              [KycKeys.INVESTMENT_LEVEL]: kycData[KycKeys.INVESTMENT_LEVEL]
            }}
            setKycData={setKycData}
            kycFields={{
              investmentKnowledgeLevels: investmentLevel
            }}
            translator={t}
          />
          <InvestmentYearsExperience
            data={{
              [KycKeys.INVESTMENT_YEARS_EXPERIENCE]: kycData[KycKeys.INVESTMENT_YEARS_EXPERIENCE]
            }}
            setKycData={setKycData}
            kycFields={{ investmentYearsExperience: yearsExperience }}
            translator={t}
          />
          <RiskToleranceLevel
            data={{
              [KycKeys.RISK_TOLERANCE_LEVEL]: kycData[KycKeys.RISK_TOLERANCE_LEVEL]
            }}
            setKycData={setKycData}
            kycFields={{
              riskToleranceLevels: riskToleranceLevel
            }}
            translator={t}
          />
          <InvestmentAdvisor
            data={{
              [KycKeys.INVESTMENT_ADVISOR]: kycData[KycKeys.INVESTMENT_ADVISOR]
            }}
            setKycData={setKycData}
            translator={t}
            kycFields={{
              yesOrNo: yesOrNo
            }}
          />
          {isBusinessDetailsSectionVisible && (
            <>
              <SameBusinessDetails
                data={{
                  [KycKeys.PRIVATE_BUSINESS_SAME_EMPLOYMENT_DETAILS]:
                    kycData[KycKeys.PRIVATE_BUSINESS_SAME_EMPLOYMENT_DETAILS],
                  [KycKeys.INCOME_SOURCE_V2]: kycData[KycKeys.INCOME_SOURCE_V2]
                }}
                setKycData={setKycData}
                kycFields={{
                  yesOrNo: yesOrNo
                }}
                translator={t}
              />
              <BusinessSector
                data={{
                  [KycKeys.PRIVATE_BUSINESS_INDUSTRY]: kycData[KycKeys.PRIVATE_BUSINESS_INDUSTRY],
                  [KycKeys.PRIVATE_BUSINESS_SAME_EMPLOYMENT_DETAILS]:
                    kycData[KycKeys.PRIVATE_BUSINESS_SAME_EMPLOYMENT_DETAILS]
                }}
                setKycData={setKycData}
                kycFields={{
                  businessSectors: privateBusinessIndustry
                }}
                translator={t}
              />
              <BusinessType
                data={{
                  [KycKeys.PRIVATE_BUSINESS_SECTOR]: kycData[KycKeys.PRIVATE_BUSINESS_SECTOR],
                  [KycKeys.PRIVATE_BUSINESS_SAME_EMPLOYMENT_DETAILS]:
                    kycData[KycKeys.PRIVATE_BUSINESS_SAME_EMPLOYMENT_DETAILS]
                }}
                setKycData={setKycData}
                kycFields={{
                  businessTypes: businessTypes
                }}
                translator={t}
              />
              <PrivateBusinessName
                data={{
                  [KycKeys.PRIVATE_BUSINESS_COMPANY]: kycData[KycKeys.PRIVATE_BUSINESS_COMPANY],
                  [KycKeys.PRIVATE_BUSINESS_SAME_EMPLOYMENT_DETAILS]:
                    kycData[KycKeys.PRIVATE_BUSINESS_SAME_EMPLOYMENT_DETAILS]
                }}
                setKycData={setKycData}
                translator={t}
              />
              <JobTitle
                data={{
                  [KycKeys.PRIVATE_BUSINESS_JOB]: kycData[KycKeys.PRIVATE_BUSINESS_JOB],
                  [KycKeys.PRIVATE_BUSINESS_SAME_EMPLOYMENT_DETAILS]:
                    kycData[KycKeys.PRIVATE_BUSINESS_SAME_EMPLOYMENT_DETAILS]
                }}
                setKycData={setKycData}
                translator={t}
              />
              <SeniorExecutiveOrOwner
                data={{
                  [KycKeys.PRIVATE_BUSINESS_SENIOR_EXECUTIVE]:
                    kycData[KycKeys.PRIVATE_BUSINESS_SENIOR_EXECUTIVE],
                  [KycKeys.PRIVATE_BUSINESS_SAME_EMPLOYMENT_DETAILS]:
                    kycData[KycKeys.PRIVATE_BUSINESS_SAME_EMPLOYMENT_DETAILS]
                }}
                setKycData={setKycData}
                kycFields={{
                  yesOrNo: yesOrNo
                }}
                translator={t}
              />
            </>
          )}
          <AssetsValue
            data={{
              [KycKeys.ASSETS_VALUE]: kycData[KycKeys.ASSETS_VALUE]
            }}
            setKycData={setKycData}
            kycFields={{
              assetsValue: assetsValues
            }}
            translator={t}
          />
          <TransactionsValuePastTwoYears
            data={{
              [KycKeys.TRANSACTIONS_VALUE_PAST_TWO_YEARS]:
                kycData[KycKeys.TRANSACTIONS_VALUE_PAST_TWO_YEARS]
            }}
            setKycData={setKycData}
            kycFields={{
              transactionsValuePastTwoYears: transactionsValuePastTwoYears
            }}
            translator={t}
          />
          <FinancialSectorYearsExperience
            data={{
              [KycKeys.FINANCIAL_SECTOR_YEARS_EXPERIENCE]:
                kycData[KycKeys.FINANCIAL_SECTOR_YEARS_EXPERIENCE]
            }}
            setKycData={setKycData}
            kycFields={{
              financialYearsExperiences: financialSectorYearsExperience
            }}
            translator={t}
          />
          <WealthSource
            data={{
              [KycKeys.WEALTH_SOURCE]: kycData[KycKeys.WEALTH_SOURCE],
              [KycKeys.INCOME_SOURCE_V2]: kycData[KycKeys.INCOME_SOURCE_V2]
            }}
            setKycData={setKycData}
            kycFields={{
              wealthSources: wealthSource
            }}
            translator={t}
          />
          <WealthAmountJob
            data={{
              [KycKeys.WEALTH_AMOUNT_JOB]: kycData[KycKeys.WEALTH_AMOUNT_JOB],
              [KycKeys.WEALTH_SOURCE]: kycData[KycKeys.WEALTH_SOURCE]
            }}
            setKycData={setKycData}
            translator={t}
          />
          <WealthAmountInheritance
            data={{
              [KycKeys.WEALTH_AMOUNT_INHERITANCE]: kycData[KycKeys.WEALTH_AMOUNT_INHERITANCE],
              [KycKeys.WEALTH_SOURCE]: kycData[KycKeys.WEALTH_SOURCE]
            }}
            setKycData={setKycData}
            translator={t}
          />
          <WealthDetailsInheritance
            data={{
              [KycKeys.WEALTH_DETAILS_INHERITANCE]: kycData[KycKeys.WEALTH_DETAILS_INHERITANCE],
              [KycKeys.WEALTH_SOURCE]: kycData[KycKeys.WEALTH_SOURCE]
            }}
            setKycData={setKycData}
            translator={t}
          />
          <WealthAmountInvestments
            data={{
              [KycKeys.WEALTH_AMOUNT_INVESTMENTS]: kycData[KycKeys.WEALTH_AMOUNT_INVESTMENTS],
              [KycKeys.WEALTH_SOURCE]: kycData[KycKeys.WEALTH_SOURCE]
            }}
            setKycData={setKycData}
            translator={t}
          />
          <WealthDetailsInvestments
            data={{
              [KycKeys.WEALTH_DETAILS_INVESTMENTS]: kycData[KycKeys.WEALTH_DETAILS_INVESTMENTS],
              [KycKeys.WEALTH_SOURCE]: kycData[KycKeys.WEALTH_SOURCE]
            }}
            setKycData={setKycData}
            translator={t}
          />
          <WealthAmountSaving
            data={{
              [KycKeys.WEALTH_AMOUNT_SAVING]: kycData[KycKeys.WEALTH_AMOUNT_SAVING],
              [KycKeys.WEALTH_SOURCE]: kycData[KycKeys.WEALTH_SOURCE]
            }}
            setKycData={setKycData}
            translator={t}
          />
          <WealthDetailSaving
            data={{
              [KycKeys.WEALTH_DETAILS_SAVING]: kycData[KycKeys.WEALTH_DETAILS_SAVING],
              [KycKeys.WEALTH_SOURCE]: kycData[KycKeys.WEALTH_SOURCE]
            }}
            setKycData={setKycData}
            translator={t}
          />
          <WealthAmountSale
            data={{
              [KycKeys.WEALTH_AMOUNT_SALE]: kycData[KycKeys.WEALTH_AMOUNT_SALE],
              [KycKeys.WEALTH_SOURCE]: kycData[KycKeys.WEALTH_SOURCE]
            }}
            setKycData={setKycData}
            translator={t}
          />
          <WealthDetailSale
            data={{
              [KycKeys.WEALTH_DETAILS_SALE]: kycData[KycKeys.WEALTH_DETAILS_SALE],
              [KycKeys.WEALTH_SOURCE]: kycData[KycKeys.WEALTH_SOURCE]
            }}
            setKycData={setKycData}
            translator={t}
          />

          <WealthAmountOther
            data={{
              [KycKeys.WEALTH_AMOUNT_OTHER]: kycData[KycKeys.WEALTH_AMOUNT_OTHER],
              [KycKeys.WEALTH_SOURCE]: kycData[KycKeys.WEALTH_SOURCE]
            }}
            setKycData={setKycData}
            translator={t}
          />
          <WealthDetailsOther
            data={{
              [KycKeys.WEALTH_DETAILS_OTHER]: kycData[KycKeys.WEALTH_DETAILS_OTHER],
              [KycKeys.WEALTH_SOURCE]: kycData[KycKeys.WEALTH_SOURCE]
            }}
            setKycData={setKycData}
            translator={t}
          />
          <WealthAmount
            data={{
              [KycKeys.WEALTH_AMOUNT]: kycData[KycKeys.WEALTH_AMOUNT],
              [KycKeys.WEALTH_SOURCE]: kycData[KycKeys.WEALTH_SOURCE],
              [KycKeys.WEALTH_AMOUNT_JOB]: kycData[KycKeys.WEALTH_AMOUNT_JOB],
              [KycKeys.WEALTH_AMOUNT_INHERITANCE]: kycData[KycKeys.WEALTH_AMOUNT_INHERITANCE],
              [KycKeys.WEALTH_AMOUNT_INVESTMENTS]: kycData[KycKeys.WEALTH_AMOUNT_INVESTMENTS],
              [KycKeys.WEALTH_AMOUNT_SAVING]: kycData[KycKeys.WEALTH_AMOUNT_SAVING],
              [KycKeys.WEALTH_AMOUNT_SALE]: kycData[KycKeys.WEALTH_AMOUNT_SALE],
              [KycKeys.WEALTH_AMOUNT_OTHER]: kycData[KycKeys.WEALTH_AMOUNT_OTHER]
            }}
            setKycData={setKycData}
            translator={t}
          />
          <BankName
            data={{
              [KycKeys.BANK_NAME]: kycData[KycKeys.BANK_NAME]
            }}
            setKycData={setKycData}
            translator={t}
            kycFieldsOptions={{ bank_names: bankNamesList }}
          />
          <Iban
            data={{
              [KycKeys.IBAN_NUMBER]: kycData[KycKeys.IBAN_NUMBER],
              [KycKeys.BANK_NAME]: kycData[KycKeys.BANK_NAME]
            }}
            setKycData={setKycData}
            translator={t}
          />
        </>
      )}
    </MainLayout>
  );
};

export default IncomeAndWealthStep;
