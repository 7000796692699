import React from 'react';
import Images from '../../assets/images';
import { useLocation } from 'react-router';

import './style.scss';

const UnblockSection = ({ title, description, icon }) => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const lang = params.get('lang');
  const isArabic = lang === 'ar';
  return (
    <>
      <div
        id='unblock'
        style={{ backgroundImage: `url(${Images.MainBackground})` }}
        className={`${isArabic ? 'rtl' : 'ltr'}`}
      >
        <div className='body-info'>
          <div className='icon'>
            <img src={icon} alt='tick' />
          </div>
          <div className='title'>{title}</div>
          <div className='description'>{description}</div>
        </div>
      </div>
    </>
  );
};

export default UnblockSection;
