import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import MainLayout from '../../layouts/main-layout';
import PrimaryButton from '../../widgets/buttons/primary-button';
import '../../../src/theme/spacing.scss';
import InputField from '../../widgets/inputs/primary-input';
import { landingRoute, otpVerification } from '../../routes/routes.const';
import styles from './phone-number.module.scss';
import FieldsValidation from '../../validation/fields-validation';
import { sendOTP } from './phone-number-details.proxy';
import { useSelector, useDispatch } from 'react-redux';
import { countryCode } from '../../enums/general';
import errorCloseIcon from '../../assets/icons/alert-close-icon.svg';
import errorInfoIcon from '../../assets/icons/alert-info-icon.svg';
import Alert from '../../components/alert-component-fixed';
import { useTranslation } from 'react-i18next';
import { setUserInfo } from '../../store/slices/general.slice';
import locale from '../../localiztion';

const PhoneNumberDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const [exception, setException] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { civilIDNumber, serialNumber, phoneNumber } = useSelector(state => state.general.userInfo);
  const { userInfo } = useSelector(state => state.general);
  const [phoneNumberInfo, setPhoneNumberInfo] = useState({
    value: '',
    error: ''
  });

  const validateField = value => {
    const fieldErrorObject = FieldsValidation.validateMobileNumber(value, countryCode);
    const errorMessageReceived = fieldErrorObject.errorMessage;
    setPhoneNumberInfo(prev => ({
      ...prev,
      value,
      error: errorMessageReceived
    }));
  };

  const handleChange = e => {
    validateField(e.target.value);
  };
  const formattedPhoneNumber = countryCode + phoneNumberInfo.value;

  const isContinueDisabled = !phoneNumberInfo.value || phoneNumberInfo.error || isLoading;

  const customerSendOTPPayload = {
    civil_id_number: civilIDNumber,
    civil_id_serial: serialNumber,
    phone_number: formattedPhoneNumber
  };

  const sendCustomerOTP = async () => {
    try {
      setIsLoading(true);
      setException('');
      await sendOTP(customerSendOTPPayload);
      navigate(otpVerification);
    } catch (exception) {
      if (
        exception.response.data.description === t('maxAttempts') ||
        exception.response.data.description === locale.maxAttempts
      ) {
        setException('maxAttempts');
      }

      throw new Error(exception);
    } finally {
      setIsLoading(false);
    }
  };

  const onContinue = async () => {
    dispatch(
      setUserInfo({
        ...userInfo,
        phoneNumber: phoneNumberInfo.value
      })
    );

    try {
      await sendCustomerOTP();
    } catch (exception) {
      console.error(exception);
    }
  };

  const renderFooter = () => (
    <PrimaryButton onClick={onContinue} disabled={isContinueDisabled} data-testid={'sendOtp'}>
      {t('sendOTP')}
    </PrimaryButton>
  );

  useEffect(() => {
    setPhoneNumberInfo(prev => ({
      ...prev,
      value: phoneNumber
    }));
  }, []);

  useEffect(() => {
    if (!civilIDNumber || !serialNumber) {
      navigate(landingRoute);
    }
  }, []);

  return (
    <>
      <MainLayout
        title={t('enterPhoneNumberMessage')}
        footer={renderFooter}
        withAlertMessage={exception}
      >
        <div className={styles.container}>
          <InputField
            inputContent='+965'
            inputContentAdornment='start'
            label={t('phoneNumberLabel')}
            onChange={handleChange}
            value={phoneNumberInfo.value}
            errorMessage={phoneNumberInfo.error}
            name={'phoneNumber'}
          />
        </div>
      </MainLayout>
      {exception && (
        <Alert
          text={t(exception)}
          setText={setException}
          leftIcon={errorInfoIcon}
          rightIcon={errorCloseIcon}
          styling={{ backGroundColor: '#ffedf0', textColor: '#e94a66' }}
        />
      )}
    </>
  );
};
export default PhoneNumberDetails;
