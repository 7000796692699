import React from 'react';
import styles from './info-container.module.scss';
import PropTypes from 'prop-types';

const InfoContainer = ({ icon = '', title = '', subTitle = '' }) => (
  <div className={styles.container}>
    <img className={styles.imageStyle} src={icon} alt='icon' />
    <span className={styles.title}>{title}</span>
    <span className={styles.subtitleMessage}>{subTitle}</span>
  </div>
);

InfoContainer.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired
};

export default InfoContainer;
