export const privacyLocalesEn = {
  privacyTitle: "Privacy Policy",
  introductionTitle: "Introduction",
  introductionDescription_1:
    "Watani Investment Company K.S.C.C. is an investment company established in the State of Kuwait in 2005. NBK Invest is a brand registered under the name of Watani Investment Company K.S.C.C. and provides prospective clients with digital access to the investment products, services, and solutions offered by Watani Investment Company K.S.C.C.",
  introductionDescription_2:
    "Watani Investment Company K.S.C.C. has created this Privacy Policy in order to demonstrate our firm commitment to protecting the privacy and confidentiality of personal information in our possession and control. We shall never sell your personal information to other companies for their independent marketing purposes. We maintain physical, electronic and procedural safeguards to prevent unauthorized access to your personal information and to protect you against the criminal misuse of that information. We restrict access to your account(s) to only those employees involved in providing the services you've requested.",
  introductionDescription_3:
    "This Privacy Policy may be updated periodically to reflect changes to our personal information practices. We strongly encourage you to refer to this Privacy Policy often for the latest information about our personal information practices. If you have any questions or if you believe Watani Investment Company K.S.C.C. has not adhered to this privacy policy, please send us a note at ",
  contactSupport: "Contact Support",
  informationTitle: "Information We Collect and Why",
  informationDescription:
    "In order to provide a high-quality service, Watani Investment Company K.S.C.C. collects various types of information when you visit the NBK Invest website or mobile app, sign-up as a customer, or otherwise engage with NBK Invest.",
  visitorTitle: "Visitor Information",
  visitorDescription:
    "We collect various types of anonymous information about visitors to NBK Invest, such as device-related information (including browser type and IP address) and server log information (including the date and time of day of your visit, average time spent on the NBK Invest application, browsing activity, and any site that referred you to NBK Invest). We also collect information that you input into our application, e.g., by beginning the account application process. We use this information to better understand our visitors and our business, facilitate site navigation, provide the services or information you request, and enhance the NBK Invest service.",
  email: "support@invest.nbk.com",
  supportEmail: "Support Email",
  customerTitle: "Customer Information",
  customerDescription_1:
    "When you choose to create an account with NBK Invest, we will also collect certain personal information, including your full legal name, email address, permanent address, date of birth and information about financial status. We are required by law to collect this information to provide you with financial services. If you do not wish to provide this information, you cannot become a NBK Invest customer.",
  customerDescription_2:
    "While onboarding, you can expect to undergo a series of identity verification procedures. Amongst them, the video identification process includes recording a video which will record the person’s ID and their face. The purpose of this procedure is to identify the person remotely to streamline the process by which that person is included as a customer with the appropriate legal guarantees. The information which is recorded during this process can be checked, analysed, verified, and confirmed.",
  collectInformationTitle: "Other Ways We Collect Information",
  collectInformationDescription:
    "Apart from through the account opening process and from visitors to the site, we may collect various other types of personal information if you or a friend engages with NBK Invest. We collect your email address if you subscribe to the NBK Invest blog. We may also collect personal information if you enter it into our live chat program or contact us or otherwise give it to us (e.g., in an email, phone call, or live chat with a member of our customer support team). Further, we may collect information regarding your browsing activity when you visit NBK Invest's application and actions you take within your NBK Invest account while logged in to your NBK Invest account.",
  cookiesTitle: "Cookies and Pixels",
  cookiesDescription_1:
    "Cookies are small pieces of data that are stored on your computer, mobile phone or any other device used to access the internet. Pixels are small code blocks on a website or application that allow for another server to measure viewing of a webpage or application and often are used in connection with cookies.",
  cookiesDescription_2:
    "We use these technologies to customize NBK Invest and improve your experience. Cookies store anonymous information, such as whether you viewed the site or application from your mobile device. We may store some personal information in a cookie and/or pixel, such as the site that you visited immediately prior to visiting NBK Invest and relevant financial information that allows us to enhance your user experience and for marketing purposes. Additionally, we may use a cookie and/or pixel that only stores anonymous information to track visitors across websites to better customize our marketing and advertising campaigns.",
  cookiesDescription_3:
    "Cookies, pixels, and similar technologies are not required for site functionality. You are not required to accept any cookies or pixels to use this site. However, refusing to accept cookies or pixels will make the use of NBK Invest more cumbersome and less accessible.",
  advertizingTitle: "Advertising Companies",
  advertizingDescription_1:
    "Watani Investment Company K.S.C.C. advertises our services on websites or platforms not affiliated withWatani Investment Company K.S.C.C., and we contract with third-party advertising companies to display these ads. These third-party advertising companies, along with data exchanges and similar providers, may use cookies and similar technologies to collect technical and web navigational information, such as device type, browser type, IP address, and pages visited. We do not provide any information to these advertising companies that could personally identify our users or clients, and they do not collect such information for us.",
  advertizingDescription_2:
    "Watani Investment Company K.S.C.C. and third-party advertising companies may use the data collected to provide you with ads relevant to you and personalized content while you are on NBK Invest or other sites. If you prefer to not receive targeted advertising, you can opt out of some network advertising programs that use your information by visiting the ",
  naiOptPage: "NAI Opt-Out Page. ",
  advertizingDescription_3:
    "Please note that even if you choose to remove your information by opting out, you will still see advertisements while you are browsing online.",
  advertizingDescription_4:
    "Additionally, many network advertising programs allow you to view and manage the interest categories they have compiled from your online browsing activities. These interest categories help determine the types of targeted advertisements you may receive. The ",
  advertizingDescription_5:
    " Page provides a tool that identifies its member companies that have cookies on your browser and provides links to those companies.",
  advertizingDescription_6:
    "Because mobile devices may vary in how they operate and may not use cookies, the choice management options listed above may not work for all mobile devices and mobile apps.",
  analyticsTitle: "Analytics Services",
  analyticsDescription_1:
    'We use third-party analytics tools, including "Google Analytics" (such tools collectively referred to as "Analytics Services") to collect information about the usage of this site/application. The Analytics Services collect information about how often users visit this site/application, what pages they visit when they do so, and what other sites they used prior to coming to this site/application, among other information. We use the information we get from these Analytics Services to improve this site.',
  analyticsDescription_2:
    "These Analytics Services collect the IP address assigned to you on the date you visit this site, along with other anonymized information (e.g., browser type and type of device used to visit the site), but not your name or other personally identifiable information. The Analytics Services’ ability to use and share information about your visits to this site is restricted by their terms of use and privacy policies. We do not combine the information collected through the use of Google Analytics with personally identifiable information.",
  analyticsDescription_3:
    "You can prevent Google Analytics from recognizing you on return visits to this site by disabling cookies on your browser. You can opt-out of Google Analytics by installing the Google Analytics Opt-out Browser Add-on ",
  here: "here",
  otherImportantInformation: "Other Important Information",
  formerCustomerTitle: "Former Customers",
  formerCustomerDescription:
    "If you are a former customer, these policies also apply to you; we treat your information with the same care as we do information about current customers.",
  acceptPolicyTitle: "How You Accept This Policy",
  acceptPolicyDescription:
    "By using and continuing to use the NBK Invest websites, mobile apps and/or services, or otherwise providing us with personal information (such as an email address) you agree to the terms and conditions of this privacy policy and any updates thereto. This policy may change from time to time. This is our entire and exclusive privacy policy and it supersedes any earlier version. Our Terms and Conditions take precedence over any conflicting privacy policy provision.",
  changePolicyTitle: "Changes to the Policy",
  changePolicyDescription:
    "We may modify this privacy policy over time without prior notice by posting a new version of this privacy policy, which is your responsibility to review. Any changes to the privacy policy will be reflected on this page and will become effective immediately upon posting. We encourage you to periodically review this privacy policy to stay informed about how we are protecting the personally identifiable information we collect. If changes to the privacy policy are material, we will do our best to notify you via email or through a notification on the site or app.",
  childrenTitle: "Children",
  childrenDescription_1:
    "Our service is not directed towards anyone under the age of 21. If a parent or guardian becomes aware that his or her child under the age of 21 has created an account with NBK Invest without setting up a Guardian account, please contact us via the ",
  childrenDescription_2:
    ". Additionally, if we become aware at any point that a child under the age of 21 is using our service, we will terminate his or her account.",
  questionsTitle: "Questions",
  questionsDescription:
    "If you have any questions on the privacy policy, please contact us at:",
  nbkCapital: "Watani Investment Company K.S.C.C.",
  location_1: "35th Floor, NBK Tower",
  location_2:
    "Block 7, Plot 6, Sharq Area, Jaber Al-Mubarak & AlShuhada Street",
  location_3: "P.O. Box 4950 Safat 13050, Kuwait",
  location_4: "Kuwait",
  tel: "Tel: +965 2224 6900",
  fax: "Fax: +965 2224 6904/5",
  mailTo: "mailto:support@invest.nbk.com",
  naiOptPageLink: "https://optout.networkadvertising.org/?c=1",
  analyticsLink:
    "https://chromewebstore.google.com/detail/google-analytics-opt-out/fllaojicojecljbmefodhfapmkghcbnh?hl=en&pli=1",
};
